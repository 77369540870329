// Abuse the 'params.filter' to pass cache duration to this function, which
// wraps the data provider. This gives us greater control over when to cache requests.
// This CACHE_DURATION_FILTER_KEY is nonsense to the server and we remove it in the
// dataProvider's filter formatter - it must be removed AFTER calling the dataProvider action.

// Because many complex objects are serialized with nested dependent resources,
// we have to limit caching to specific cases - i.e. ReferenceInput dropdowns,
// which contain the CACHE_DURATION_FILTER_KEY attribute.

// In the future, if we perfectly flatten serialized data structure, we can implement
// this cache universally.

const supportedActionNames = [
  'getList',
]

const CACHE_DURATION_FILTER_KEY = '_CACHE_DURATION'

const cacheProxy = (dataProvider) => {
  return new Proxy(dataProvider, {
    get: (_, name) => async (resource, params) => {
      const cacheDuration = params.filter?.[CACHE_DURATION_FILTER_KEY]
      if (supportedActionNames.includes(name) && cacheDuration) {
        const response = await dataProvider[name](resource, params)
        const validUntil = new Date()
        validUntil.setTime(validUntil.getTime() + cacheDuration)
        response.validUntil = validUntil
        return response
      }
      return dataProvider[name](resource, params)
    },
  })
}

export default cacheProxy

export {
  CACHE_DURATION_FILTER_KEY,
}
