import {
  TextInput,
} from 'react-admin'
import { List, Filter, DateInput } from '../../custom'
import { useLabel } from '../../../hooks'
import CollectionData from './collection-data'
import Datagrid from './datagrid'
import { withCollectionData } from '../../hoc'

const DatagridWithTotals = withCollectionData(Datagrid, CollectionData)

const BrokerDriverAggregateProfitsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <DateInput label={label('ticket_date', 'earliest')} source='ticket_date_gteq' />
      <DateInput label={label('ticket_date', 'latest')} source='ticket_date_lteq' />
      <TextInput label={label('name', 'filter')} source='name_i_cont' />
    </Filter>
  )
}

const BrokerDriverAggregateProfitsList = props => {
  return (
    <List
      {...props}
      filters={<BrokerDriverAggregateProfitsFilter />}
      filter={{ include_collection_data: true }}
    >
      <DatagridWithTotals />
    </List>
  )
}

export default BrokerDriverAggregateProfitsList
