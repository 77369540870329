import { put, takeEvery } from 'redux-saga/effects'
import { CUSTOM_MUTATION_SUCCESS, BUST_CACHE } from '../actions'
import { dependsOnResources } from '../data-model'
import { CRUD_DELETE_SUCCESS } from 'ra-core'

// Some resources (i.e. drivers) are pseudo-polymorphic in that
// they are created/syncronized one-to-one with other resources. This causes
// a problem with our front-end caching system because the dependent resource cache is not
// busted when its dependency is created, updated or destroyed.
// This saga generator function works around the
// issue by dispatching a fake action on the dependent resource when one of the deps changes.

const dependsOnResourcesEntries = Object.entries(dependsOnResources)

const pattern = [
  CUSTOM_MUTATION_SUCCESS,
  CRUD_DELETE_SUCCESS,
]

function* bustDependentResourceCache() {
  yield takeEvery(pattern, function* (data) {
    const {
      meta: {
        resource,
        fetchResponse,
        fetchStatus,
      }
    } = data

    for (const [dependent, dependencies] of dependsOnResourcesEntries) {
      if (dependencies.includes(resource)) {
        yield put({
          type: BUST_CACHE,
          meta: {
            fetchStatus,
            fetchResponse,
            resource: dependent,
          },
          // don't have the real data but this conforms to structure
          requestPayload: {},
          payload: {
            data: {},
          },
        })
      }
    }
  })
}

export default bustDependentResourceCache
