import { useMemo } from 'react'
import groupBy from 'lodash/groupBy'

const usePayRollupTickets = ({ tonnageTickets, hourlyTickets }) => {
  return useMemo(() => {
    const tickets = [
      tonnageTickets,
      hourlyTickets
    ].reduce((accum, tickets) => {
      return [
        ...accum,
        ...tickets.map(t => ({ ...t, id: `${t.type}-${t.id}` })),
      ]
    }, [])

    const sorted = tickets.sort((a, b) => {
      // date asc
      if (a.date > b.date) return 1
      if (a.date < b.date) return -1

      // ticket_number asc
      if (a.ticket_number > b.ticket_number) return 1
      if (a.ticket_number < b.ticket_number) return -1
      return 0
    })

    const grouped = groupBy(sorted, 'date')

    return Object.entries(grouped).reduce((accum, [date, tickets]) => {
      const sums = tickets.reduce((_accum, curr) => {
        return {
          total_pay_in_dollars: _accum.total_pay_in_dollars + curr.total_pay_in_dollars,
          regular_time_in_hours: _accum.regular_time_in_hours + curr.regular_time_in_hours,
          overtime_in_hours: _accum.overtime_in_hours + curr.overtime_in_hours,
          doubletime_in_hours: _accum.doubletime_in_hours + curr.doubletime_in_hours,
          net_weight_in_tons: _accum.net_weight_in_tons + curr.net_weight_in_tons,
        }
      }, {
        total_pay_in_dollars: 0,
        regular_time_in_hours: 0,
        overtime_in_hours: 0,
        doubletime_in_hours: 0,
        net_weight_in_tons: 0,
      })

      return [
        ...accum,
        {
          data: tickets,
          subtotal: {
            ticket_number: tickets.length,
            date: date,
            ...sums,
          },
        }
      ]
    }, [])
  }, [tonnageTickets, hourlyTickets])
}

export default usePayRollupTickets
