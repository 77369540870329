import {
  NumberField as RaNumberField,
} from 'react-admin'
import get from 'lodash/get'
import set from 'lodash/set'

const NumberField = ({ forceZero = false, record, ...rest }) => {
  const { source } = rest
  const value = get(record, source)

  let recordClone = { ...record }

  if (forceZero) {
    set(recordClone, source, value ?? 0.00)
  }

  return (
    <RaNumberField
      {...rest}
      record={recordClone}
    />
  )
}

// defaultProps will get lost on wrapped RaNumberField unless we apply them
NumberField.defaultProps = RaNumberField.defaultProps

export default NumberField
