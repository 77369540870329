import fieldNames from './field-names'
import pick from 'lodash/pick'
import merge from 'lodash/merge'

const formDefaultDriver = {
  defaultDriver: 'Owner for selected truck: \'%{name}\'',
  noDefaultDriver: 'No owner for selected truck',
}

const aggregateRevenues = {
  name: 'customer',
  revenue_in_dollars: 'revenue',
  tickets_count: 'tickets',
  date: 'date',
}

const extractionMappings = {
  text: 'extracted text',
  ticket_vendor: 'ticket vendor',
}

const materialsFieldNames = pick(fieldNames, [
  'name',
])

const companiesFieldNames = {
  ...pick(fieldNames, [
    'name',
    'address',
    'ledger_code',
    'email',
    'phone_number'
  ]),
  uses_consolidated_invoices: 'consolidate invoices?',
}

const sitesFieldNames = {
  ...pick(fieldNames, [
  'name',
  'company',
  'address',
  ]),
  google_maps_pin_link: 'google maps pin link',
  route_link: 'google maps route link',
  lat: 'lat',
  long: 'long',
  checkin_radius_in_miles: 'checkin radius in miles',
  internal_notes: 'internal notes',
}

const trucksFieldNames = {
  number: 'number',
  default_driver: 'owner',
}

const tonnageTicketsFieldNames = {
  ...pick(fieldNames, [
    'customer',
    'recipient',
    'driver',
    'material',
    'dropoff_site',
    'pickup_site',
    'truck',
    'fuel_surcharge_in_dollars',
    'material_surcharge_in_dollars',
    'base_rate_in_dollars',
    'ticket_number',
    'worked_at_date',
    'net_weight_in_tons',
  ]),
  tonnage_rate: 'tonnage rate',
  snapshot_mode: 'archived?',
  is_completable: 'complete',
  tonnage_ticket_invoice_period_end_date: 'transfer week end',
}

const hourlyTicketsFieldNames = {
  ...pick(fieldNames, [
    'customer',
    'truck',
    'driver',
    'ticket_number',
    'regular_rate_in_dollars',
    'overtime_rate_in_dollars',
    'doubletime_rate_in_dollars',
  ]),
  hourly_rate: 'hourly rate',
  regular_time_in_hours: 'regular hours',
  overtime_in_hours: 'overtime hours',
  doubletime_in_hours: 'doubletime hours',
  worked_at_date: 'date',
  snapshot_mode: 'archived?',
  hourly_ticket_invoice_period_end_date: 'transfer week end',
}

const hourlyTicketsForm = {
  general: {
    ...formDefaultDriver,
    currentRateValue: 'Suggested from current hourly rate: \'%{value}\'',
    autofilledValueFromRate: 'Autofilled from hourly rate: \'%{value}\'',
    noRate: 'No hourly rate found yet',
    selectedRate: 'Hourly rate: \'%{description}\'',
  },
  labels: {
    hourlyRate: 'Find hourly rate',
  },
  info: {
    formHelper: 'Find hourly rate.',
  },
}

const tonnageTicketsForm = {
  general: {
    ...formDefaultDriver,
    currentRateValue: 'Suggested from current tonnage rate: \'%{value}\'',
    autofilledValueFromRate: 'Autofilled from tonnage rate: \'%{value}\'',
    autofilledBlankValueFromRate: 'No value from current tonnage rate',
    noRate: 'No tonnage rate found yet',
  },
  info: {
    formHelper: 'Find tonnage rate by selecting customer, recipient, pickup site, dropoff site and material.',
  },
  validate: {
    tonnageTooHigh: 'Can not exceed %{max} tons',
  },
}

const aggregateProfitFieldNames = {
  sum_total_line_item_quantity: 'tickets',
  sum_regular_total_in_dollars: 'reg pay',
  sum_overtime_total_in_dollars: 'OT pay',
  sum_doubletime_total_in_dollars: 'DT pay',
  sum_total_in_dollars: 'total pay',
  ticket_date: 'ticket date',
  sum_income_total_in_dollars: 'total income',
  sum_hourly_income_total_in_dollars: 'hourly income',
  sum_tonnage_income_total_in_dollars: 'ton income',
  sum_profit_total_in_dollars: 'profit',
}

const tonnageRatesFieldNames = {
  code: 'S/O #',
  ...pick(fieldNames, [
    'description',
    'customer',
    'recipient',
    'material',
    'pickup_site',
    'dropoff_site',
    'base_rate_in_dollars',
    'fuel_surcharge_in_dollars',
    'material_surcharge_in_dollars',
    'is_active',
  ]),
}

const hourlyRatesFieldNames = {
  code: 'description', // TODO: change to 'code' if code field added to db
  ...pick(fieldNames, [
    'description',
    'customer',
    'regular_rate_in_dollars',
    'overtime_rate_in_dollars',
    'doubletime_rate_in_dollars',
    'is_active',
  ]),
}

const orders = {
  fieldNames: {
    status: 'status',
    order_number: 'order #',
    num_trucks: 'req. trucks',
    num_assigned_trucks: 'assigned trucks',
    is_created_by_customer: 'external?',
    num_available_trucks: 'available trucks',
    num_active_trucks: 'active trucks',
    num_pending_trucks: 'pending trucks',
    num_unassigned_trucks: 'unassigned trucks',
    num_remaining_trucks: 'rem. trucks',
    dispatches: 'dispatches',
  },
  form: {
    general: {
      edit: 'edit',
      missingPinLink: 'missing pin link',
    },
  },
}

const resources = {
  // conceptual combination tonnage + hourly tickets
  tickets: {
    fieldNames: {
      ...pick(fieldNames, [
        'ticket_number',
      ]),
    },
  },
  // shared tonnage + hourly orders
  orders: {
    status_type: {
      pending: 'pending',
      active: 'active',
      archived: 'archived',
      canceled: 'canceled',
    },
    hauler_acceptance_status: {
      accepted: 'accepted',
      declined: 'declined',
      none: 'none',
    },
    list: {
      activateAll: {
        buttonLabel: 'Activate',
        confirmTitle: 'Activate all pending orders',
        confirmContent: 'Are you sure?',
        isUpdating: 'Activating...',
        notifyFailure: 'Error activating orders',
        notifySuccess: 'Activated all pending orders',
      },
      deactivateAll: {
        buttonLabel: 'Deactivate',
        confirmTitle: 'Deactivate all active orders',
        confirmContent: 'Are you sure?',
        isUpdating: 'Deactivating...',
        notifyFailure: 'Error deactivating orders',
        notifySuccess: 'Deactivated all active orders',
      },
      cancelAll: {
        buttonLabel: 'Cancel',
        confirmTitle: 'Cancel all non-archived orders',
        confirmContent: 'Canceled orders cannot be reactivated. Are you sure?',
        isUpdating: 'Canceling...',
        notifyFailure: 'Error canceling orders',
        notifySuccess: 'Canceled all non-archived orders',
      },
      archiveAll: {
        buttonLabel: 'Archive',
        confirmTitle: 'Archive all orders',
        confirmContent: 'This cannot be undone. Are you sure?',
        isUpdating: 'Archiving...',
        notifyFailure: 'Error archiving orders',
        notifySuccess: 'Archived all orders',
      },
    },
  },
  tonnage_rates: {
    name: 'tonnage rate |||| tonnage rates',
    fieldNames: tonnageRatesFieldNames,
  },
  tonnage_tickets: {
    name: 'tonnage ticket |||| tonnage tickets',
    contextualType: 'tonnage',
    fieldNames: tonnageTicketsFieldNames,
    form: tonnageTicketsForm,
    list: {
      download: 'Download Images',
      downloadError: 'Error downloading tonnage ticket uploads',
    }
  },
  sites: {
    name: 'site |||| sites',
    fieldNames: sitesFieldNames,
    actions: {
      copyGoogleMapsPinLink: 'Copy pin link',
      copiedGoogleMapsPinLink: 'Copied pin link',
    },
  },
  materials: {
    name: 'material |||| materials',
    fieldNames: materialsFieldNames,
    form: {
      info: {
        noteText: "Note: do not create separate materals for different grades; i.e. only create '#53', not '#53 COMMERCIAL' and '#53 STATE'",
      },
    },
  },
  companies: {
    name: 'company |||| companies',
    fieldNames: companiesFieldNames,
  },
  broker_drivers: {
    name: 'truck owner |||| truck owners',
    fieldNames: {
      ...pick(fieldNames, [
        'name',
        'ledger_code',
        'percent_of_base_rate',
        'address',
        'hourly_work_deduction_in_dollars',
      ]),
      hourly_regular_rate_in_dollars: 'hourly reg rate',
      hourly_overtime_rate_in_dollars: 'hourly OT rate',
      hourly_doubletime_rate_in_dollars: 'hourly DT rate',
      is_surcharge_passthrough: 'paid full surcharges?',
      num_available_trucks: 'available trucks',
      num_active_trucks: 'active trucks',
      num_pending_trucks: 'pending trucks',
      num_unassigned_trucks: 'unassigned trucks',
      phone: 'phone',
      email: 'email',
    },
    form: {
      hourly_work_deduction: 'Rate deduction',
      hourly_rate: 'Rate',
    },
  },
  trucks: {
    name: 'truck |||| trucks',
    fieldNames: trucksFieldNames,
  },
  hourly_rates: {
    name: 'hourly rate |||| hourly rates',
    fieldNames: hourlyRatesFieldNames,
  },
  hourly_tickets: {
    name: 'hourly ticket |||| hourly tickets',
    contextualType: 'hourly',
    fieldNames: hourlyTicketsFieldNames,
    form: hourlyTicketsForm,
  },
  pending_hourly_tickets: {
    name: 'pending hourly ticket |||| pending hourly tickets',
    contextualType: 'hourly',
    fieldNames: hourlyTicketsFieldNames,
    form: {
      ...hourlyTicketsForm,
      edit: {
        saveButtonLabel: 'complete hourly ticket',
      },
    },
  },
  hourly_ticket_invoices: {
    name: 'hourly invoice |||| hourly invoices',
    fieldNames: {
      ...pick(fieldNames, [
        'customer',
        'total_in_dollars',
        'is_archived',
      ]),
      hourly_rate: 'rate',
      period_end_date: 'date',
      invoice_number: 'invoice #',
      total_line_item_quantity: 'tickets',
    },
    labels: {
      invoice: {
        workType: 'Hourly work',
        total_in_dollars: 'Total due',
        total_line_item_quantity: 'Total tickets',
      },
    },
  },
  invoice_hourly_tickets: {
    name: 'invoice hourly ticket |||| invoice hourly tickets',
    fieldNames: {
      ...pick(fieldNames, [
        'total_in_dollars',
        'ticket_number',
      ]),
      regular_time_in_hours: 'RG hr',
      regular_rate_in_dollars: 'RG rate',
      overtime_in_hours: 'OT hr',
      overtime_rate_in_dollars: 'OT rate',
      doubletime_in_hours: 'DT hr',
      doubletime_rate_in_dollars: 'DT rate',
      worked_at_date: 'date',
    },
  },
  tonnage_ticket_invoices: {
    name: 'tonnage invoice |||| tonnage invoices',
    fieldNames: {
      ...pick(fieldNames, [
        'customer',
        'total_in_dollars',
        'is_archived',
      ]),
      tonnage_rate: 'rate',
      period_end_date: 'date',
      invoice_number: 'invoice #',
      total_line_item_quantity: 'tickets',
      total_net_weight_in_tons: 'total tons',
    },
    labels: {
      invoice: {
        workType: 'Tonnage work',
        total_in_dollars: 'Total due',
        total_line_item_quantity: 'Total tickets',
        total_net_weight_in_tons: 'Total tons',
      },
    },
  },
  invoice_tonnage_tickets: {
    name: 'invoice tonnage ticket |||| invoice tonnage tickets',
    fieldNames: {
      ...pick(fieldNames, [
        'worked_at_date',
        'net_weight_in_tons',
        'fuel_surcharge_in_dollars',
        'material_surcharge_in_dollars',
        'total_in_dollars',
      ]),
      ticket_number: 'ticket #',
      base_rate_in_dollars: 'rate',
      tickets_count: 'tickets',
    },
  },
  transfers: {
    preview: {
      name: 'transfer',
      isTransferring: 'Transferring...',
      action: 'transfer',
      confirmContent: 'Transfer all tickets?\nThis cannot be undone.',
      confirmTitle: 'Transfer week ending %{date}',
      success: 'Successfully transferred tickets',
      error: 'Error transferring tickets',
      downloadError: 'Error downloading exports',
    },
  },
  broker_driver_pay_rollups: {
    name: 'account payable |||| accounts payable',
    fieldNames: {
      ...pick(fieldNames, [
        'total_in_dollars',
        'is_archived',
        'driver',
        'period_end_date',
      ]),
      total_line_item_quantity: 'tickets',
      regular_total_in_dollars: 'reg pay',
      overtime_total_in_dollars: 'OT pay',
      doubletime_total_in_dollars: 'DT pay',
    },
  },
  driver_pay_rollup_tickets: {
    name: 'hourly ticket |||| hourly tickets',
    fieldNames: {
      ...pick(fieldNames, [
        'date',
        'ticket_number',
        'net_weight_in_tons',
      ]),
      date: 'date',
      base_rate_in_dollars: 'ton rate',
      fuel_surcharge_in_dollars: 'fuel',
      material_surcharge_in_dollars: 'material',
      total_pay_in_dollars: 'total',
      driver_percent_of_base_rate: '% base',
      driver_percent_of_surcharge_rate: '% sur',
      regular_time_in_hours: 'RG hr',
      overtime_in_hours: 'OT hr',
      doubletime_in_hours: 'DT hr',
      regular_pay_rate_in_dollars: 'RG rate',
      overtime_pay_rate_in_dollars: 'OT rate',
      doubletime_pay_rate_in_dollars: 'DT rate',
    },
  },
  broker_driver_profits: {
    name: 'profit |||| profit',
    fieldNames: {
      ...pick(fieldNames, [
        'driver',
      ]),
      total_in_dollars: 'total pay',
      regular_total_in_dollars: 'reg pay',
      overtime_total_in_dollars: 'OT pay',
      doubletime_total_in_dollars: 'DT pay',
      hourly_income_total_in_dollars: 'hourly income',
      tonnage_income_total_in_dollars: 'tonnage income',
      income_total_in_dollars: 'total income',
      profit_total_in_dollars: 'total profit',
    },
  },
  broker_driver_aggregate_profits: {
    name: 'broker profit |||| broker profit',
    fieldNames: {
      ...pick(fieldNames, [
        'name',
      ]),
      ...aggregateProfitFieldNames,
    },
  },
  truck_aggregate_profits: {
    name: 'truck profit |||| truck profit',
    fieldNames: {
      ...trucksFieldNames,
      ...aggregateProfitFieldNames,
    },
  },
  periods: {
    name: 'period |||| periods',
    fieldNames: {
      end_date: 'week ending',
    },
  },
  tenants: {
    name: 'business',
    fieldNames: {
      ...pick(fieldNames, [
        'address',
        'name',
      ]),
    }
  },
  users: {
    name: 'account',
    fieldNames: {
      email: 'email',
      new_password: 'new password',
      old_password: 'old password',
    },
  },
  exports: {
    name: 'export configuration |||| export configurations',
    export_type: {
      invoice: 'invoice',
      broker_driver_pay_rollup: 'payroll',
    },
    fieldNames: {
      ...pick(fieldNames, [
        'name',
      ]),
      export_type: 'export type',
      include_csv_headers: 'include csv headers?',
    },
    form: {
      general: {
        typeDisabled: 'Remove all export fields to change export type',
      },
    },
  },
  export_fields: {
    fieldNames: {
      ...pick(fieldNames, [
        'name',
      ]),
      dynamic_value_type: 'dynamic value type',
      static_value: 'static value',
      is_negative: 'negative?',
    },
    dynamic_value_type: {
      _static: '-- leave blank for static value --',
      broker_driver_pay_rollup: {
        driver_ledger_code: 'driver ledger code',
        pay_rollup_date: 'payroll date',
        pay_rollup_total: 'payroll total',
      },
      invoice: {
        customer_ledger_code: 'customer ledger code',
        invoice_number: 'invoice number',
        invoice_date: 'invoice date',
        invoice_total: 'invoice total',
      }
    }
  },
  tonnage_revenues: {
    name: 'tonnage revenue |||| tonnage revenues',
    fieldNames: {
      ...aggregateRevenues,
      units_count: 'tons',
    }
  },
  hourly_revenues: {
    name: 'hourly revenue |||| hourly revenues',
    fieldNames: {
      ...aggregateRevenues,
      units_count: 'hours',
    }
  },
  tonnage_ticket_uploads: {
    name: 'ticket image upload |||| ticket image uploads',
    fieldNames: {
      created_at: 'uploaded on',
      ticket_image_url: 'file',
      ticket_image_filename: 'filename',
      sender: 'Sender',
      sender_truck: 'Truck',
      is_sms: 'sms?',
      tonnage_ticket_pdf_upload: 'PDF upload',
      imageUpload: 'image upload',
      lat: 'dropoff lat',
      long: 'dropoff long',
    },
    list: {
      download: 'Download',
      downloadError: 'Error downloading tonnage ticket uploads',
    },
    form: {
      actions: {
        create: 'upload',
      },
    },
  },
  tonnage_ticket_spreadsheet_uploads: {
    name: 'ticket spreadsheet upload |||| ticket spreadsheet uploads',
    fieldNames: {
      created_at: 'uploaded on',
      spreadsheet_file_url: 'file',
      spreadsheet_filename: 'filename',
      fileUpload: 'file upload',
      ticket_vendor: 'ticket vendor',
    },
    form: {
      actions: {
        create: 'upload',
      },
    },
  },
  tonnage_ticket_pdf_uploads: {
    name: 'ticket PDF upload |||| ticket PDF uploads',
    fieldNames: {
      created_at: 'uploaded on',
      pdf_file_url: 'file',
      pdf_filename: 'filename',
      fileUpload: 'file upload',
      page_count: 'pages total',
      tonnage_ticket_uploads_count: 'pages processed'
    },
    form: {
      actions: {
        create: 'upload',
      },
    },
  },
  tonnage_ticket_audits: {
    name: 'Tonnage ticket Audit |||| Tonnage ticket Audits',
    fieldNames: {
      created_at: 'uploaded on',
      pdf_file_url: 'file',
      spreadsheet_filename: 'filename',
      ticket_vendor: 'ticket vendor',
      fileUpload: 'file upload',
      page_count: 'pages total',
      tonnage_ticket_uploads_count: 'pages processed',
      rerun: 'rerun'
    },
    form: {
      actions: {
        create: 'upload',
      },
    },
  },
  tonnage_ticket_extractions: {
    name: 'ticket extraction |||| ticket extractions',
    fieldNames: {
      ...pick(fieldNames, [
        'ticket_number',
        'customer',
        'recipient',
        'material',
        'dropoff_site',
        'pickup_site',
        'net_weight_in_tons',
      ]),
      created_at: 'uploaded',
      weighed_at: 'date',
      truck_number: 'truck',
      has_error: 'error?',
    },
  },
  pending_tonnage_tickets: {
    name: 'pending tonnage ticket |||| pending tonnage tickets',
    fieldNames: tonnageTicketsFieldNames,
    form: {
      ...tonnageTicketsForm,
      edit: {
        extractedText: 'Extracted text: \'%{value}\'',
        saveButtonLabel: 'complete tonnage ticket',
      },
    },
    list: {
      completeAll: {
        buttonLabel: 'Complete all',
        confirmTitle: 'Complete %{smart_count} tonnage ticket |||| Complete %{smart_count} tonnage tickets',
        confirmContent: 'Complete all eligible tonnage tickets?\nUnsuccessful completions will remain in pending list for manual review or deletion.',
        isCompleting: 'Completing...',
        notifyFailure: 'Error completing tickets',
        notifySuccess: 'Completed %{smart_count} tonnage ticket |||| Completed %{smart_count} tonnage tickets',
      },
    },
  },
  pickup_sites: {
    name: 'pickup site |||| pickup sites',
  },
  dropoff_sites: {
    name: 'dropoff site |||| dropoff sites',
  },
  customers: {
    name: 'customer |||| customers',
  },
  recipients: {
    name: 'recipient |||| recipients',
  },
  pending_resources: {
    preview: {
      name: 'pending resources'
    },
    form: {
      actions: {
        createNew: 'create',
        mapExisting: 'link',
      },
      toggle: 'create new?'
    },
  },
  pending_material_extraction_mappings: {
    name: 'material extraction |||| material extractions',
    fieldNames: {
      ...extractionMappings,
      ...materialsFieldNames,
      resource_id: 'material',
    },
  },
  pending_customer_extraction_mappings: {
    name: 'customer extraction |||| customer extractions',
    fieldNames: {
      ...extractionMappings,
      ...companiesFieldNames,
      resource_id: 'company',
    },
  },
  pending_recipient_extraction_mappings: {
    name: 'recipient extraction |||| recipient extractions',
    fieldNames: {
      ...extractionMappings,
      ...companiesFieldNames,
      resource_id: 'company',
    },
  },
  pending_pickup_site_extraction_mappings: {
    name: 'pickup site extraction |||| pickup site extractions',
    fieldNames: {
      ...extractionMappings,
      ...sitesFieldNames,
      resource_id: 'site',
    },
  },
  pending_dropoff_site_extraction_mappings: {
    name: 'dropoff site extraction |||| dropoff site extractions',
    fieldNames: {
      ...extractionMappings,
      ...sitesFieldNames,
      resource_id: 'site',
    },
  },
  pending_truck_extraction_mappings: {
    name: 'trucks extraction |||| trucks extractions',
    fieldNames: {
      ...extractionMappings,
      ...trucksFieldNames,
      resource_id: 'truck',
    },
  },
  material_extraction_mappings: {
    name: 'material mapping |||| material mappings',
  },
  company_extraction_mappings: {
    name: 'company mapping |||| company mappings',
  },
  site_extraction_mappings: {
    name: 'site mapping |||| site mappings',
  },
  truck_extraction_mappings: {
    name: 'truck mapping |||| truck mappings',
  },
  extraction_mappings: {
    name: 'extraction mapping |||| extraction mappings',
    fieldNames: extractionMappings,
  },
  pending_tonnage_rates: {
    name: 'tonnage rate |||| tonnage rates',
    fieldNames: tonnageRatesFieldNames,
  },
  contacts: {
    name: 'contact |||| contacts',
    fieldNames: {
      phone: 'phone',
      broker_driver: 'truck owner',
      truck: 'truck',
      created_at: 'created',
    },
  },
  broker_drivers_users: {
    name: 'truck owner login |||| truck owner logins',
    fieldNames: {
      created_at: 'created',
      broker_driver: {
        name: 'truck owner',
      },
      user: {
        email: 'email',
        temp_password: 'temp password',
      },
    },
  },
  customers_users: {
    name: 'customer login |||| customer logins',
    fieldNames: {
      created_at: 'created',
      customer: {
        name: 'customer',
      },
      user: {
        email: 'email',
        temp_password: 'temp password',
      },
    },
  },
  tonnage_orders: merge({}, orders, {
    name: 'tonnage order |||| tonnage orders',
    fieldNames: {
      ...pick(fieldNames, [
        'customer',
        'recipient',
        'material',
        'dropoff_site',
        'pickup_site',
        'fuel_surcharge_in_dollars',
        'material_surcharge_in_dollars',
        'base_rate_in_dollars',
      ]),
      load_at: 'load at',
      tonnage_rate: 'rate',
      notes: 'Notes to all truck owners',
      tonnage_rate_code: tonnageRatesFieldNames.code,
      material_grade: 'material grade',
    },
    material_grade_type: {
      na: 'N/A',
      commercial: 'COMMERCIAL',
      state: 'STATE',
    },
    form: {
      general: {
        lookupRate: `Lookup tonnage rate by ${tonnageRatesFieldNames.code}`,
        selectedRateWithoutCode: `Selected tonnage rate has no ${tonnageRatesFieldNames.code}`,
        rateFetchError: 'Error finding rate details',
        route: 'Route',
        missingRouteLink: 'missing route link',
        noRate: 'No rate selected',
      },
      info: {
        formHelper: `Lookup tonnage rate by ${tonnageRatesFieldNames.code}. Pickup site and material listed in lookup options to disambiguate. Add dispatches to assign truck owners to the order. Active orders will show up on truck owner portals.`,
      },
    },
  }),
  hourly_orders: merge({}, orders, {
    name: 'hourly order |||| hourly orders',
    fieldNames: {
      ...pick(fieldNames, [
        'customer',
        'material',
        'regular_rate_in_dollars',
        'overtime_rate_in_dollars',
        'doubletime_rate_in_dollars',
      ]),
      arrive_at: 'arrive at',
      hourly_rate: 'rate',
      hourly_rate_code: `rate ${hourlyRatesFieldNames.code}`,
      location: 'location',
      hourly_work_type: 'work type',
    },
    form: {
      general: {
        lookupRate: `Lookup hourly rate by ${hourlyRatesFieldNames.code}`,
        jobInfoFetchError: 'Error finding job details',
        selectedRateWithoutCode: `Selected hourly rate has no ${hourlyRatesFieldNames.code}`,
        noJobInfo: 'No job info selected',
        jobInfo: 'Job Info',
      },
      info: {
        formHelper: `Lookup hourly rate by ${hourlyRatesFieldNames.code}. Add dispatches to assign truck owners to the order. Active orders will show up on truck owner portals.`,
      },
    },
  }),
  dispatches: {
    name: 'dispatch |||| dispatches',
    fieldNames: {
      broker_driver: 'truck owner',
      num_trucks: '# Trucks',
      hauler_acceptance_status: 'acceptance',
      notes: 'notes',
      is_canceled: 'canceled?',
    },
    form: {
      broker_driver: {
        num_unassigned_trucks: 'trucks',
      },
    },
  },
  dispatch_tickets_summaries: {
    fieldNames: {
      broker_driver: 'truck owner',
      num_trucks: 'assigned trucks',
      tickets_count: 'tickets',
      unique_trucks_count: 'trucks',
      hauler_acceptance_status: 'hauler accept?',
      is_canceled: 'canceled?',
    },
  },
  order_uploads: {
    name: 'order upload |||| order uploads',
    fieldNames: {
      created_at: 'uploaded at',
      customer: 'customer',
      image_filename: 'filename',
      is_created_by_customer: 'uploaded by customer?',
    },
  },
  hourly_work_types: {
    name: 'hourly work type |||| hourly work types',
    fieldNames: {
      name: 'name',
    },
  },
  locations: {
    name: 'location lookup |||| location lookups',
    form: {
      search_address: 'Search address',
      search_coordinates: 'Search lat, long',
      invalidCoordinates: 'Invalid lat, long coordinates',
      addressLabel: 'Nearest address',
      coordinatesLabel: 'Lat, long',
      submitButton: 'Apply',
    },
  },
}

export default {
  resources,
}
