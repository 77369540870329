import { useCallback, useMemo } from 'react'
import {
  useTranslate,
  DateField,
  NumberField,
} from 'react-admin'
import {
  Grid,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core'
import {
  dateFieldOptions,
  numberFieldOptions,
  currencyFieldOptions,
} from '../util/component-options'
import { capitalize } from '../../util'
import { useTranslateResource, useLabel } from '../../hooks'
import { DatagridWithSubtotals } from '../custom'
import { iSODateToDateOnlyString } from '../../util/date'

const useStyles = makeStyles(({ shape, spacing }) => {
  return {
    spaced: {
      marginBottom: spacing(6)
    },
    totals : {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'inherit',
      borderRadius: shape.borderRadius,
      '@media print': {
        pageBreakInside: 'avoid',
      },
    },
  }
})

const DetailsGrid = ({ resource, data, totals }) => {
  const label = useLabel({ resource })
  const dataWithSubtotals = useMemo(() => {
    return [{
      data,
      subtotals: totals,
    }]
  }, [data, totals])

  return (
    <DatagridWithSubtotals data={dataWithSubtotals}>
      <DateField source='date' label={label('date')} options={dateFieldOptions} />
      <NumberField source='revenue_in_dollars' label={label('revenue_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='units_count' label={label('units_count')} options={numberFieldOptions} />
      <NumberField source='tickets_count' label={label('tickets_count')} />
    </DatagridWithSubtotals>
  )
}

const DateRange = ({ startDate, endDate, align }) => {
  const translate = useTranslate()
  const adjustedEndDate = endDate ? endDate : iSODateToDateOnlyString(new Date())

  const formatDate = useCallback(date => {
    return new Date(date).toLocaleString([], {
      ...dateFieldOptions,
      weekday: 'short',
    })
  }, [])

  return (
    <>
      {
        startDate &&
        <Typography align={align}>
          {`${translate('revenue.startDate')} ${formatDate(startDate)}`}
        </Typography>
      }
      {
        <Typography align={align}>
          {`${translate('revenue.endDate')} ${formatDate(adjustedEndDate)}`}
        </Typography>
      }
    </>
  )
}

const Revenue = ({ record, resource }) => {
  const {
    name,
    end_date,
    start_date,
    tickets_count,
    totals_by_date,
    revenue_in_dollars,
    units_count
  } = record

  const translate = useTranslate()
  const translateResource = useTranslateResource(resource)
  const classes = useStyles()

  const totals = {
    tickets_count,
    revenue_in_dollars,
    units_count,
  }

  return (
    <Box>
      <Grid container className={classes.spaced}>
        <Grid item xs={6}>
          <Typography>
            {name}
          </Typography>
          <Typography>
            {`${capitalize(translateResource('name', { smart_count: 1 }))} ${translate('revenue.byDate')}`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <DateRange
            startDate={start_date}
            endDate={end_date}
            align='right'
          />
        </Grid>
      </Grid>

      <Grid container>
        <DetailsGrid resource={resource} data={totals_by_date} totals={totals} />
      </Grid>
    </Box>
  )
}

export default Revenue
