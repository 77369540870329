import React from 'react'
import { Edit } from 'react-admin'
import Form from './form'
import { editOptions } from '../../util/component-options'
import { EmbeddedExtractionMappingsList, EmbeddedSiteMap } from '../../shared'

const EditView = props => {
  return (
    <>
      <Form {...props}/>
      <EmbeddedSiteMap {...props} />
    </>
  )
}

const SitesEdit = props => {
  return (
    <>
      <Edit {...editOptions} {...props} >
        <EditView />
      </Edit>
      <EmbeddedExtractionMappingsList
        resource='site_extraction_mappings'
        filter={{
          site_id_eq: props.id,
        }}
      />
    </>
  )
}

export default SitesEdit
