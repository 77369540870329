import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { lighten } from '@material-ui/core'

const statusTypes = [
  'active',
  'pending',
  'canceled',
  'archived',
]

const materialGradeTypes = [
  'na',
  'commercial',
  'state',
]

const materialGradeDisplayNames = {
  commercial: 'COMM',
  state: 'STATE',
}

const statusTypeChoices = statusTypes.map(id => ({
  id,
  name: `resources.orders.status_type.${id}`,
}))

const materialGradeChoices = materialGradeTypes.map(id => ({
  id,
  name: `resources.tonnage_orders.material_grade_type.${id}`,
}))

const getMaterialAndGrade = (materialName, materialGradeKey) => {
  return [
    materialName,
    materialGradeDisplayNames[materialGradeKey] == null ? '' : ` (${materialGradeDisplayNames[materialGradeKey]})`,
  ].join('')
}

const haulerAcceptanceStatusTypes = {
  accepted: {
    name: 'resources.orders.hauler_acceptance_status.accepted',
    color: '#32A852',
    Icon: CheckIcon,
    canDelete: false,
  },
  declined: {
    name: 'resources.orders.hauler_acceptance_status.declined',
    color: '#F44336',
    Icon: CloseIcon,
    canDelete: true,
  },
  none: {
    name: 'resources.orders.hauler_acceptance_status.none',
    color: '#757575',
    canDelete: true,
  },
}

const cancelConfirmedProps = {
  true: {
    color: '#32A852',
    Icon: CheckIcon,
  },
  false: {
    color: '#F44336',
    Icon: CloseIcon,
  },
}

const orderAcceptanceStatusTypes = {
  all_accepted: {
    color: lighten(haulerAcceptanceStatusTypes.accepted.color, 0.89),
  },
  some_declined: {
    color: lighten(haulerAcceptanceStatusTypes.declined.color, 0.89),
  },
  pending: {
    color: 'white',
  },
}

const statusTypeColors = {
  pending: '#FCBA03',
  active: '#32A852',
  archived: '#B3B4B5',
  canceled: '#F44336',
}

export {
  statusTypeChoices,
  statusTypeColors,
  haulerAcceptanceStatusTypes,
  orderAcceptanceStatusTypes,
  cancelConfirmedProps,
  materialGradeChoices,
  getMaterialAndGrade,
}
