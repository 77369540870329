import { CACHE_DURATION_FILTER_KEY } from '../../json-provider/cache-proxy'
import { sorts } from '../../data-model'

const numberFieldOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}

const currencyFieldOptions = {
  ...numberFieldOptions,
  style: 'currency',
  currency: 'USD',
}

const dateTimeFieldOptions = {
  year:'numeric',
  month: '2-digit',
  day: '2-digit',
}

const dateFieldOptions = {
  year:'numeric',
  month: '2-digit',
  day: '2-digit',
  timeZone: 'UTC',
}

const dateWithTimeFieldOptions = {
  year:'numeric',
  month: 'numeric',
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
}

const formOptions = {
  submitOnEnter: false,
}

const formInputOptions = {
  variant: 'outlined',
  margin: 'dense',
  fullWidth: true,

  // try to trick chrome autocomplete
  inputProps: {
    id: null,
    autoComplete: 'chrome-off',
    name: null,
  },
}

const editOptions = {
  mutationMode: 'pessimistic',
}

const referenceInputOptions = (resource) => ({
  perPage: 1000,
  filter: {
    [CACHE_DURATION_FILTER_KEY]: 4 * 60 * 60 * 1000, // 4 hours
    is_active_true: true,
  },
  sort: sorts[resource],
})

const booleanFieldOptions = {
  FalseIcon: () => null,
}

export {
  numberFieldOptions,
  currencyFieldOptions,
  dateFieldOptions,
  dateWithTimeFieldOptions,
  formOptions,
  referenceInputOptions,
  editOptions,
  formInputOptions,
  booleanFieldOptions,
  dateTimeFieldOptions,
}
