import { useState, useCallback, useMemo } from 'react'
import {
  useDataProvider,
} from 'react-admin'
import useDownload from './use-download'

const useTransfer = (periodId) => {
  const { update } = useDataProvider()
  const [isLoading, setIsLoading] = useState(false)

  const downloadFilter = useMemo(() => {
    return {
      filter: {
        period_id_eq: periodId
      }
    }
  }, [periodId])

  const downloadZip = useDownload('periods', 'exports/download.zip', downloadFilter)

  const transfer = useCallback(async () => {
    let transferError
    let downloadError
    setIsLoading(true)

    try {
      await update('periods', {
        id: periodId,
        data: {
          is_completed: true,
        },
      })
    } catch(error) {
      transferError = error
    }

    if (!transferError) {
      try {
        await downloadZip()
      } catch(error) {
        downloadError = error
      }
    }

    setIsLoading(false)
    return [transferError, downloadError]
  }, [downloadZip, update, periodId])

  return [
    transfer,
    isLoading,
  ]
}

export default useTransfer
