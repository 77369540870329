const overrides = {
  RaLayout: {
    root: {
      minWidth: 'initial',
    },
    content: {
      minWidth: 0,
    },
  },
  RaList: {
    content: {
      overflowX: 'hidden',
    },
  },
  MuiFormControl: {
    marginDense: {
      marginBottom: 12,
    },
  },
  MuiFormControlLabel: {
    root: {
      width: 'fit-content',
    },
  },
  RaBooleanField: {
    root: {
      justifyContent: 'center',
    },
  },
  MuiSnackbarContent: {
    message: {
      whiteSpace: 'pre-line',
    },
  },
  MuiDialogContentText: {
    root: {
      whiteSpace: 'pre-line',
    },
  },
  RaFilterFormInput: {
    body: {
      width: '100%'
    },
    spacer: {
      width: 0,
    },
  },
  RaListToolbar: {
    toolbar: {
      position: 'sticky',
      top: 48,
      zIndex: 3,
      padding: '0 !important',
      minHeight: '0 !important',
    },
  },
  MuiToolbar: {
    dense: {
      minHeight: 0,
    },
  },
  MuiTablePagination: {
    toolbar: {
      minHeight: 0,
    },
  },
  MuiFormHelperText: {
    root: {
      whiteSpace: 'pre-line',
      fontSize: '0.95em',
      lineHeight: 1.2,
      minHeight: '1.2em',
      '&:empty': {
        display: 'none',
      },
    },
  },
  RaPaginationActions: {
    hellip: {
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
    },
    button: {
      minWidth: 40,
    },
  },
  RaEdit: {
    card: {
      overflow: 'initial',
    },
  },
  RaNullableBooleanInput: {
    input: {
      width: '100%',
    },
  },
  RaDatagrid: {
    expandHeader: {
      width: 1,
    },
    expandIconCell: {
      width: 1,
    },
    expandIcon: {
      padding: 5,
    },
  },
}

export default overrides
