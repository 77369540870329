import { Pagination as RaPagination } from 'react-admin'

const Pagination = props => {
  const { loaded } = props
  return (
    loaded &&
    <RaPagination
      rowsPerPageOptions={[]}
      {...props}
    />
  )
}

export default Pagination
