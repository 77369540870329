import { useMemo } from 'react'

const useHourlyRateFields = () => (
  useMemo(() => ([
    {
      source: 'regular_rate_in_dollars',
      label: 'regular_rate_in_dollars',
      required: true,
    },
    {
      source: 'overtime_rate_in_dollars',
      label: 'overtime_rate_in_dollars',
      required: true,
    },
    {
      source: 'doubletime_rate_in_dollars',
      label: 'doubletime_rate_in_dollars',
      required: true,
    },
  ]),[])
);

export default useHourlyRateFields
