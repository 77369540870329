import { useMemo } from 'react'
import {
  useTranslate,
  useUpdate,
  useRefresh,
} from 'react-admin'
import { statusTypeChoices } from './util'
import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core'
import { useCallback, useRef, useState } from 'react'
import StatusColor from './status-color'
import { useNotifyResponse } from '../../form/functions'

const useStyles = makeStyles(({ spacing }) => {
  return {
    button: {
      padding: spacing(0.5),
      width: '100%',
      fontSize: '1em',
      textTransform: 'inherit',
      letterSpacing: 'inherit',
    },
  }
})

const StatusSelect = ({ record, resource }) => {
  const ref = useRef()
  const classes = useStyles()
  const [ update, { loading } ] = useUpdate(resource)
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const refresh = useRefresh()
  const { notifyFailure } = useNotifyResponse({ resource, record })

  const onItemClick = useCallback(status => {
    if (record.status !== status && !loading) {
      update({
        payload: {
          id: record.id,
          data: { status }
        },
      },
      {
        onSuccess: refresh,
        onFailure: notifyFailure,
      })
    }
    setOpen(false)
  }, [update, record, loading, refresh, notifyFailure])

  const onClick = useCallback(()=> {
    setOpen(!open)
  }, [open])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const selected = useMemo(() => {
    return statusTypeChoices.find(c => c.id === record.status)
  }, [record])

  return (
    <>
      <Button
        onClick={onClick}
        ref={ref}
        className={classes.button}
      >
        <StatusColor status={selected.id}>
          {translate(selected.name)}
        </StatusColor>
      </Button>
      <Menu
        variant='menu'
        onClose={onClose}
        open={open}
        anchorEl={ref.current}
      >
        {
          statusTypeChoices.map(c => (
            <MenuItem
              selected={c.id === selected.id}
              key={c.id}
              onClick={onItemClick.bind(null, c.id)}
            >
              <StatusColor status={c.id}>
                {translate(c.name)}
              </StatusColor>
            </MenuItem>
          ))
        }
      </Menu>
    </>
  )

}

export default StatusSelect
