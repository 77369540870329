import { List as RaList } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import { Card, CardActions } from '@material-ui/core'
import ListHeader from './list-header'
import { sorts } from '../../data-model'

const StyledRaList = withStyles(() => ({
  content: {
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
}))(RaList)

const HeadersCard = withStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(1),
  }
}))(Card)

const Header = props => {
  return (
    <HeadersCard >
      <CardActions>
        <ListHeader {...props} />
      </CardActions>
    </HeadersCard>
  )
}

const List = props => {
  const { children, filters, actions, sort, ...rest } = props
  const { resource } = props

  const finalSort = {
    ...sorts[resource],
    ...sort,
  }

  const { filterDefaultValues } = rest

  return (
    <StyledRaList
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      empty={false}
      actions={<Header filters={filters} actions={actions} filterDefaultValues={filterDefaultValues} />}
      pagination={false}
      sort={finalSort}
      {...rest}
    >
      {children}
    </StyledRaList>
  )
}

export default List
