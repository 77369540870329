import React from 'react'
import { Notification as RaNotification } from 'react-admin'

const Notification = props => {
  return (
    <RaNotification
      {...props}
    />
  )
}

export default Notification
