import React, { useCallback, useMemo, useState, useRef, useEffect, forwardRef } from 'react'
import { useInput } from 'ra-core'
import { InputHelperText } from 'react-admin'
import { TextField, CircularProgress, InputAdornment } from '@material-ui/core'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import get from 'lodash/get'

// react-admin ReferenceInput controls options and filtering by passing this component
// choices and setFilter. When this component calls 'setFilter', ReferenceInput
// kicks off async server call with the new filter, but passes choices as empty array.
// MuiAutocomplete then renders empty state while awaiting server response, resulting
// in a jarring UX. This is a workaround to smooth the UX by maintaining
// previous non-loading choices while loading.

// EDIT: we avoid calling 'setFilter' because it replaces existing options set with
// filtered options set and slows performance. Alternativey, we fetch the entire options set
// (default 1000 max) and rely on front-end MUI component text filtering.
// Lookout for large data sets causing memory issues or total sets exceeding 1000 max!

// EDIT: pass 'useServerToFilter' (default false) to rely on server response to filtered options set, otherwise
// default is to use client-side to filter options.

const useOptions = (choices, loading, preserveChoicesOnLoading) => {
  const prevChoices = useRef(choices)

  const options = useMemo(() => {
    return loading && preserveChoicesOnLoading ? prevChoices.current : choices
  }, [choices, loading, preserveChoicesOnLoading])

  useEffect(() => {
    if (!loading) {
      prevChoices.current = choices
    }
  }, [loading, choices])

  return options
}

const useStyles =  makeStyles(theme => {
  const { spacing, typography: { caption } } = theme
  const optionColSpacing = 0.6

  const optionCol = {
    paddingLeft: spacing(optionColSpacing),
    paddingRight: spacing(optionColSpacing),
  }

  return {
    option: {
      display: 'flex',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      marginLeft: spacing(-optionColSpacing),
      marginRight: spacing(-optionColSpacing),
    },
    optionCol: {
      ...optionCol,
    },
    subtextCol: {
      ...caption,
      ...optionCol,
    },
    loadingAdornment: {
      marginRight: spacing(0.5),
    },
  }
})

const Autocomplete = forwardRef((props, ref) => {
  const classes = useStyles(props)
  const {
    className,
    label,
    choices,
    variant,
    margin,
    helperText,
    required,
    disabled,
    autoFocus,
    optionText = 'name',
    optionValue = 'id',
    optionSubtext,
    getOptionSubtext,
    loading,
    inputProps = {},
    setFilter,
    useServerToFilter = false,
    onInputChange: _onInputChange,
    preserveChoicesOnLoading = false,
    disableFilterOptions = false,
    matchFrom = 'any', // any | start
  } = props

  const {
    isRequired,
    input: {
      onBlur,
      value: _value,
      onChange: _onChange,
    },
    meta: {
      touched,
      error: _error,
      submitError,
    }
  } = useInput(props)

  const error = useMemo(() => {
    return _error ?? submitError
  }, [_error, submitError])

  const [inputValue, setInputValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const getOptionTexts = useCallback((option) => {
    const text = get(option, optionText)
    const subtext = optionSubtext ? get(option, optionSubtext) : (
      getOptionSubtext ? getOptionSubtext(option) : null
    )
    return {
      text,
      subtext,
    }
  }, [optionSubtext, getOptionSubtext, optionText])

  const filteredOptions = useMemo(() => {
    return choices.filter(choice => {
      const optionTexts = getOptionTexts(choice)
      return Boolean(Object.values(optionTexts).filter(Boolean).length)
    })
  }, [choices, getOptionTexts])

  const options = useOptions(filteredOptions, loading, preserveChoicesOnLoading)

  const hasLoader = loading && isOpen

  const filterOptions = useMemo(() => {
    return disableFilterOptions ?
      (opts) => opts :
      createFilterOptions({ matchFrom })
  }, [matchFrom, disableFilterOptions])

  const renderInput = useCallback(textFieldProps => {
    const {
      inputProps: textFieldInputProps,
      InputProps: InputPropsDefault,
      ...rest
    } = textFieldProps

    const finalInputProps = {
      ...textFieldInputProps,
      ...inputProps,
    }

    const InputPropsFinal = {
      ...InputPropsDefault,
      endAdornment: hasLoader ? (
        <InputAdornment>
          <CircularProgress size={20} className={classes.loadingAdornment}/>
        </InputAdornment>
      ) : InputPropsDefault.endAdornment
    }

    return (
      <TextField
        {...rest}
        InputProps={InputPropsFinal}
        label={label}
        variant={variant}
        required={isRequired || required}
        margin={margin}
        error={Boolean(touched && error)}
        autoFocus={autoFocus}
        inputProps={finalInputProps}
        inputRef={ref}
        helperText={<InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />}
      />
    )
  }, [
    label,
    variant,
    isRequired,
    inputProps,
    autoFocus,
    required,
    margin,
    touched,
    error,
    helperText,
    hasLoader,
    classes,
    ref,
  ])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const getOptionLabel = useCallback(option => {
    return option[optionText] ?? ''
  }, [optionText])

  const onChange = useCallback((_, option) => {
    _onChange(option?.[optionValue])
  }, [_onChange, optionValue])

  const onInputChange = useCallback((_, text, type) => {
    _onInputChange && _onInputChange(text)
    // see note above
    if (useServerToFilter && type !== 'reset') setFilter(text)
    setInputValue(text)
  }, [useServerToFilter, setFilter, _onInputChange])

  const getOptionSelected = useCallback((option, selected) => {
    return option[optionValue] === selected[optionValue]
  }, [optionValue])

  const renderOption = useCallback((option) => {
    const { text, subtext } = getOptionTexts(option)

    return (
      <Box className={classes.option}>
        {
          text &&
            <Box className={classes.optionCol}>
              {text}
            </Box>
        }
        {
          subtext &&
          <Box className={classes.subtextCol}>
            {subtext}
          </Box>
        }
      </Box>
    )
  }, [classes, getOptionTexts])

  const value = useMemo(() => {
    return options.find(option => option[optionValue] === _value) ?? null
  }, [_value, options, optionValue])

  return (
    <MuiAutocomplete
      options={options}
      className={className}
      inputValue={inputValue}
      value={value}
      disabled={disabled}
      open={isOpen}
      forcePopupIcon={!disabled && !hasLoader}
      popupIcon={hasLoader ? false : undefined}
      disableClearable={hasLoader}
      onChange={onChange}
      onBlur={onBlur}
      onOpen={onOpen}
      onClose={onClose}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      renderOption={renderOption}
      onInputChange={onInputChange}
      getOptionSelected={getOptionSelected}
      autoHighlight
      loading={false}
      filterOptions={filterOptions}
    />
  )
})

export default Autocomplete
