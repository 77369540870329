import { NumberField, useListContext } from 'react-admin'
import { CollectionDataTable } from '../../shared'
import { currencyFieldOptions, numberFieldOptions } from '../../util/component-options'
import { useLabel } from '../../../hooks'

const CollectionData = props => {
  const context = useListContext()
  const label = useLabel(context)
  
  return (
    <CollectionDataTable {...props}>
      <NumberField source='total_in_dollars' label={label('total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_line_item_quantity' label={label('total_line_item_quantity')} />
      <NumberField source='total_net_weight_in_tons' label={label('total_net_weight_in_tons')} options={numberFieldOptions} />
    </CollectionDataTable>
  )
}

export default CollectionData
