import { useEffect } from 'react'

const useInterval = (cb, ms, skip = false) => {
  useEffect(() => {
    const interval = skip ? null : setInterval(cb, ms)
    return () => clearInterval(interval)
  }, [cb, ms, skip])
}

export default useInterval
