import { useMemo } from 'react'

const useTonnageRateReferenceFields = () => (
  useMemo(() => ([
    {
      source: 'customer',
      label: 'customer',
      required: true,
      optionText: 'name',
    },
    {
      source: 'recipient',
      label: 'recipient',
      required: true,
      optionText: 'name',
    },
    {
      source: 'pickup_site',
      label: 'pickup_site',
      required: true,
      optionText: 'name',
    },
    {
      source: 'dropoff_site',
      label: 'dropoff_site',
      required: true,
      optionText: 'name',
    },
    {
      source: 'material',
      label: 'material',
      required: true,
      optionText: 'name',
    },
  ]),[])
);

export default useTonnageRateReferenceFields
