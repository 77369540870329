import {
  SimpleFormIterator as RaSimpleFormIterator,
  useTranslate,
} from 'react-admin'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { IconButton, Button } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline'
import AddIcon from '@material-ui/icons/AddCircleOutline'

const useStyles = makeStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}))

const StyledSimpleFormIterator = withStyles(theme => {
  return {
    index: {
      width: '2.5ch',
    },
    form: {
      marginRight: theme.spacing(0.5),
    },
    line: {
      padding: theme.spacing(1.3),
      paddingBottom: theme.spacing(0.5),
      marginTop: theme.spacing(1.3),
      borderRadius: theme.shape.borderRadius,
      border: `2px solid ${theme.palette.divider}`
    },
    root: {
      // add button
      '& > li:last-child': {
        border: 'none',
        padding: 0,
        marginTop: theme.spacing(0.4),
      },
    },
  }
})(RaSimpleFormIterator)

const RemoveButton = props => {
  return (
    <IconButton
      {...props}
      size='small'
      tabIndex='-1'
    >
      <RemoveIcon size='small' />
    </IconButton>
  )
}

const AddButton = props => {
  const classes = useStyles(props)
  const translate = useTranslate()
  return (
    <Button
      size='small'
      tabIndex='-1'
      {...props}
    >
      <AddIcon className={classes.leftIcon} />
      {translate('ra.action.add')}
    </Button>
  )
}

const SimpleFormIterator = ({ children, ...rest }) => {
  return (
    <StyledSimpleFormIterator
      {...rest}
      removeButton={<RemoveButton />}
      addButton={<AddButton />}
    >
      {children}
    </StyledSimpleFormIterator>

  )
}

export default SimpleFormIterator
