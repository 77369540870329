import {
  TextField,
  NumberField,
  DateField,
  useListContext,
} from 'react-admin'
import Invoice from './invoice'
import { GridExpand } from '../../shared'
import { Datagrid } from '../../custom'
import {
  currencyFieldOptions,
  dateFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'

const Expand = (props) => (
  <GridExpand {...props}>
    <Invoice/>
  </GridExpand>
)

const HourlyTicketInvoicesDatagrid = ({ isPrintView }) => {
  const context = useListContext()
  const label = useLabel(context)

  return (
    <Datagrid
      hasEdit={false}
      expand={isPrintView ? undefined : <Expand />}
    >
      <TextField source='invoice_number' label={label('invoice_number')} />
      <TextField source='customer.name' label={label('customer')} sortBy='customer_name' />
      <TextField source='hourly_rate.description' label={label('hourly_rate')} sortBy='hourly_rate_description' />
      <NumberField source='total_in_dollars' label={label('total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_line_item_quantity' label={label('total_line_item_quantity')} />
      <DateField source='period.end_date' label={label('period_end_date')} options={dateFieldOptions} sortBy='period_end_date' />
    </Datagrid>
  )
}
export default HourlyTicketInvoicesDatagrid
