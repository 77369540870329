import { CreateButton, useListContext } from 'react-admin'
import { useCallback, useMemo, useState, cloneElement } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Button, Drawer, Grid, Typography } from '@material-ui/core'
import Pagination from './pagination'
const StyledFilterDrawer = withStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    width: 360,
  }
}))(Drawer)

const FilterButtonGrid = withStyles(() => ({
  root: {
    width: 'auto',
    flexWrap: 'nowrap',
  }
}))(Grid)

const useStyles = makeStyles((theme) => {
  return {
    paginationGridItem: {
      marginLeft: 'auto',
      '& > .MuiCardContent-root': {
        padding: `${theme.spacing(1)}px !important`,
      },
    },
    gridContainer: {
      minHeight: 56,
    },
    title: {
      textTransform: 'capitalize',
    },
  }
})

const ListHeader = props => {
  const { filters, actions, title, ...rest } = props
  const context = useListContext()

  const {
    filterValues,
    setFilters,
    displayedFilters,
    filterDefaultValues = {},
    resource,
    hasCreate,
    basePath,
    loaded,
  } = {
    ...context,
    ...rest
  }

  const [drawerOpen, setDrawerOpen] = useState(false)
  const classes = useStyles()

  const onFilterButtonClick = useCallback(() => {
    setDrawerOpen(true)
  }, [])

  const onFilterClose = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const onClearFiltersClick = useCallback(() => {
    setFilters(filterDefaultValues)
  }, [setFilters, filterDefaultValues])

  const hasAppliedFilters = useMemo(() => {
    return JSON.stringify(filterValues || {}) !== JSON.stringify(filterDefaultValues || {})
  }, [filterDefaultValues, filterValues])

  return (
    <Grid container spacing={2} alignItems='center' className={classes.gridContainer}>
      {
        title &&
          <Grid item>
            <Typography className={classes.title} variant='h6'>
              {title}
            </Typography>
          </Grid>
      }
      <Grid item>
        <FilterButtonGrid spacing={2} container>
          {
            hasCreate &&
            <Grid item>
              <CreateButton
                variant='text'
                size='small'
                basePath={basePath}
              />
            </Grid>
          }
          {actions}
          {
            filters &&
            <>
              <Grid item>
                <Button
                  variant='text'
                  size='small'
                  onClick={onFilterButtonClick}
                >
                  Filters
                </Button>
              </Grid>
              {
                hasAppliedFilters &&
                <Grid item>
                  <Button
                    variant='text'
                    size='small'
                    onClick={onClearFiltersClick}
                  >
                    Clear
                  </Button>
                </Grid >
              }
            </>
          }
        </FilterButtonGrid>
        <StyledFilterDrawer
          open={drawerOpen}
          onClose={onFilterClose}
        >
          {
            filters &&
            cloneElement(filters, {
              displayedFilters,
              filterValues,
              resource,
            })
          }
        </StyledFilterDrawer>
      </Grid>
      <Grid className={classes.paginationGridItem} item>
        <Pagination loaded={loaded} />
      </Grid>
    </Grid>
  )
}

export default ListHeader
