import {
  TextInput,
  ArrayInput,
  SelectInput,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import pick from 'lodash/pick'
import differenceBy from 'lodash/differenceBy'
import { formInputOptions } from '../../util/component-options'
import { dynamicValueTypeChoices } from '../export-fields/util'
import { useLabel, useTranslateResource } from '../../../hooks'
import { getValidator } from '../../form'
import { SimpleFormIterator } from '../../custom'

const StyledArrayInput = withStyles(theme => {
  return {
    marginNormal: {
      marginTop: theme.spacing(0.5),
    },
  }
})(ArrayInput)

const transform = (record, data) => {
  const { export_fields: oldExportFields } = record
  const { export_fields: newExportFields = [] } = data

  const deleted = differenceBy(oldExportFields, newExportFields, 'id')

  return {
    export_fields_attributes: [
      ...newExportFields.map((f, idx) => ({
        ...pick(f, [
          'id',
          'name',
          'dynamic_value_type',
        ]),
        order_index: idx,
        is_negative: Boolean(f.is_negative && f.dynamic_value_type),
        static_value: f.dynamic_value_type ? null : f.static_value,
      })),
      ...deleted.map(f => ({
        ...pick(f, [
          'id',
        ]),
        _destroy: true,
      })),
    ]
  }
}

const Inputs = props => {
  const { resource } = props
  const label = useLabel({ resource })
  const translate = useTranslateResource(resource)

  return (
    <SimpleFormIterator {...props}>
      <FormDataConsumer>
        {({ getSource, scopedFormData, formData }) => {
          const dynamicValueSelected = Boolean(scopedFormData?.dynamic_value_type)
          const typeChoices = dynamicValueTypeChoices[formData.export_type]

          return (
            <>
              <TextInput
                {...formInputOptions}
                required
                validate={getValidator()}
                source={getSource('name')}
                label={label('name')}
              />

              <SelectInput
                {...formInputOptions}
                source={getSource('dynamic_value_type')}
                allowEmpty
                emptyText={translate('dynamic_value_type._static')}
                choices={typeChoices}
                label={label('dynamic_value_type')}
              />

              {
                dynamicValueSelected ?
                <BooleanInput
                  source={getSource('is_negative')}
                  label={label('is_negative')}
                /> :
                <TextInput
                  {...formInputOptions}
                  required
                  validate={getValidator()}
                  source={getSource('static_value')}
                  label={label('static_value')}
                />
              }

            </>
          )
        }}
      </FormDataConsumer>
    </SimpleFormIterator>
  )
}

const ExportFieldsFieldArray = () => {
  return (
    <StyledArrayInput
      resource='export_fields'
      source='export_fields'
    >
      <Inputs />
    </StyledArrayInput>
  )
}

export default ExportFieldsFieldArray

export { transform }
