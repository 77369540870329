import {
  TextInput,
  TextField,
  NumberField,
  useListContext,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { List, Filter, Datagrid, DateInput, NumberInput } from '../custom'
import { useLabel } from '../../hooks'
import {
  currencyFieldOptions,
  numberFieldOptions,
} from '../util/component-options'
import { GridExpand, Revenue } from '../shared'

const RevenuesFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <DateInput label={label('date', 'earliest')} source='date_gteq' />
      <DateInput label={label('date', 'latest')} source='date_lteq' />
      <TextInput label={label('name', 'filter')} source='name_i_cont' />
      <NumberInput label={label('tickets_count', 'filter', 'greaterThan')} source='tickets_count_gteq' />
      <NumberInput label={label('revenue_in_dollars', 'filter', 'greaterThan')} source='revenue_in_dollars_gteq' />
      <NumberInput label={label('units_count', 'filter', 'greaterThan')} source='units_count_gteq' />
    </Filter>
  )
}

const useStyles = makeStyles(theme => {
  return {
    revenue: {
      maxWidth: 800,
    },
  }
})

const Expand = (props) => {
  const { filterValues } = useListContext()
  const classes = useStyles()

  return (
    <GridExpand {...props} payload={filterValues} className={classes.revenue} >
      <Revenue resource={props.resource} />
    </GridExpand>
  )
}

const RevenuesList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<RevenuesFilter />}
    >
      <Datagrid
        hasEdit={false}
        expand={<Expand />}
      >
        <TextField source='name' label={label('name')} />
        <NumberField source='revenue_in_dollars' label={label('revenue_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='units_count' label={label('units_count')} options={numberFieldOptions} />
        <NumberField source='tickets_count' label={label('tickets_count')} />
      </Datagrid>
    </List>
  )
}

export default RevenuesList
