import { useCallback, useState } from 'react'

const generateNonce = () => {
  return new Date().getTime()
}

const useNonce = () => {
  const [nonce, setNonce] = useState(() => generateNonce())

  const resetNonce = useCallback(() => {
    setNonce(generateNonce())  
  }, [])

  return [
    nonce,
    resetNonce,
  ]
}

export default useNonce
