import { Grid, Typography, Box, makeStyles } from '@material-ui/core'
import { colors, markerTypes } from './props'
import { useTranslate } from 'react-admin'

const translationPrefix = 'views.dispatchMap'
const types = Object.keys(markerTypes)

const useStyles = makeStyles(() => {
  return {
    color: {
      padding: '0.36em',
      marginRight: '0.2em',
      borderRadius: '2em',
    },
  }
})

const Legend = () => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Box>
      {
        types.map(type => {
          return (
            <Grid container key={type} wrap='nowrap' alignItems='center'>
              <Grid item>
                <Box className={classes.color} style={{ background: colors[type].primary }} />
              </Grid>
              <Grid item>
                <Typography variant='caption'>
                  {translate(`${translationPrefix}.legend.${type}`)}
                </Typography>
              </Grid>
            </Grid>
          )
        })
      }
    </Box>
  )
}

export default Legend
