import React from 'react'
import { Sidebar as RaSidebar } from 'react-admin'

const Sidebar = props => {
  return (
    <RaSidebar
      {...props}
    />
  )
}

export default Sidebar
