import { useCallback } from 'react'
import { useQueryWithStore } from 'react-admin'

const useGetPendingResources = () => {
  const queryProps = useCallback((resource) => {
    return {
      type: 'getList',
      resource,
      payload: {
        filter: {
          only_meta: true,
        },
        pagination: {
          perPage: 0,
          page: 1,
        },
      },
    }
  }, [])

  const pending_customer_extraction_mappings = useQueryWithStore(queryProps('pending_customer_extraction_mappings'))
  const pending_recipient_extraction_mappings = useQueryWithStore(queryProps('pending_recipient_extraction_mappings'))
  const pending_material_extraction_mappings = useQueryWithStore(queryProps('pending_material_extraction_mappings'))
  const pending_pickup_site_extraction_mappings = useQueryWithStore(queryProps('pending_pickup_site_extraction_mappings'))
  const pending_dropoff_site_extraction_mappings = useQueryWithStore(queryProps('pending_dropoff_site_extraction_mappings'))
  const pending_truck_extraction_mappings = useQueryWithStore(queryProps('pending_truck_extraction_mappings'))
  const pending_tonnage_rates = useQueryWithStore(queryProps('pending_tonnage_rates'))

  return {
    pending_customer_extraction_mappings,
    pending_recipient_extraction_mappings,
    pending_material_extraction_mappings,
    pending_pickup_site_extraction_mappings,
    pending_dropoff_site_extraction_mappings,
    pending_truck_extraction_mappings,
    pending_tonnage_rates,
  }
}

export default useGetPendingResources
