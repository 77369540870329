import React from 'react'
import {
  TextField,
  TextInput,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import { useLabel } from '../../../hooks'

const MaterialsFilter = props => {
  const label = useLabel(props)
  
  return (
    <Filter>
      <TextInput label={label('name', 'filter')} source='name_i_cont' />
    </Filter>
  )
}

const MaterialsList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<MaterialsFilter />}
    >
      <Datagrid>
        <TextField source='name' label={label('name')} />
      </Datagrid>
    </List>
  )
}

export default MaterialsList
