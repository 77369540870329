import {
  NumberField,
  TextField,
  FunctionField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { DataTable } from '..'
import { useLabel } from '../../../hooks'
import { haulerAcceptanceStatusTypes, cancelConfirmedProps } from './util'

const useStyles = makeStyles(() => {
  return {
    table: {
      width: 'auto',
    },
    cellContent: {
      fontWeight: 'normal',
    },
    alignCenter: {
      textAlign: 'center',
    },
  }
})

const TicketsSummary = ({ record }) => {
  const label = useLabel({ resource: 'dispatch_tickets_summaries' })
  const classes = useStyles()

  const data = record.dispatches

  return (
    <DataTable data={data} tableClassName={classes.table} cellContentClassName={classes.cellContent}>
      <TextField source='broker_driver.name' label={label('broker_driver')} />
      <FunctionField cellClassName={classes.alignCenter} label={label('hauler_acceptance_status')} render={record => {
        const { hauler_acceptance_status } = record
        if (hauler_acceptance_status) {
          const { Icon, color } = haulerAcceptanceStatusTypes[hauler_acceptance_status]
          return <Icon fontSize='small' htmlColor={color} />
        } else {
          return null
        }
      }} />
      <FunctionField cellClassName={classes.alignCenter} label={label('is_canceled')} render={dispatchRecord => {
        const { is_canceled, is_cancel_confirmed, hauler_acceptance_status } = dispatchRecord
        if (is_canceled || (record.status === 'canceled' && hauler_acceptance_status === 'accepted')) {
          const { Icon, color } = cancelConfirmedProps[is_cancel_confirmed]
          return <Icon fontSize='small' htmlColor={color} />
        } else {
          return null
        }
      }} />
      <NumberField source='tickets_count' label={label('tickets_count')} />
      <NumberField source='unique_trucks_count' label={label('unique_trucks_count')} />
      <NumberField source='num_trucks' label={label('num_trucks')} />
    </DataTable>
  )
}

export default TicketsSummary
