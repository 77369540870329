import { useMemo } from 'react'

const useAddressInputFields = (prefix = 'address') => (
  useMemo(() => ([
    {
      source: `${prefix}_street_1`,
      addressSource: 'street_1',
      label: 'address.street_1',
      required: true,
    },
    {
      source: `${prefix}_street_2`,
      addressSource: 'street_2',
      label: 'address.street_2',
      required: false,
    },
    {
      source: `${prefix}_city`,
      addressSource: 'city',
      label: 'address.city',
      required: true,
    },
    {
      source: `${prefix}_united_state_id`,
      addressSource: 'united_state_id',
      label: 'address.state',
      reference: 'united_states',
      required: true,
    },
    {
      source: `${prefix}_zip`,
      addressSource: 'zip',
      label: 'address.zip',
      required: true,
    },
  ]),[prefix])
);

export default useAddressInputFields
