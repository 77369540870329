import { Autocomplete } from '../custom'
import { Form } from 'react-final-form'
import { useCallback, useContext, useMemo, useEffect, useState } from 'react'
import { useFilterState } from 'ra-core'
import { useTranslate, DataProviderContext } from 'react-admin'
import { useLabel } from '../../hooks'
import cancelablePromise from '../util/cancelable-promise'

const emptyRecord = {}
const polymorphicDelimiter = '-'
const emptyChoices = []

const LookupForm = ({ onChange: propsOnChange }) => {
  const [tonnageLoading, setTonnageLoading] = useState(false)
  const [hourlyLoading, setHourlyLoading] = useState(false)
  const [tonnageChoices, setTonnageChoices] = useState(emptyChoices)
  const [hourlyChoices, setHourlyChoices] = useState(emptyChoices)

  // Don't overwrite cache with results
  const dataProvider = useContext(DataProviderContext)

  const onSubmit = useCallback(e => {
    e.preventDefault()
    return false
  }, [])

  const { filter, setFilter } = useFilterState({
    filterToQuery: v => ({ ticket_number_eq: v }),
    debounceTime: 300,
  })

  const translate = useTranslate()
  const label = useLabel({ resource: 'tickets'})

  const hasFilter = useMemo(() => {
    return Boolean(filter.ticket_number_eq)
  }, [filter])

  const queryOptions = useMemo(() => {
    return {
      filter: {
        ...filter,
        view: 'search',
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        perPage: 1000,
        page: 1,
      },
    }
  }, [filter])

  const clearChoices = useCallback(() => {
    setTonnageChoices(emptyChoices)
    setHourlyChoices(emptyChoices)
  }, [])

  useEffect(() => {
    let cancelPromise
    if (hasFilter) {
      const fn = (async () => {
        const p = Promise.all([
          dataProvider.getList('tonnage_tickets', queryOptions),
          dataProvider.getList('hourly_tickets', queryOptions),
        ])
        const { promise, cancel } = cancelablePromise(p)
        cancelPromise = cancel
        const [{ data: tonnageData }, { data: hourlyData }] = await promise
        setTonnageLoading(false)
        setTonnageChoices(tonnageData)
        setHourlyLoading(false)
        setHourlyChoices(hourlyData)
      })
      setTonnageLoading(true)
      setHourlyLoading(true)
      clearChoices()
      fn()
    }
    return cancelPromise
  }, [hasFilter, dataProvider, queryOptions, clearChoices])

  const loading = hourlyLoading || tonnageLoading

  const choices = useMemo(() => {
    const combined = [
      ...tonnageChoices,
      ...hourlyChoices,
    ].map(t => {
      return {
        ...t,
        polymorphicId: `${t.type}${polymorphicDelimiter}${t.id}`,
      }
    })

    const sortFn = ((a, b) => {
      // created_at desc
      if (a.created_at > b.created_at) return -1
      if (a.created_at < b.created_at) return 1
      return 0
    })

    return combined.sort(sortFn)
  }, [tonnageChoices, hourlyChoices])

  const onChange = useCallback(polymorphicId => {
    if (polymorphicId) {
      propsOnChange(...polymorphicId.split(polymorphicDelimiter))
    }
  }, [propsOnChange])

  const getOptionSubtext = useCallback(opt => {
    return `(${translate(`resources.${opt.type}.contextualType`)}) ${opt.customer.name}`
  }, [translate])

  return (
    <Form
      onSubmit={onSubmit}
      render={() => (
        <Autocomplete
          label={label('ticket_number')}
          onChange={onChange}
          setFilter={setFilter}
          getOptionSubtext={getOptionSubtext}
          record={emptyRecord}
          choices={choices}
          loading={loading}
          variant='outlined'
          onInputChange={clearChoices}
          margin='dense'
          optionValue='polymorphicId'
          source='ticket_filter' // nonsense
          optionText='ticket_number'
          autoFocus
          useServerToFilter
        />
      )}
    />
  )
}

export default LookupForm
