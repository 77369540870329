import { useMemo } from 'react'
import { useRedirect, Edit } from 'react-admin'
import Form from './form'
import { editOptions } from '../../util/component-options'

const resource = 'tenants'

const TenantsEdit = () => {
  const redirect = useRedirect()

  const formOptions = useMemo(() => ({
    onUpdateSuccess: () => {
      redirect('/')
    },
  }), [redirect]);

  return (
    <Edit
      {...editOptions}
      id='0'
      resource={resource}
      basePath={`/${resource}`}
    >
      <Form
        {...formOptions}
        isDeleteDisabled
      />
    </Edit>
  )
}
export default TenantsEdit
