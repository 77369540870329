import {
  useState,
  useCallback,
  useMemo,
} from 'react'
import {
  useListContext,
  useRefresh,
  useQueryWithStore,
  Confirm,
  useNotify,
} from 'react-admin'
import {
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { useFetch, useTranslateResource } from '../../../hooks'

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    paddingBottom: spacing(3),
  },
  button: {
    minWidth: '12em',
  },
}))

const CompleteAllButton = (props) => {
  const refresh = useRefresh()
  const { resource } = useListContext()
  const translate = useTranslateResource(resource, 'list.completeAll')
  const classes = useStyles()
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const notify = useNotify()

  const onCompleteButtonClick = useCallback(() => {
    setConfirmIsOpen(true)
  }, [])

  const onConfirmClose = useCallback(() => {
    setConfirmIsOpen(false)
  }, [])

  const onFailure = useCallback(() => {
    notify('ra.notification.http_error', 'error')
  }, [notify])

  const onSuccess = useCallback(data => {
    setConfirmIsOpen(false)
    notify('resources.pending_tonnage_tickets.list.completeAll.notifySuccess', 'info', { smart_count: data.success_count })
    refresh()
  }, [refresh, notify])

  const [complete, isCompleting] = useFetch({ url: '/pending_tonnage_tickets/complete_all', onFailure, onSuccess })

  const { total, loading: countLoading } = useQueryWithStore({
    type: 'getList',
    resource,
    payload: {
      filter: {
        only_meta: true,
        is_completable_true: true,
      },
      pagination: {
        perPage: 0,
        page: 1,
      },
    },
  })

  const buttonLabel = useMemo(() => {
    return [
      translate('buttonLabel'),
      total == null ? null : `(${total})`,
    ].filter(Boolean).join(' ')
  }, [translate, total])

  return (
    <>
      <Grid item>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          size='small'
          onClick={onCompleteButtonClick}
          disabled={countLoading || !total}
        >
          {buttonLabel}
        </Button>
      </Grid>
      <Confirm
        isOpen={confirmIsOpen}
        loading={isCompleting}
        onConfirm={complete}
        title={translate('confirmTitle', { smart_count: total })}
        content={isCompleting ? translate('isCompleting') : translate('confirmContent')}
        onClose={onConfirmClose}
      />
    </>
  )
}

export default CompleteAllButton
