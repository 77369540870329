import { CHANGE_UI } from '../../actions'

const defaultState = {
  tonnageTicketInvoiceShowDetail: false,
}

const reducer = (previousState = defaultState, data) => {
  const { type } = data

  if (type === CHANGE_UI) {
    return {
      ...previousState,
      ...data,
    }
  }

  return previousState
}

export default reducer
