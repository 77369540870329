// https://marmelab.com/react-admin/Theming.html#writing-a-custom-theme

import { createMuiTheme } from "@material-ui/core";
import { defaultTheme } from 'react-admin'
import merge from 'lodash/merge'
import overrides from './overrides'
import palette from './palette'

const theme = createMuiTheme(
  merge({}, defaultTheme, {
    palette,
    overrides,
    typography: {
      fontFamily: 'Helvetica, sans-serif',
    },
    sidebar: {
      width: 240,
      closedWidth: 48,
    },
  })
)

export default theme
