import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Typography, IconButton } from '@material-ui/core'
import NextIcon from '@material-ui/icons/ChevronRight'
import PrevIcon from '@material-ui/icons/ChevronLeft'
import { dateFieldOptions } from '../util/component-options'
import { dateOnlyStringToISODate, iSODateToDateOnlyString } from '../../util/date'
import debounce from 'lodash/debounce'

const DateFilter = ({ date: propsDate, onChange, isDisabled }) => {
  const [startDate, setStartDate] = useState(propsDate)

  const debouncedOnChange = useMemo(() => {
    return debounce(onChange, 500)
  }, [onChange])

  useEffect(() => {
    debouncedOnChange(startDate)
  }, [debouncedOnChange, startDate])

  const onNextClick = useCallback(() => {
    setStartDate(prev => iSODateToDateOnlyString(dateOnlyStringToISODate(prev, 1)))
  }, [])

  const onPrevClick = useCallback(() => {
    setStartDate(prev => iSODateToDateOnlyString(dateOnlyStringToISODate(prev, -1)))
  }, [])

  return (
    <Grid item>
      <Grid container alignItems='center'>
        <Grid item>
          <Typography variant='subtitle2'>
            {`Date: ${new Date(startDate).toLocaleString([], dateFieldOptions)}`}
          </Typography>
        </Grid>

        <Grid item>
          <IconButton
            onClick={onPrevClick}
            size='small'
            disabled={isDisabled}
          >
            <PrevIcon />
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton
            onClick={onNextClick}
            size='small'
            disabled={isDisabled}
          >
            <NextIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DateFilter
