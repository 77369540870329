import { useCallback, useMemo } from 'react'

const useSoundEffect = file => {
  const audio = useMemo(() => {
    return new Audio(file)
  }, [file])
  
  return useCallback(() => {
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }, [audio])
}

export default useSoundEffect
