import { useSelector } from 'react-redux'
import { useMediaQuery, Box, makeStyles, withStyles } from '@material-ui/core'
import { MenuItemLink, getResources, useTranslate } from 'react-admin'
import { withRouter } from 'react-router-dom'
import ListIcon from '@material-ui/icons/List'
import TransferIcon from '@material-ui/icons/Sync'
import DispatchMapIcon from '@material-ui/icons/LocationOn'
import PendingResourceIcon from '@material-ui/icons/PlaylistAdd'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))

const StyledMenuItemLink = withStyles(theme => ({
  root: {
    textTransform: 'capitalize',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  icon: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
}))(MenuItemLink)

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <StyledMenuItemLink
        className={classes.item}
        key='transfer'
        to='/transfer'
        primaryText={translate('resources.transfers.preview.name')}
        leftIcon={<TransferIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      <StyledMenuItemLink
        className={classes.item}
        key='pending_resources'
        to='/pending_resources'
        primaryText={translate('resources.pending_resources.preview.name')}
        leftIcon={<PendingResourceIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      <StyledMenuItemLink
        className={classes.item}
        key='dispatch_map'
        to='/dispatch_map'
        primaryText={translate('views.dispatchMap.name')}
        leftIcon={<DispatchMapIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      {resources.map(resource => (
        resource.options.isMenuItem &&
        <StyledMenuItemLink
          className={classes.item}
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`resources.${resource.name}.name`, { smart_count: 2 })}
          leftIcon={resource.icon || <ListIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {isXSmall && logout}
    </Box>
  )
}

export default withRouter(Menu)
