import {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import { colors, markerZIndex } from './props'
import {
  useMap,
} from '@vis.gl/react-google-maps'
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer'

const clusterSvgStr = ({ color, count }) => {
  return `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
    <circle cx="120" cy="120" opacity=".68" r="70" />
    <circle cx="120" cy="120" opacity=".36" r="90" />
    <circle cx="120" cy="120" opacity=".24" r="110" />
    <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-weight="bold" font-family="roboto,arial,sans-serif">${count}</text>
  </svg>`
}

const useClustering = ({ markers, getClusterType, clusterRadius }) => {
  const google = window.google
  const markerDataByRef = useRef(new Map())
  const clusterer = useRef(null)
  const map = useMap()
  const [clusters, setClusters] = useState([])
  const [markerRefs, setMarkerRefs] = useState({})

  const renderCluster = useCallback(({ count, position, markers }) => {
    const reduced = markers.reduce((acc, markerRef) => {
      const markerData = markerDataByRef.current.get(markerRef)
      if (!markerData) return acc
      const { type, title } = markerData
      return {
        ...acc,
        types: acc.types.add(type),
        titles: [...acc.titles, title],
      }
    }, { types: new Set(), titles: [] })

    const type = getClusterType(Array.from(reduced.types))
    const title = reduced.titles.join('\n')
    const color = colors[type].primary
    const svg = clusterSvgStr({ color, count })
    const zIndex = markerZIndex.cluster[type] + count

    const div = document.createElement('div')
    div.innerHTML = svg
    const content = div.firstElementChild
    content.setAttribute('transform', 'translate(0, 25)')

    return new google.maps.marker.AdvancedMarkerElement({
      title,
      content,
      position,
      zIndex,
    })
  }, [google, getClusterType])

  const setMarkerRef = useCallback((marker, { key }) => {
    setMarkerRefs(prev => {
      if (!(Boolean(marker) ^ Boolean(prev[key]))) return prev
      const next = {...prev}
      if (marker) {
        next[key] = marker
      } else {
        delete next[key]
      }
      return next
    })
  }, [])

  useEffect(() => {
    markerDataByRef.current = markers.reduce((acc, marker) => {
      const markerRef = markerRefs[marker.key]
      if (markerRef) acc.set(markerRef, marker)
      return acc
    }, new Map())
  }, [markerRefs, markers])

  useEffect(() => {
    if (!clusterer?.current) return
    clusterer.current.clearMarkers()
    clusterer.current.addMarkers(Object.values(markerRefs))
  }, [markerRefs])

  useEffect(() => {
    let listener
    if (!map) return

    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        algorithm: new SuperClusterAlgorithm({
          maxZoom: 22,
          radius: clusterRadius,
        }),
        renderer: {
          render: renderCluster,
        },
      })
    }

    listener = clusterer.current.addListener('clusteringend', ({ clusters }) => setClusters(clusters))
    return () => {
      listener?.remove()
    }
  }, [map, renderCluster, clusterRadius])

  return {
    clusters,
    setMarkerRef,
    markerDataByRef,
  }
}

export default useClustering
