import React from 'react'
import {
  TextField,
  NumberField,
  TextInput,
  NullableBooleanInput,
  BooleanField,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import {
  currencyFieldOptions,
  booleanFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  {
    booleanFieldHeader: {
      textAlign: 'center',
    },
  },
)

const TonnageRatesFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('description', 'filter')} source='description_i_cont' />
      <TextInput label={label('customer', 'filter')} source='customer_name_i_cont' />
      <TextInput label={label('recipient', 'filter')} source='recipient_name_i_cont' />
      <TextInput label={label('material', 'filter')} source='material_name_i_cont' />
      <TextInput label={label('pickup_site', 'filter')} source='pickup_site_name_i_cont' />
      <TextInput label={label('dropoff_site', 'filter')} source='dropoff_site_name_i_cont' />
      <NullableBooleanInput label={label('is_active')} source='is_active_true' />
    </Filter>
  )
}

const TonnageRatesList = props => {
  const label = useLabel(props)
  const classes = useStyles(props)

  return (
    <List
      {...props}
      filters={<TonnageRatesFilter />}
      filterDefaultValues={{ is_active_true: true }}
    >
      <Datagrid>
        <TextField source='code' label={label('code')} />
        <TextField source='customer.name' sortBy='customer_name' label={label('customer')} />
        <TextField source='recipient.name' sortBy='recipient_name' label={label('recipient')} />
        <TextField source='material.name' sortBy='material_name' label={label('material')} />
        <TextField source='pickup_site.name' sortBy='pickup_site_name' label={label('pickup_site')} />
        <TextField source='dropoff_site.name' sortBy='dropoff_site_name' label={label('dropoff_site')} />
        <NumberField source='base_rate_in_dollars' label={label('base_rate_in_dollars')} options={currencyFieldOptions}/>
        <NumberField source='fuel_surcharge_in_dollars' label={label('fuel_surcharge_in_dollars')} options={currencyFieldOptions}/>
        <NumberField source='material_surcharge_in_dollars' label={label('material_surcharge_in_dollars')} options={currencyFieldOptions}/>
        <TextField source='description' label={label('description')} />
        <BooleanField {...booleanFieldOptions} source='is_active' label={label('is_active')} headerClassName={classes.booleanFieldHeader} />
      </Datagrid>
    </List>
  )
}

export default TonnageRatesList
