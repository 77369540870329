import {
  TextField,
  DateField,
  TextInput,
  NumberField,
  Link as RaLink,
  BooleanField,
  useRecordContext,
} from 'react-admin'
import {
  Datagrid as CustomDatagrid,
  List,
  Filter
} from '../../custom'
import SourceableLinkIcon from '@material-ui/icons/Description'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import {
  dateFieldOptions,
  dateTimeFieldOptions,
  numberFieldOptions,
  booleanFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'

const Link = withStyles(() => ({
  link: {
    display: 'flex',
  },
}))(RaLink)

const useStyles = makeStyles(
  {
    booleanFieldHeader: {
      textAlign: 'center',
    },
  },
)

const Datagrid = withStyles(() => ({
  rowCell: {
    whiteSpace: 'initial',
    textOverflow: 'initial',
    overflow: 'initial',
    maxWidth: 'none',
  },
}))(CustomDatagrid)

const TonnageTicketExtractionsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('ticket_number', 'find')} source='ticket_number_start' />
    </Filter>
  )
}

const SourceableLink = (props) => {
  const record = useRecordContext(props)
  const basePath = get(record, 'sourceable_base_path')
  const id = get(record, 'sourceable_id')
  return (
    <Link to={`/${basePath}/${id}`}>
      <SourceableLinkIcon fontSize='small'/>
    </Link>
  )
}

const TonnageTicketExtractionsList = props => {
  const label = useLabel(props)
  const classes = useStyles(props)

  return (
    <List
      {...props}
      filters={<TonnageTicketExtractionsFilter />}
    >
      <Datagrid
        hasEdit={false}
      >
        <SourceableLink />
        <TextField source='ticket_number' label={label('ticket_number')} />
        <TextField source='customer' label={label('customer')} />
        <TextField source='recipient' label={label('recipient')} />
        <TextField source='material' label={label('material')} />
        <TextField source='pickup_site' label={label('pickup_site')} />
        <TextField source='dropoff_site' label={label('dropoff_site')} />
        <TextField source='truck_number' label={label('truck_number')} />
        <NumberField source='net_weight_in_tons' label={label('net_weight_in_tons')} options={numberFieldOptions}/>
        <DateField source='weighed_at' label={label('weighed_at')} options={dateFieldOptions} />
        <DateField source='created_at' label={label('created_at')} options={dateTimeFieldOptions}/>
        <BooleanField {...booleanFieldOptions} source='has_error' label={label('has_error')} headerClassName={classes.booleanFieldHeader} />
      </Datagrid>
    </List>
  )
}

export default TonnageTicketExtractionsList
