import {
  TextField,
  NumberField,
  useListContext,
  DateField,
  FunctionField,
  BooleanField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Datagrid } from '../../custom'
import { useLabel } from '../../../hooks'
import {
  dateWithTimeFieldOptions,
  booleanFieldOptions,
} from '../../util/component-options'
import StatusSelect from '../../shared/orders/status-select'
import { GridExpand } from '../../shared'
import TicketsSummary from '../../shared/orders/tickets-summary'
import rowStyle from '../../shared/orders/acceptance-status-row-style'

const Expand = (props) => (
  <GridExpand {...props} resource='hourly_order_tickets_summaries' enablePrint={false}>
    <TicketsSummary />
  </GridExpand>
)

const useStyles = makeStyles(({ spacing }) => ({
  booleanFieldHeader: {
    textAlign: 'center',
  },
  statusSelectCell: {
    padding: spacing(0.5),
  }
}))

const HourlyOrdersDatagrid = () => {
  const context = useListContext()
  const { resource } = context
  const label = useLabel(context)
  const classes = useStyles()

  return (
    <Datagrid
      expand={<Expand />}
      rowStyle={rowStyle}
    >
      <FunctionField label={label('status')} cellClassName={classes.statusSelectCell} sortBy='status' render={record => (
        <StatusSelect record={record} resource={resource} />
      )} />
      <BooleanField source='is_created_by_customer' label={label('is_created_by_customer')} headerClassName={classes.booleanFieldHeader} {...booleanFieldOptions} />
      <DateField source='arrive_at' label={label('arrive_at')} options={dateWithTimeFieldOptions} />
      <NumberField source='num_trucks' label={label('num_trucks')} />
      <NumberField source='num_assigned_trucks' label={label('num_assigned_trucks')} />
      <NumberField source='num_remaining_trucks' label={label('num_remaining_trucks')} />
      <TextField source='order_number' label={label('order_number')} />
      <TextField source='hourly_rate.description' sortBy='hourly_rate_description' label={label('hourly_rate')} />
      <TextField source='hourly_rate.customer.name' sortBy='hourly_rate_customer_name' label={label('customer')} />
      <TextField source='location.name' sortBy='location_name' label={label('location')} />
      <TextField source='hourly_work_type.name' sortBy='hourly_work_type_name' label={label('hourly_work_type')} />
    </Datagrid>
  )
}

export default HourlyOrdersDatagrid
