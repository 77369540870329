import { Autocomplete } from '../custom'
import { Form } from 'react-final-form'
import { useCallback, useMemo, useEffect, useState, Children, cloneElement, useRef } from 'react'
import { useFilterState } from 'ra-core'
import { useFetch, useTranslateResource } from '../../hooks'
import { Tabs, Tab, Box, Typography, Button } from '@material-ui/core'
import { TextInput } from 'react-admin'
import { formInputOptions } from '../util/component-options'
import { InlineLoading } from '../shared'
import pick from 'lodash/pick'
import { useNotifyResponse } from '../form/functions'

const emptyRecord = {}

const Location = ({ label, value, lat, long }) => {
  if (!value) return null
  return (
    <Box>
      <Box>
        <Typography component='span' variant='body2' style={{ fontWeight: 'bold' }}>
          {`${label}: `}
        </Typography>
        <Typography component='span' variant='body2'>
          {value}
        </Typography>
      </Box>
      <Box marginTop={1} height={280}>
        <iframe
          title='location'
          style={{ border: 0 }}
          width='100%'
          height='100%'
          loading='lazy'
          src={`https://maps.google.com/maps?q=${lat},${long}&hl=en&z=10&output=embed`}
        />
      </Box>
    </Box>
  )
}

const FormWrapper = ({ children, resultLabel, resultValue, isLoading, formData, onSubmit }) => {
  const translate = useTranslateResource('locations', 'form')

  return (
    <Box>
      <Form
        onSubmit={onSubmit.bind(null, formData)}
        render={({ handleSubmit }) => (
          <form>
            {children}
            <Box marginTop={1}>
              {
                isLoading ?
                <InlineLoading /> :
                <Location
                  label={resultLabel}
                  value={resultValue}
                  lat={formData?.lat}
                  long={formData?.long}
                />
              }
            </Box>
            {
              !isLoading && formData &&
              <Box marginTop={2}>
                <Button
                  variant='contained'
                  color='primary'
                  type='button'
                  onClick={handleSubmit}
                >
                  {translate('submitButton')}
                </Button>
              </Box>
            }
          </form>
        )}
      />
    </Box>
  )
}

const useFetchLocation = ({ shouldFetch, url, query }) => {
  const [data, setData] = useState(null)

  const onSuccess = useCallback(({ data }) => {
    setData(data)
  }, [])

  const { notifyFailure } = useNotifyResponse({ record: emptyRecord, resource: 'locations' })

  const [_fetch, isLoading, cancelFetch] = useFetch({ method: 'get', onSuccess, onFailure: notifyFailure })

  useEffect(() => {
    if (shouldFetch) {
      setData(null)
      _fetch({ url, query })
    }
    return cancelFetch.current
  }, [shouldFetch, url, query, _fetch, cancelFetch])

  return [
    data,
    isLoading,
  ]
}

const PositionLookup = ({ isVisible, onSubmit }) => {
  const ref = useRef(null)
  const translate = useTranslateResource('locations', 'form')

  const filterToQuery = useCallback(v => {
    if (!v) return {}
    const [lat, long] = v.split(',').map(s => {
      const f = parseFloat(s)
      return isNaN(f) ? null : f.toFixed(6)
    })
    if (!lat || !long) return {}
    return { lat, long }
  }, [])

  const { filter, setFilter } = useFilterState({
    filterToQuery,
    debounceTime: 1000,
  })

  const validate = useCallback(v => {
    const q = filterToQuery(v)
    if (v && (!q.lat || !q.long)) {
      return translate('invalidCoordinates')
    }
  }, [filterToQuery, translate])

  const onInputChange = useCallback(e => {
    setFilter(e.target.value)
  }, [setFilter])

  const fetchLocationProps = useMemo(() => {
    return {
      shouldFetch: Boolean(filter.lat && filter.long),
      url: '/locations/position',
      query: filter,
    }
  }, [filter])

  const [location, isLoading] = useFetchLocation(fetchLocationProps)

  useEffect(() => {
    if (isVisible) ref.current.focus()
  }, [isVisible])

  const formData = useMemo(() => {
    if (!location) return null
    if (!Boolean(filter.lat && filter.long)) return null

    return {
      ...pick(location, [
        'city',
        'street_1',
        'united_state_id',
        'zip',
      ]),
      lat: filter.lat,
      long: filter.long,
    }
  }, [location, filter])

  return (
    <FormWrapper
      isLoading={isLoading}
      resultLabel={translate('addressLabel')}
      resultValue={location?.label}
      formData={formData}
      onSubmit={onSubmit}
    >
      <TextInput
        {...formInputOptions}
        source='search_coordinates'
        defaultValue=''
        record={emptyRecord}
        onChange={onInputChange}
        validate={validate}
        label={translate('search_coordinates')}
        inputRef={ref}
      />
    </FormWrapper>
  )
}

const AddressLookup = ({ isVisible, onSubmit }) => {
  const [selectedId, setSelectedId] = useState(null)
  const ref = useRef(null)

  const translate = useTranslateResource('locations', 'form')

  const { filter, setFilter } = useFilterState({
    filterToQuery: v => ({ text: v }),
    debounceTime: 1000,
  })

  useEffect(() => {
    if (isVisible) ref.current.focus()
  }, [isVisible])

  const fetchChoicesProps = useMemo(() => {
    return {
      shouldFetch: Boolean(filter.text),
      url: '/locations/suggest',
      query: filter,
    }
  }, [filter])

  const fetchLocationProps = useMemo(() => {
    return {
      shouldFetch: Boolean(selectedId),
      url: `/locations/${selectedId}`,
      query: filter,
    }
  }, [filter, selectedId])

  const [choices, isLoadingChoices] = useFetchLocation(fetchChoicesProps)
  const [location, isLoadingLocation] = useFetchLocation(fetchLocationProps)

  const formData = useMemo(() => {
    if (!location) return null
    return pick(location, [
      'long',
      'lat',
      'city',
      'street_1',
      'united_state_id',
      'zip',
    ])
  }, [location])

  const resultValue = useMemo(() => {
    if (!location) return null
    return [
      location.lat,
      location.long,
    ].join(', ')
  }, [location])

  return (
    <FormWrapper
      isLoading={isLoadingLocation}
      resultLabel={translate('coordinatesLabel')}
      resultValue={resultValue}
      formData={formData}
      onSubmit={onSubmit}
    >
      <Autocomplete
        {...formInputOptions}
        ref={ref}
        label={translate('search_address')}
        onChange={setSelectedId}
        setFilter={setFilter}
        record={emptyRecord}
        choices={choices || []}
        loading={isLoadingChoices}
        source='search_address'
        optionText='text'
        useServerToFilter
        disableFilterOptions
      />
    </FormWrapper>
  )
}

const TabPanel = ({ children, value, index }) => {
  const isVisible = value === index

  return (
    <Box hidden={value !== index}>
      {Children.map(children, c => cloneElement(c, { isVisible }))}
    </Box>
  )
}

const LookupForm = ({ onSubmit }) => {
  const [tabValue, setTabValue] = useState(0)

  const onTabChange = useCallback((_, v) => {
    setTabValue(v)
  }, [])

  return (
    <Box>
      <Box marginBottom={3}>
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor='primary'
          variant='fullWidth'
        >
          <Tab label='Address' />
          <Tab label='Coordinates' />
        </Tabs>
      </Box>
      <Box marginBottom={2}>
        <TabPanel value={tabValue} index={0}>
          <AddressLookup onSubmit={onSubmit} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <PositionLookup onSubmit={onSubmit} />
        </TabPanel>
      </Box>
    </Box>
  )
}

export default LookupForm
