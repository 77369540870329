import { useMemo } from 'react'

const useTonnageRateFields = () => (
  useMemo(() => ([
    {
      source: 'base_rate_in_dollars',
      label: 'base_rate_in_dollars',
      required: true,
    },
    {
      source: 'fuel_surcharge_in_dollars',
      label: 'fuel_surcharge_in_dollars',
      required: false,
    },
    {
      source: 'material_surcharge_in_dollars',
      label: 'material_surcharge_in_dollars',
      required: false,
    },
  ]),[])
);

export default useTonnageRateFields
