import {
  memo,
  useMemo,
} from 'react'
import {
  AdvancedMarker,
} from '@vis.gl/react-google-maps'
import DirectionIcon from '@material-ui/icons/KeyboardArrowUp'
import { colors, markerZIndex, baseLabelStyles } from './props'
import { Box, makeStyles } from '@material-ui/core'

const size = 18

const { position: { primary, secondary } } = colors
const { position: zIndex } = markerZIndex

const useStyles = makeStyles(() => {
  const { fontSize, borderWidth, padding, lineHeight } = baseLabelStyles
  const margin = 1
  return {
    label: {
      ...baseLabelStyles,
      top: `calc(50% - (${((2 * lineHeight * fontSize) + 2 * (padding + borderWidth)) / 2 + margin}px))`,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: primary,
      borderColor: secondary,
    },
    labelTitle: {
      fontSize: '1.1em',
    },
    labelSubtitle: {
    },
  }
})

const Marker = memo(({ marker, position, title, heading, subTitle, setMarkerRef, onClick }) => {
  const classes = useStyles()

  const transform = useMemo(() => {
    return [
      `translate(0, ${size / 2})`,
      heading != null && `rotate(${heading})`
    ].filter(Boolean).join(' ')
  }, [heading])

  return (
    <AdvancedMarker
      position={position}
      title={title}
      onClick={onClick?.bind(null, marker)}
      ref={ref => setMarkerRef(ref, marker)}
      zIndex={zIndex}
    >
      <Box display='flex'>
        <svg
          transform={transform}
          opacity={0.9}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 240 240'
          width={size}
          height={size}
        >
          <circle cx='120' cy='120' r='120' fill={secondary} />
          <circle cx='120' cy='120' r='100' fill={primary} />
          {
            heading != null &&
            <DirectionIcon fontSize='small' htmlColor='#FFF'/>
          }
        </svg>
        <Box className={classes.label}>
          <Box className={classes.labelTitle}>{title}</Box>
          <Box className={classes.labelSubtitle}>{subTitle}</Box>
        </Box>
      </Box>
    </AdvancedMarker>
  )
})

export default Marker
export { size }
