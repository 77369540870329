import { useMemo, useCallback } from 'react'
import {
  TextInput,
  BooleanInput,
  SelectInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import pick from 'lodash/pick'
import { useLabel, useTranslateResource } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { exportTypeChoices } from './util'
import ExportFieldsFieldArray, { transform as transformExportFields } from './export-fields-field-array'

const FormBody = props => {
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const { resource } = props
  const translate = useTranslateResource(resource, 'form')
  const { values: formValues } = useFormState()

  const hasExportFields = Boolean(formValues.export_fields?.length)

  const typeInputHelperText = useMemo(() => {
    return hasExportFields ? translate('general.typeDisabled') : undefined
  }, [hasExportFields, translate])

  return (
    <Box className={formClasses.grid}>
      <Box>
        <TextInput
          {...formInputOptions}
          source='name'
          label={label('name')}
          validate={getValidator()}
          required
        />

        <SelectInput
          {...formInputOptions}
          source='export_type'
          label={label('export_type')}
          choices={exportTypeChoices}
          defaultValue={exportTypeChoices[0]?.id}
          disabled={hasExportFields}
          helperText={typeInputHelperText}
        />

        <BooleanInput
          source='include_csv_headers'
          label={label('include_csv_headers')}
          validate={getValidator(false)}
        />
      </Box>

      <Box>
        <ExportFieldsFieldArray />
      </Box>
    </Box>
  )
}
const ExportsForm = props => {
  const { record } = props

  const transform = useCallback(data => {
    const picked = pick(data, [
      'id',
      'name',
      'export_type',
      'include_csv_headers',
    ])

    return {
      ...picked,
      ...transformExportFields(record, data)
    }
  }, [record])

  return (
    <Form
      {...props}
      transform={transform}
    >
      <FormBody {...props} />
    </Form>
  )
}

export default ExportsForm
