const resources = (previousState = {}, data) => {
  const { type } = data

  if (type === 'RA/CRUD_GET_LIST_SUCCESS') {
    const {
      payload: { collectionData },
      meta: { resource },
      requestPayload: { filter },
    } = data

    const filterKey = key(filter)

    return {
      ...previousState,
      [resource]: {
        ...previousState[resource],
        [filterKey]: collectionData,
      },
    }
  }
  
  return previousState
}

export default resources

export const key = (filter = {}) => {
  return JSON.stringify(filter)
}
