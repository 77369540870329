import { cloneElement } from 'react'
import { useListContext } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  const { spacing, shape } = theme

  const collectionDataBase = {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'inherit',
    borderRadius: shape.borderRadius,
    margin: spacing(1),
  }

  return {
    collectionData: {
      ...collectionDataBase,
      marginBottom: spacing(2),
    },
    collectionDataPrint: {
      ...collectionDataBase,
      marginTop: spacing(4),
    },
  }
})

const withCollectionData = (WrappedComponent, CollectionDataComponent) => (props) => {
  const context = useListContext()
  const { loaded, total, perPage, page } = context
  const { isPrintView, showDetail } = props
  const classes = useStyles()

  const passThroughProps = {
    isPrintView,
    showDetail,
  }

  const numPages = Math.ceil(total / perPage) || 1
  const isLastPage = page === numPages

  const listElement = <WrappedComponent {...passThroughProps} />

  if (!loaded) return listElement

  const collectionDataElement = (
    <CollectionDataComponent
      {...passThroughProps}
      className={classes[isPrintView ? 'collectionDataPrint' : 'collectionData']}
    />
  )

  let children = [
    collectionDataElement,
    listElement,
  ]

  if (isPrintView) {
    if (isLastPage) {
      children = [
        listElement,
        collectionDataElement,
      ]
    } else {
      children = [
        listElement,
      ]
    }
  }

  return children.map((c, i) => cloneElement(c, {key: i}))
}

export default withCollectionData
