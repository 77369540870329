import {
  useTranslate,
  useUpdate,
} from 'react-admin'
import {
  Button,
} from '@material-ui/core'

const defaultButtonProps = {
  size: 'small',
  color: 'secondary',
}

const ToggleActiveButton = ({
  record,
  buttonClassName,
  resource,
  onSuccess,
  onFailure,
}) => {
  const translate = useTranslate()
  const isActive = record.is_active

  const [update, { loading }] = useUpdate(
    resource,
    record.id,
    {is_active: !isActive},
    {},
    {
      onFailure,
      onSuccess,
    }
  )

  return (
    <Button
      {...defaultButtonProps}
      className={buttonClassName}
      onClick={update}
      disabled={loading}
      tabIndex={-1}
      variant={isActive ? 'outlined' : 'contained'}
    >
      {translate(isActive ? 'ra.action.deactivate' : 'ra.action.reactivate')}
    </Button>
  )
}

export default ToggleActiveButton
