import { useMemo } from 'react'
import groupBy from 'lodash/groupBy'

const useTonnageInvoiceTickets = (tickets) => {
  return useMemo(() => {
    const sorted = tickets.sort((a, b) => {
      // date asc
      if (a.worked_at_date > b.worked_at_date) return 1
      if (a.worked_at_date < b.worked_at_date) return -1

      // ticket_number asc
      if (a.ticket_number > b.ticket_number) return 1
      if (a.ticket_number < b.ticket_number) return -1
      return 0
    })

    const grouped = groupBy(sorted, 'worked_at_date')

    return Object.entries(grouped).reduce((accum, [date, tickets]) => {
      const sums = tickets.reduce((_accum, curr) => {
        return {
          total_in_dollars: _accum.total_in_dollars + curr.total_in_dollars,
          net_weight_in_tons: _accum.net_weight_in_tons + curr.net_weight_in_tons,
        }
      }, {
        total_in_dollars: 0,
        net_weight_in_tons: 0,
      })

      return [
        ...accum,
        {
          data: tickets,
          subtotal: {
            ticket_number: tickets.length,
            worked_at_date: date,
            ...sums,
          },
        }
      ]
    }, [])
  }, [tickets])
}

export default useTonnageInvoiceTickets
