import React from 'react'
import {
  TextField,
  TextInput,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import { useLabel } from '../../../hooks'

const TrucksFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('number', 'filter')} source='number_i_cont' />
    </Filter>
  )
}

const TrucksList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<TrucksFilter />}
    >
      <Datagrid>
        <TextField source='number' label={label('number')} />
        <TextField source='default_driver.name' sortBy='default_driver_name' label={label('default_driver')} />
      </Datagrid>
    </List>
  )
}

export default TrucksList
