import {
  useRefresh,
  useUpdate,
} from 'react-admin'
import {
  Button,
} from '@material-ui/core'
import Icon from '@material-ui/icons/CheckCircle'
import { useNotifyResponse } from '../../form/functions'

const defaultButtonProps = {
  size: 'small',
  color: 'primary',
}

const CompleteButton = ({
  record,
  buttonClassName,
  resource,
}) => {
  const refresh = useRefresh()

  const {
    notifyFailure,
    notifySuccess,
  } = useNotifyResponse({ record, resource })

  const [update, { loading }] = useUpdate(
    resource,
    record.id,
    {},
    {},
    {
      onFailure: notifyFailure,
      onSuccess: () => {
        notifySuccess()
        refresh()
      }
    }
  )

  return (
    <Button
      {...defaultButtonProps}
      className={buttonClassName}
      onClick={update}
      disabled={loading}
    >
      <Icon fontSize='small'/>
    </Button>
  )
}

export default CompleteButton
