import React from 'react'
import {
  TextField,
  TextInput,
  DateField,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import { useLabel } from '../../../hooks'
import { dateTimeFieldOptions } from '../../util/component-options'

const ContactsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('phone', 'filter')} source='phone_i_cont' />
      <TextInput label={label('broker_driver', 'filter')} source='broker_driver_name_i_cont' />
      <TextInput label={label('truck', 'filter')} source='truck_number_i_cont' />
    </Filter>
  )
}

const ContactsList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<ContactsFilter />}
    >
      <Datagrid>
        <TextField source='phone' label={label('phone')} />
        <TextField source='broker_driver.name' sortBy='broker_driver_name' label={label('broker_driver')} />
        <TextField source='truck.number' sortBy='truck_number' label={label('truck')} />
        <DateField source='created_at' label={label('created_at')} options={dateTimeFieldOptions}/>
      </Datagrid>
    </List>
  )
}

export default ContactsList
