export const markerTypes = {
  pickup: 'pickup',
  dropoff: 'dropoff',
  pickupAndDropoff: 'pickupAndDropoff',
  position: 'position',
}

export const markerZIndex = {
  pickup: 1,
  dropoff: 1,
  pickupAndDropoff: 1,
  position: 5000,
  cluster: {
    pickup: 1000,
    dropoff: 1000,
    pickupAndDropoff: 1000,
    position: 10000,
  },
}

export const colors = {
  pickup: {
    primary: '#EA4335',
    secondary: '#B31412',
  },
  dropoff: {
    primary: '#0087FF',
    secondary: '#0050BE',
  },
  pickupAndDropoff: {
    primary: '#A357FF',
    secondary: '#631AC1',
  },
  position: {
    primary: '#3FC12A',
    secondary: '#009B00',
  },
}

export const baseLabelStyles = {
  position: 'absolute',
  opacity: 0.9,
  padding: 1.5,
  lineHeight: 1.1,
  fontSize: 10,
  fontWeight: 500,
  borderRadius: 2,
  borderWidth: 1,
  borderStyle: 'solid',
  color: '#FFF',
  textWrap: 'nowrap',
  // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.06)',
}

export const formatTime = date => {
  return new Date(date).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }).replace(' ', '').toLocaleLowerCase()
}

export const detailZoomThreshold = 10

// dev
// export const detailZoomThreshold = 2
