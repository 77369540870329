// https://material-ui.com/customization/color/

import { defaultTheme } from 'react-admin'
import createPalette from '@material-ui/core/styles/createPalette'
import merge from 'lodash/merge'

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    primary: {
      main: '#F9A059',
      light: '#FEE3CA',
      // dark: '#F9A059',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#1F2E35',
      light: '#93979C',
      dark: '#06080A',
      contrastText: '#FFFFFF',
    },
    custom: {
      gray: {
        main: '#F4F4F0',
      },
    },
  })
)

export default palette

// Color palette 1
// #1F2E35
// #93979C
// #BCCOC2
// #F4F4F0
// #F9FBF9
// #FFFFFF
// #F9A059
// #FCCEA8
// #FEE3CA
