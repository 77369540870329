import {
  TextField,
  NumberField,
  BooleanField,
  useListContext,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Datagrid } from '../../custom'
import { useLabel, useAddressFields } from '../../../hooks'
import {
  // currencyFieldOptions,
  booleanFieldOptions,
} from '../../util/component-options'

const useStyles = makeStyles(
  {
    booleanFieldHeader: {
      textAlign: 'center',
    },
  },
)

const BrokerDriversDatagrid = props => {
  const context = useListContext()
  const label = useLabel(context)
  const addressFields = useAddressFields('address', ['city', 'united_state_abbreviation'])
  const classes = useStyles(props)

  return (
    <Datagrid>
      <TextField source='name' label={label('name')} />
      <NumberField source='num_available_trucks' label={label('num_available_trucks')} />
      <NumberField source='num_pending_trucks' label={label('num_pending_trucks')} />
      <NumberField source='num_active_trucks' label={label('num_active_trucks')} />
      <NumberField source='num_unassigned_trucks' label={label('num_unassigned_trucks')} />
      {/* <TextField source='ledger_code' label={label('ledger_code')} /> */}
      <NumberField source='percent_of_base_rate' label={label('percent_of_base_rate')} />
      {/* <NumberField source='hourly_work_deduction_in_dollars' label={label('hourly_work_deduction_in_dollars')} options={currencyFieldOptions} /> */}
      {/* <NumberField source='hourly_regular_rate_in_dollars' label={label('hourly_regular_rate_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='hourly_overtime_rate_in_dollars' label={label('hourly_overtime_rate_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='hourly_doubletime_rate_in_dollars' label={label('hourly_doubletime_rate_in_dollars')} options={currencyFieldOptions} /> */}
      <BooleanField {...booleanFieldOptions} source='is_surcharge_passthrough' label={label('is_surcharge_passthrough')} headerClassName={classes.booleanFieldHeader} />
      {
        addressFields.map(({ source, label: _label }) => {
          return (
            <TextField key={source} source={source} sortBy={source} label={label(_label)} />
          )
        })
      }
    </Datagrid>
  )
}

export default BrokerDriversDatagrid
