import {
  TextField,
  NumberField,
  useListContext,
} from 'react-admin'
import { Datagrid as CustomDatagrid } from '../../custom'
import {
  currencyFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'

const Datagrid = () => {
  const context = useListContext()
  const label = useLabel(context)

  return (
    <CustomDatagrid
      hasEdit={false}
    >
      <TextField source='name' label={label('name')} />
      <NumberField source='sum_regular_total_in_dollars' label={label('sum_regular_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='sum_overtime_total_in_dollars' label={label('sum_overtime_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='sum_doubletime_total_in_dollars' label={label('sum_doubletime_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='sum_total_in_dollars' label={label('sum_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='sum_tonnage_income_total_in_dollars' label={label('sum_tonnage_income_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='sum_hourly_income_total_in_dollars' label={label('sum_hourly_income_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='sum_income_total_in_dollars' label={label('sum_income_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='sum_profit_total_in_dollars' label={label('sum_profit_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='sum_total_line_item_quantity' label={label('sum_total_line_item_quantity')} />
    </CustomDatagrid>
  )
}

export default Datagrid
