import React from 'react'
import { Edit } from 'react-admin'
import Form from './form'
import { editOptions } from '../../util/component-options'

const TonnageOrdersEdit = props => {
  return (
    <Edit {...editOptions} {...props} >
      <Form />
    </Edit>
  )
}

export default TonnageOrdersEdit
