import { useMemo } from 'react'

const useHourlyRateReferenceFields = () => (
  useMemo(() => ([
    {
      source: 'customer',
      label: 'customer',
      required: true,
      optionText: 'name',
    },
  ]),[])
);

export default useHourlyRateReferenceFields
