import {
  useCallback, useMemo,
} from 'react'
import {
  ArrayInput,
  FormDataConsumer,
  ReferenceInput,
  useTranslate,
  TextInput,
  BooleanInput,
} from 'react-admin'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import pick from 'lodash/pick'
import get from 'lodash/get'
import differenceBy from 'lodash/differenceBy'
import { formInputOptions, referenceInputOptions } from '../../util/component-options'
import { useLabel, useTranslateResource } from '../../../hooks'
import { getValidator } from '../../form'
import { Autocomplete, SimpleFormIterator, NumberInput } from '../../custom'
import { relationships } from '../../../data-model'
import { Typography, Box } from '@material-ui/core'
import { haulerAcceptanceStatusTypes } from './util'
import { useFindFromStore } from '../../form/functions'

const useStyles = makeStyles(theme => {
  return {
    meta: {
      marginBottom: theme.spacing(1.2),
      marginTop: theme.spacing(-1.2),
    },
  }
})

const StyledArrayInput = withStyles(theme => {
  return {
    marginNormal: {
      marginTop: theme.spacing(0.5),
    },
  }
})(ArrayInput)

const transform = (record, data) => {
  const { dispatches: oldFields } = record
  const { dispatches: newFields = [] } = data

  const deleted = differenceBy(oldFields, newFields, 'id')

  return {
    dispatches_attributes: [
      ...newFields.map(f => {
        const baseFields = {
          num_trucks: f.num_trucks,
          notes: f.notes,
          broker_driver_id: get(f, 'broker_driver.id'),
        }

        // NOTE: quirk in ArrayInput that populates null values for non-form attributes
        // when deleting a record and adding a new one.
        return Boolean(f.id) ?
          {
            ...baseFields,
            id: f.id,
            is_canceled: f.is_canceled,
          } : baseFields
      }),
      ...deleted.map(f => ({
        ...pick(f, [
          'id',
        ]),
        _destroy: true,
      })),
    ]
  }
}

const Meta = ({ resource, scopedFormData, getBrokerMeta }) => {
  const translate = useTranslate()
  const label = useLabel({ resource })
  const resourceRelationships = relationships[resource]
  const { color, name } = haulerAcceptanceStatusTypes[scopedFormData?.hauler_acceptance_status ?? 'none']
  const classes = useStyles()

  const findBrokerDriverFromStore = useFindFromStore(resourceRelationships.broker_driver)

  return (
    <Box className={classes.meta}>
      {
        getBrokerMeta &&
        <Typography variant='body2'>
          {getBrokerMeta(findBrokerDriverFromStore(scopedFormData?.broker_driver?.id))}
        </Typography>
      }
      {
        scopedFormData?.id &&
        <>
          <Typography variant='body2' component='span'>
            {`${label('hauler_acceptance_status')}: `}
          </Typography>
          <Typography variant='body2' component='span' style={{ color, fontWeight: 'bold' }}>
            {translate(name)}
          </Typography>
        </>
      }
    </Box>
  )
}

const BrokerDriverAutocomplete = ({ formData, choices, ...rest }) => {
  const resourceFormData = formData.dispatches
  const filteredChoices = useMemo(() => {
    const selectedIds = resourceFormData?.map(d => d?.broker_driver?.id).filter(Boolean) ?? []
    return choices.filter(c => c.id === rest.input.value || !selectedIds.includes(c.id))
  }, [resourceFormData, choices, rest.input.value])

  const selected = useMemo(() => {
    return choices.find(c => c.id === rest.input.value)
  }, [choices, rest.input.value])

  return <Autocomplete
    {...rest}
    useServerToFilter
    preserveChoicesOnLoading
    choices={filteredChoices}
    helperText={selected ? rest.getOptionSubtext(selected) : ''}
  />
}

const Inputs = props => {
  const { resource, getBrokerMeta, record } = props
  const label = useLabel({ resource })
  const translateForm = useTranslateResource(resource, 'form')
  const resourceRelationships = relationships[resource]

  const disableRemove = useCallback(record => {
    return !haulerAcceptanceStatusTypes[record.hauler_acceptance_status ?? 'none'].canDelete
  }, [])

  const getDriverOptionSubtext = useCallback(option => {
    return [
      option.num_unassigned_trucks,
      translateForm('broker_driver.num_unassigned_trucks'),
    ].join(' ')
  }, [translateForm])

  const brokerInputOptions = useMemo(() => {
    const opts = referenceInputOptions(resourceRelationships.broker_driver)
    return {
      ...opts,
      filterToQuery: v => ({ name_i_cont: v }),
    }
  }, [resourceRelationships])

  return (
    <SimpleFormIterator
      {...props}
      disableRemove={disableRemove}
    >
      <FormDataConsumer>
        {({ getSource, formData, scopedFormData }) => {
          const canCancel = !haulerAcceptanceStatusTypes[scopedFormData?.hauler_acceptance_status ?? 'none'].canDelete && record?.status !== 'canceled'
          return (
            <>
              <ReferenceInput
                {...formInputOptions}
                {...brokerInputOptions}
                source={getSource('broker_driver.id')}
                reference={resourceRelationships['broker_driver']}
                label={label('broker_driver')}
                required
                inputProps={formInputOptions.inputProps}
              >
                <BrokerDriverAutocomplete
                  formData={formData}
                  validate={getValidator()}
                  optionText='name'
                  getOptionSubtext={getDriverOptionSubtext}
                />
              </ReferenceInput>

              <NumberInput
                {...formInputOptions}
                required
                validate={getValidator()}
                min={1}
                max={99}
                source={getSource('num_trucks')}
                label={label('num_trucks')}
              />

              <TextInput
                {...formInputOptions}
                source={getSource('notes')}
                label={label('notes')}
                validate={getValidator(false)}
                multiline
              />

              <Meta
                resource={resource}
                getBrokerMeta={getBrokerMeta}
                scopedFormData={scopedFormData}
              />

              {
                canCancel &&
                <BooleanInput
                  source={getSource('is_canceled')}
                  label={label('is_canceled')}
                  helperText={false}
                />
              }
            </>
          )
        }}
      </FormDataConsumer>
    </SimpleFormIterator>
  )
}

const DispatchesFieldArray = ({ parentResource, getBrokerMeta, ...rest }) => {
  const resource = 'dispatches'
  const label = useLabel({ resource: parentResource })

  return (
    <StyledArrayInput
      resource={resource}
      source={resource}
      label={label(resource)}
      {...rest}
    >
      <Inputs getBrokerMeta={getBrokerMeta} />
    </StyledArrayInput>
  )
}

export default DispatchesFieldArray

export { transform }
