import {
  TextInput,
  PasswordInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'

const UsersForm = props => {
  const { record } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='email'
            label={label('email')}
            disabled
          />

          {
            !record.has_temp_password &&
            <PasswordInput
              {...formInputOptions}
              source='old_password'
              label={label('old_password')}
              validate={getValidator()}
              required
            />
          }

          <PasswordInput
            {...formInputOptions}
            source='password'
            label={label('new_password')}
            validate={getValidator()}
            required
          />
        </Box>
        <Box />
      </Box>
    </Form>
  )
}

export default UsersForm
