import { useCallback, useMemo } from 'react'
import { useListContext } from 'react-admin'
import { Grid, Typography, IconButton } from '@material-ui/core'
import NextIcon from '@material-ui/icons/ChevronRight'
import PrevIcon from '@material-ui/icons/ChevronLeft'
import { dateFieldOptions } from '../util/component-options'
import { useLabel } from '../../hooks'
import { dateOnlyStringToISODate, iSODateToDateOnlyString } from '../../util/date'

const PeriodQuickFilter = ({ fieldName }) => {
  const { filterValues, setFilters, resource, loading } = useListContext()
  const translate = useLabel({ resource })
  const filterKey = `${fieldName}_eq`
  const currentDate = filterValues[filterKey]

  const isDisabled = useMemo(() => {
    return loading
  }, [loading])

  const onNextClick = useCallback(() => {
    const nextFilter = iSODateToDateOnlyString(dateOnlyStringToISODate(currentDate, 7))
    setFilters({
      ...filterValues,
      [filterKey]: nextFilter,
    }, null, false)
  }, [setFilters, filterKey, filterValues, currentDate])

  const onPrevClick = useCallback(() => {
    const nextFilter = iSODateToDateOnlyString(dateOnlyStringToISODate(currentDate, -7))
    setFilters({
      ...filterValues,
      [filterKey]: nextFilter,
    }, null, false)
  }, [setFilters, filterKey, filterValues, currentDate])

  if (!currentDate) return null

  return (
    <Grid item>
      <Grid container alignItems='center'>
        <Grid item>
          <IconButton
            onClick={onPrevClick}
            size='small'
            disabled={isDisabled}
          >
            <PrevIcon />
          </IconButton>
        </Grid>

        <Grid item>
          <Typography variant='subtitle2'>
            {`${translate(fieldName)}: ${new Date(currentDate).toLocaleString([], dateFieldOptions)}`}
          </Typography>
        </Grid>

        <Grid item>
          <IconButton
            onClick={onNextClick}
            size='small'
            disabled={isDisabled}
          >
            <NextIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PeriodQuickFilter
