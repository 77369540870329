import {
  TextField,
  TextInput,
  DateField
} from 'react-admin'
import { dateFieldOptions } from '../../util/component-options'
import { Datagrid, List, Filter } from '../../custom'
import { useLabel } from '../../../hooks'

const BrokerDriversUsersFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('user.email', 'filter')} source='user_email_i_cont' />
      <TextInput label={label('broker_driver.name', 'filter')} source='broker_driver_name_i_cont' />
    </Filter>
  )
}

const BrokerDriversUsersList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<BrokerDriversUsersFilter />}
    >
      <Datagrid>
        <TextField source='user.email' label={label('user.email')} sortBy='user_email' />
        <TextField source='broker_driver.name' label={label('broker_driver.name')} sortBy='broker_driver_name' />
        <TextField source='user.temp_password' label={label('user.temp_password')} sortBy='user_temp_password' />
        <DateField source='created_at' label={label('created_at')} options={dateFieldOptions} />
      </Datagrid>
    </List>
  )
}

export default BrokerDriversUsersList
