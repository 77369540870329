import { UserMenu as RaUserMenu, MenuItemLink } from 'react-admin'
import IconBusinessSettings from '@material-ui/icons/Settings'
import IconAccountSettings from '@material-ui/icons/AccountCircle'
import { useTranslateResource } from '../../hooks'
import { capitalize } from '../../util'

const tooltipProps = {
  disableHoverListener: true,
}

const UserMenu = props => {
  const translateTenants = useTranslateResource('tenants')
  const translateUsers = useTranslateResource('users')

  return (
    <RaUserMenu
      {...props}
    >
      <MenuItemLink
        to='/business_settings'
        primaryText={capitalize(translateTenants('name', { smart_count: 1 }))}
        leftIcon={<IconBusinessSettings />}
        tooltipProps={tooltipProps}
      />

      <MenuItemLink
        to='/account_settings'
        primaryText={capitalize(translateUsers('name', { smart_count: 1 }))}
        leftIcon={<IconAccountSettings />}
        tooltipProps={tooltipProps}
      />
    </RaUserMenu>
  )
}

export default UserMenu
