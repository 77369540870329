import {
  useState,
  useCallback,
} from 'react'
import {
  TextField,
  FunctionField,
  useCreateController,
  CreateContextProvider,
  useTranslate,
  ReferenceInput,
  useListContext,
} from 'react-admin'
import {
  Button,
  makeStyles,
  Switch,
  FormControlLabel,
  FormControl,
  FormGroup,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import ShowIcon from '@material-ui/icons/RemoveRedEye';
import { Datagrid, Autocomplete } from '../custom'
import { useLabel, useTranslateResource } from '../../hooks'
import Form from '../form/form'
import { capitalize } from '../../util'
import { formInputOptions, referenceInputOptions } from '../util/component-options'
import { optionTexts, humanReadableIdentifiers } from '../../data-model'
import { getValidator } from '../form/functions'
import CompleteButton from './complete-button'

const useStyles = makeStyles(({ spacing }) => ({
  cell: {
    width: 1,
    whiteSpace: 'nowrap',
    paddingLeft: spacing(0.2),
    paddingRight: spacing(0.2),
  },
  button: {
    minWidth: 0,
  },
}))

const CompleteResource = (props) => {
  const { record, onSuccess, resource, reference, ResourceFormComponent, ...rest } = props
  const translate = useTranslate()
  const [createNewIsChecked, setCreateNewIsChecked] = useState(false)
  const onToggleChange = useCallback((_, val) => setCreateNewIsChecked(val), [])
  const label = useLabel({ resource })

  const baseControllerProps = {
    basePath: `/${resource}`,
    resource,
    record: {
      id: record.id,
      ticket_vendor_id: record.ticket_vendor_id,
    },
  }

  const createReferenceFormContext = useCreateController({
    ...baseControllerProps,
    record: {
      ...baseControllerProps.record,
      [humanReadableIdentifiers[reference]]: record.text,
    },
  })

  const mapExistingReferenceContext = useCreateController(baseControllerProps)

  const optionText = optionTexts[reference]

  const filterToQuery = useCallback(v => {
    return { [`${optionText}_i_cont`]: v }
  }, [optionText])

  return (
    <>
      <FormControl component='fieldset'>
        <FormGroup row>
          <FormControlLabel
            labelPlacement='end'
            label={capitalize(translate('resources.pending_resources.form.toggle'))}
            control={
              <Switch
                tabIndex='-1'
                color='primary'
                checked={createNewIsChecked}
                onChange={onToggleChange}
              />
            }
          />
        </FormGroup>
      </FormControl>
      {
        createNewIsChecked ?
        <CreateContextProvider value={createReferenceFormContext}>
          <ResourceFormComponent
            {...rest}
            {...createReferenceFormContext}
            saveButtonLabel={translate('resources.pending_resources.form.actions.createNew')}
            onUpdateSuccess={onSuccess}
            isDeleteDisabled
          />
        </CreateContextProvider> :

        <CreateContextProvider value={mapExistingReferenceContext}>
          <Form
            {...mapExistingReferenceContext}
            saveButtonLabel={translate('resources.pending_resources.form.actions.mapExisting')}
            onUpdateSuccess={onSuccess}
            isDeleteDisabled
          >
            <ReferenceInput
              {...formInputOptions}
              {...referenceInputOptions(reference)}
              source='resource_id'
              reference={reference}
              label={label('resource_id')}
              filterToQuery={filterToQuery}
              required
            >
              <Autocomplete
                optionText={optionText}
                validate={getValidator()}
                useServerToFilter
                preserveChoicesOnLoading
              />
            </ReferenceInput>
          </Form>
        </CreateContextProvider>
      }
    </>
  )
}

const ExtractionsList = (props) => {
  const { reference, extractionAttribute, ResourceFormComponent } = props
  const context = useListContext()
  const label = useLabel(context)
  const classes = useStyles()
  const translate = useTranslateResource(reference)
  const { resource } = context

  return (
    <Datagrid hasEdit={false}>
      <FunctionField cellClassName={classes.cell} render={record => {
        return <CompleteButton
          buttonClassName={classes.button}
          record={record}
          resource={resource}
          reference={reference}
          dialogTitle={`${translate('name', { smart_count: 1 })}: ${record.text}`}
          DialogContentComponent={CompleteResource}
          ResourceFormComponent={ResourceFormComponent}
        />
      }} />

      <FunctionField cellClassName={classes.cell} render={record => {
        return (
          <Button
            size='small'
            color='primary'
            target='_blank'
            component={Link}
            className={classes.button}
            to={{
              pathname: '/tonnage_ticket_extractions',
              search: new URLSearchParams({
                filter: JSON.stringify({
                  [`${extractionAttribute}_eq`]: record.text
                })
              }).toString()
            }}
          >
            <ShowIcon fontSize='small' />
          </Button>
        )
      }} />

      <TextField source='text' label={label('text')} />
      <TextField source='ticket_vendor.name' label={label('ticket_vendor')} sortBy='ticket_vendor_name' />
    </Datagrid>
  )
}

export default ExtractionsList
