import DataTable from './data-table'
import { useListCollectionDataSelector } from '../../hooks'

const CollectionDataTable = ({ children, ...rest }) => {
  const data = useListCollectionDataSelector()

  return (
    <DataTable data={data} {...rest}>
      {children}
    </DataTable>
  )
}

export default CollectionDataTable
