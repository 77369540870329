import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import {
  useMap,
} from '@vis.gl/react-google-maps'
import { makeStyles } from '@material-ui/core'
import { markerTypes, colors, detailZoomThreshold, baseLabelStyles, formatTime } from './props'
import useClustering from './use-clustering'
import { size as endpointMarkerSize } from './endpoint-marker'
import { size as positionMarkerSize } from './position-marker'

const useStyles = makeStyles(() => {
  return {
    intersectionPolylineLabel: {
      ...baseLabelStyles,
      transform: 'translate(-50%, -50%)',
      opacity: 0.8,
      fontWeight: 500,
    },
  }
})

const endointClusterRadius = endpointMarkerSize
const positionClusterRadius = positionMarkerSize / 2

const endpointPolylineOptions = {
  strokeColor: colors.pickupAndDropoff.primary,
  strokeOpacity: 0.6,
  strokeWeight: 2,
  geodesic: true,
}

const intersectionPolylineOptions = {
  strokeColor: colors.position.primary,
  strokeOpacity: 0.6,
  strokeWeight: 8,
  geodesic: true,
}

const routePolylineOptions = {
  strokeColor: colors.position.primary,
  strokeOpacity: 0.7,
  strokeWeight: 4,
  geodesic: true,
}

const circleOptions = {
  strokeOpacity: 0.6,
  strokeWeight: 1,
  zIndex: 1,
  fillOpacity: 0.15,
}

const useMarkers = ({ routes, positions, selectedEndpointMarker, selectedOrder }) => {
  const google = window.google
  const classes = useStyles()
  const map = useMap()
  const endpointPolylines = useRef([])
  const intersectionPolylines = useRef([])
  const routePolylines = useRef([])
  const endpointCircles = useRef([])

  const endpoints = useMemo(() => {
    const routeIds = selectedEndpointMarker?.points.map(p => p.routeId)
    return routes.reduce((acc, route) => {
      if (routeIds?.length && !routeIds.includes(route.id)) return acc
      if (selectedOrder && selectedOrder.id !== route.id) return acc
      return [
        ...acc,
        {
          position: route.pickup.position,
          type: markerTypes.pickup,
          title: route.pickup.title,
          key: route.pickup.id,
          routeId: route.id,
          radius: route.pickup.radius,
        },
        {
          position: route.dropoff.position,
          type: markerTypes.dropoff,
          title: route.dropoff.title,
          key: route.dropoff.id,
          routeId: route.id,
          radius: route.dropoff.radius,
        },
      ]
    }, [])
  }, [routes, selectedEndpointMarker, selectedOrder])

  const endpointMarkers = useMemo(() => {
    const pointsByKey = {}
    for (const _point of endpoints) {
      const { key, position, title, radius, ...point } = _point
      const { type } = point
      const marker = {
        position,
        title,
        type,
        key,
        radius,
      }
      const existingLocation = pointsByKey[key]
      if (!existingLocation) {
        pointsByKey[key] = {
          ...marker,
          points: [point],
        }
      } else {
        const nextType = existingLocation.type === type ? type : markerTypes.pickupAndDropoff
        pointsByKey[key] = {
          ...marker,
          type: nextType,
          points: [...existingLocation.points, point],
        }
      }
    }
    return Object.values(pointsByKey)
  }, [endpoints])

  const positionMarkers = useMemo(() => {
    const getTitle = ({ contact: c }) => {
      if (c.truck) return c.truck.number
      return c.phone
    }
    return positions.map(p => {
      const current = p.latest_location_captures[0]
      return {
        ...p,
        ...current,
        title: getTitle(p),
        subTitle: formatTime(current.captured_at),
        key: p.id,
        type: markerTypes.position,
      }
    })
  }, [positions])

  const getEndpointMarkerClusterType = useCallback((types) => {
    return types.length === 1 ? types[0] : markerTypes.pickupAndDropoff
  }, [])

  const getPositionMarkerClusterType = useCallback((types) => {
    return types[0]
  }, [])

  const {
    setMarkerRef: setEndpointMarkerRef,
    clusters: endpointClusters,
    markerDataByRef: endpointMarkerDataByRef,
  } = useClustering({ markers: endpointMarkers, getClusterType: getEndpointMarkerClusterType, clusterRadius: endointClusterRadius })

  const {
    setMarkerRef: setPositionMarkerRef,
    clusters: positionClusters,
    markerDataByRef: positionMarkerDataByRef,
  } = useClustering({ markers: positionMarkers, getClusterType: getPositionMarkerClusterType, clusterRadius: positionClusterRadius })

  const makeClusteredMarkersByKey = useCallback((clusteredMarkers, markerDataByRef) => {
    const result = {}
    for (const cluster of clusteredMarkers) {
      for (const markerRef of cluster.markers) {
        const markerData = markerDataByRef.current.get(markerRef)
        if (!markerData) continue
        result[markerData.key] = {
          markerData,
          cluster,
          clusterSize: cluster.markers.length,
        }
      }
    }
    return result
  }, [])

  const clusteredEndpointMarkersByKey = useMemo(() => {
    return makeClusteredMarkersByKey(endpointClusters, endpointMarkerDataByRef)
  }, [endpointClusters, endpointMarkerDataByRef, makeClusteredMarkersByKey])

  const clusteredPositionMarkersByKey = useMemo(() => {
    return makeClusteredMarkersByKey(positionClusters, positionMarkerDataByRef)
  }, [positionClusters, positionMarkerDataByRef, makeClusteredMarkersByKey])

  useEffect(() => {
    if (!map) return
    const zoom = map.getZoom()
    const newEndpointPolylines = new Map()
    const newCircles = []
    while (endpointPolylines.current.length) endpointPolylines.current.pop().setMap(null)
    while (endpointCircles.current.length) endpointCircles.current.pop().setMap(null)

    if (zoom > detailZoomThreshold) {
      for (const { markerData, clusterSize } of Object.values(clusteredEndpointMarkersByKey)) {
        if (clusterSize === 1 && zoom > detailZoomThreshold) {
          const color = colors[markerData.type].primary
          const circle = new google.maps.Circle({
            ...circleOptions,
            strokeColor: color,
            fillColor: color,
            center: markerData.position,
            radius: markerData.radius,
            map,
          });
          newCircles.push(circle)
        }
      }
    }

    for (const route of routes) {
      const pickup = clusteredEndpointMarkersByKey[route.pickup.id]
      const dropoff = clusteredEndpointMarkersByKey[route.dropoff.id]
      if (!pickup || !dropoff) continue
      const pickupCluster = pickup.cluster
      const dropoffCluster = dropoff.cluster
      if (pickupCluster === dropoffCluster) continue
      const pickupClusterPosition = { lat: pickupCluster.position.lat(), lng: pickupCluster.position.lng() }
      const dropoffClusterPosition = { lat: dropoffCluster.position.lat(), lng: dropoffCluster.position.lng() }
      const path = [pickupClusterPosition, dropoffClusterPosition]
      const pathKey = JSON.stringify(path)
      newEndpointPolylines.set(pathKey, { path })
    }

    endpointCircles.current = newCircles

    for (const { path } of newEndpointPolylines.values()) {
      const polyline = new google.maps.Polyline({
        ...endpointPolylineOptions,
        path,
        map,
      })
      endpointPolylines.current.push(polyline)
    }
  }, [google, routes, map, clusteredEndpointMarkersByKey])

  useEffect(() => {
    if (!map) return

    class CustomOverlay extends google.maps.OverlayView {
      constructor({ content, position, backgroundColor, borderColor }) {
        super()
        this.content = content
        this.position = position
        this.backgroundColor = backgroundColor
        this.borderColor = borderColor
      }

      onAdd() {
        this.div = document.createElement('div')
        this.div.className = classes.intersectionPolylineLabel
        this.div.style.background = this.backgroundColor
        this.div.style.borderColor = this.borderColor
        this.div.innerHTML = this.content
        this.getPanes().markerLayer.appendChild(this.div)
      }

      draw() {
        const overlayProjection = this.getProjection()
        const position = overlayProjection.fromLatLngToDivPixel(this.position)
        this.div.style.left = `${position.x}px`
        this.div.style.top = `${position.y}px`
      }

      onRemove() {
        this.div.parentNode.removeChild(this.div)
        delete this.div
      }
    }

    const newIntersectionPolylines = new Map()

    // Cleanup
    while (intersectionPolylines.current.length) {
      const item = intersectionPolylines.current.pop()
      item.polyline.setMap(null)
      item.infoWindow?.setMap(null)
    }

    for (const { markerData, clusterSize} of Object.values(clusteredPositionMarkersByKey)) {
      if (clusterSize > 1) continue
      const currentLocation = markerData.latest_location_captures[0]
      for (const point of markerData.site_intersections) {
        const markerPoint = clusteredEndpointMarkersByKey[point.site.id]
        if (!markerPoint) continue
        const markerCluster = markerPoint.cluster
        const currentLocationPosition = currentLocation.position
        const clusterPosition = { lat: markerCluster.position.lat(), lng: markerCluster.position.lng() }
        const path = [currentLocationPosition, clusterPosition]
        const pathKey = JSON.stringify(path)
        const existingPoyline = newIntersectionPolylines.get(pathKey)
        const isMixedType = existingPoyline && existingPoyline.type !== point.type
        const type = isMixedType ? markerTypes.pickupAndDropoff : point.type
        const capturedAt = isMixedType ? existingPoyline.capturedAt : point.location_capture.captured_at
        newIntersectionPolylines.set(pathKey, { type, path, capturedAt })
      }
    }

    const compute = google.maps.geometry.spherical
    const zoom = map.getZoom()

    for (const { type, path, capturedAt } of newIntersectionPolylines.values()) {
      let infoWindow
      if (zoom > detailZoomThreshold) {
        const distanceBetween = compute.computeDistanceBetween(path[0], path[1])
        const offsetDistance = distanceBetween * 0.5
        const heading = compute.computeHeading(path[0], path[1])
        const offsetLocation = compute.computeOffset(path[0], offsetDistance, heading)

        infoWindow = new CustomOverlay({
          content: formatTime(capturedAt),
          position: offsetLocation,
          backgroundColor: colors[type].primary,
          borderColor: colors[type].secondary,
        })
        infoWindow.setMap(map)
      }

      const polyline = new google.maps.Polyline({
        ...intersectionPolylineOptions,
        strokeColor: colors[type].primary,
        path,
        map,
      })
      intersectionPolylines.current.push({ polyline, infoWindow })
    }
  }, [google, map, clusteredEndpointMarkersByKey, clusteredPositionMarkersByKey, classes])

  useEffect(() => {
    if (!map) return
    while (routePolylines.current.length) routePolylines.current.pop().polyline.setMap(null)
    const zoom = map.getZoom()

    if (zoom > detailZoomThreshold) {
      for (const { markerData, clusterSize } of Object.values(clusteredPositionMarkersByKey)) {
        if (clusterSize > 1) continue
        if (markerData.latest_location_captures.length < 2) continue
        const polyline = new google.maps.Polyline({
          ...routePolylineOptions,
          path: markerData.latest_location_captures.map(c => c.position),
          map,
        })
        routePolylines.current.push({ polyline, key: markerData.key })
      }
    }
  }, [google, map, clusteredPositionMarkersByKey])

  return {
    endpointMarkers,
    positionMarkers,
    setEndpointMarkerRef,
    setPositionMarkerRef,
  }
}

export default useMarkers
