import {
  TextInput,
  NullableBooleanInput,
} from 'react-admin'
import { statusTypeChoices } from '../../shared/orders/util'
import { ActivateAll, DeactivateAll, ArchiveAll, CancelAll } from '../../shared/orders/batch-status-button'
import { Grid } from '@material-ui/core'
import { List, Filter, SelectArrayInput } from '../../custom'
import { useLabel } from '../../../hooks'
import { withCollectionData } from '../../hoc'
import Datagrid from './datagrid'
import CollectionData from '../../shared/orders/collection-data'

const DatagridWithTotals = withCollectionData(Datagrid, CollectionData)

const TonnageOrdersFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <SelectArrayInput
        label={label('status', 'filter')}
        source='status_in'
        choices={statusTypeChoices}
      />
      <NullableBooleanInput label={label('is_created_by_customer')} source='is_created_by_customer_true' />
      <TextInput label={label('order_number', 'filter')} source='order_number_i_cont' />
      <TextInput label={label('tonnage_rate', 'filter')} source='tonnage_rate_code_i_cont' />
      <TextInput label={label('customer', 'filter')} source='tonnage_rate_customer_name_i_cont' />
      <TextInput label={label('recipient', 'filter')} source='tonnage_rate_recipient_name_i_cont' />
      <TextInput label={label('material', 'filter')} source='tonnage_rate_material_name_i_cont' />
      <TextInput label={label('pickup_site', 'filter')} source='tonnage_rate_pickup_site_name_i_cont' />
      <TextInput label={label('dropoff_site', 'filter')} source='tonnage_rate_dropoff_site_name_i_cont' />
    </Filter>
  )
}

const Actions = () => (
  <>
    <Grid item>
      <ActivateAll />
    </Grid>
    <Grid item>
      <DeactivateAll />
    </Grid>
    <Grid item>
      <CancelAll />
    </Grid>
    <Grid item>
      <ArchiveAll />
    </Grid>
  </>
)

const TonnageOrdersList = props => {
  return (
    <List
      {...props}
      filterDefaultValues={{
        status_in: ['active', 'pending', 'canceled'],
      }}
      filter={{ include_collection_data: true }}
      actions={<Actions />}
      filters={<TonnageOrdersFilter />}
    >
      <DatagridWithTotals />
    </List>
  )
}

export default TonnageOrdersList
