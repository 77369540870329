import { jsonHttpClient as httpClient, getApiUrl } from '../http-client'

const authKey = 'haulzUser'
const resource = 'sessions'

const setAuth = (data = {}) => localStorage.setItem(authKey, JSON.stringify(data))
const removeAuth = () => localStorage.removeItem(authKey)
const isAuthenticated = () => Boolean(localStorage.getItem(authKey))
const getUser = () => {
  try {
    return JSON.parse(localStorage.getItem(authKey))
  } catch {
    return null
  }
}

const authProvider = () => {
  let url = getApiUrl(resource)

  return {
    login: async ({ username, password }) => {
      const postData = {
        email: username,
        password,
      }
      const json = await httpClient.post(url, postData)
      const { data } = json
      setAuth(data)
    },

    checkError: async error => {
      const { status } = error
      if (status === 401) {
        removeAuth()
        return Promise.reject()
      }
    },

    checkAuth: async params => {
      if (!isAuthenticated()) {
        return Promise.reject()
      }
    },

    logout: async () => {
      await httpClient.delete(url)
      removeAuth()
    },

    getIdentity: async () => {
      const user = getUser()
      if (!user) {
        return {}
      }
      return {
        fullName: user.email
      }
    },

    getPermissions: async () => {
      return {}
    },
  }
}

export default authProvider
