const OFFSET_MULTIPLIER = 60 * 1000

const getOffsetMS = date => {
  return date.getTimezoneOffset() * OFFSET_MULTIPLIER
}

const iSODateToDateOnlyString = iSODate => {
  const date = new Date(iSODate)
  return new Date(date.getTime() - getOffsetMS(date)).toISOString().split('T')[0]
}

const dateOnlyStringToJSDate = dateOnlyStr => {
  const date = new Date(dateOnlyStr)
  return new Date(date.getTime() + getOffsetMS(date))
}

const dateOnlyStringToISODate = (dateOnlyStr, daysAdjustment = 0) => {
  const date = dateOnlyStringToJSDate(dateOnlyStr)
  return new Date(addDays(date, daysAdjustment)).toISOString()
}

const addDays = (date, days = 0) => {
  const d = new Date(date)
  d.setDate(d.getDate() + days)
  return d
}

const startOfDay = date => {
  const d = new Date(date)
  d.setHours(0, 0, 0, 0)
  return d
}

export {
  iSODateToDateOnlyString,
  dateOnlyStringToISODate,
  dateOnlyStringToJSDate,
  startOfDay,
  addDays,
}
