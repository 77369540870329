import { useState, useCallback } from 'react'
import { Dialog } from '@material-ui/core'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  image: {
    cursor: 'pointer',
  },
  modalImage: {
    width: '100%',
  },
}))

const ImageWithModal = ({ src, className }) => {
  const classes = useStyles()
  const [showModal, setShowModal] = useState(false)

  const onClick = useCallback(() => {
    setShowModal(true)
  }, [])

  const onModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <>
      <img
        className={classnames(className, classes.image)}
        src={src}
        onClick={onClick}
        alt=''
      />
      <Dialog
        maxWidth='xl'
        fullWidth
        open={showModal}
        onClose={onModalClose}
      >
        <img
          className={classes.modalImage}
          src={src}
          alt=''
        />
      </Dialog>
    </>
  )
}

export default ImageWithModal
