import {
  memo
} from 'react'
import {
  AdvancedMarker,
  Pin,
} from '@vis.gl/react-google-maps'
import { colors, markerZIndex } from './props'

// Observed Pin size
const size = 26

const Marker = memo(({ marker, position, title, label, type, setMarkerRef, onClick }) => {
  const { primary: background, secondary: borderColor } = colors[type]

  return (
    <AdvancedMarker
      position={position}
      title={title}
      onClick={onClick?.bind(null, marker)}
      ref={ref => setMarkerRef(ref, marker)}
      zIndex={markerZIndex[type]}
    >
      <Pin
        background={background}
        borderColor={borderColor}
      >
        <div
          style={{
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          {label}
        </div>
      </Pin>
    </AdvancedMarker>
  )
})

export default Marker
export { size }
