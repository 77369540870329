import {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react'
import {
  ReferenceInput,
  TextInput,
  Link,
  useNotify,
  SelectInput,
  DataProviderContext,
} from 'react-admin'
import { Grid, Box, Typography, IconButton, CircularProgress, Link as ExternalLink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useForm } from 'react-final-form'
import { statusTypeChoices, materialGradeChoices, getMaterialAndGrade } from '../../shared/orders/util'
import { Autocomplete, DateTimeInput, NumberInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
  currencyFieldOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import {
  useLabel,
  usePrevious,
  useTranslateResource,
} from '../../../hooks'
import { relationships } from '../../../data-model'
import { Form, getValidator } from '../../form'
import {
  useReferenceLookup,
} from '../../form/functions'
import DispatchesFieldArray, { transform as transformDispatches } from '../../shared/orders/dispatches-field-array'

const useStyles = makeStyles(theme => {
  return {
    container: {
      padding: theme.spacing(4),
    },
    form: {
      padding: 0,
    },
    rateTitle: {
      marginBottom: theme.spacing(1),
    },
    editLink: {
      fontSize: '0.9em',
      display: 'flex',
    },
    rateReloader: {
      display: 'flex',
      alignItems: 'center',
    },
    rateRelationshipLabel: {
      ...theme.typography.body2,
      color: theme.palette.grey['600'],
      paddingBottom: theme.spacing(0.25),
      boxSizing: 'content-box',
    },
    missingLink: {
      color: theme.palette.error.main,
    },
    routeText: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    routeIcon: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    iframeWrap: {
      padding: theme.spacing(2)
    },
    iframe: {
      border: 0,
    },
  }
})

const FormBody = props => {
  const { onRateIdChange, onMaterialGradeChange: propsOnMaterialGradeChange, ...restProps } = props
  const { resource, record } = restProps
  const isNewRecord = !record?.id
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(restProps)
  const { change: changeFormField, resetFieldState } = useForm()
  const label = useLabel(restProps)
  const translate = useTranslateResource(resource, 'form.general')
  const { selectedRecord: rate, onSelectedIdChange: onSelectedRateIdChange } = useReferenceLookup(resourceRelationships['tonnage_rate'], record.tonnage_rate)
  const rateId = rate?.id
  const prevRateId = usePrevious(rate?.id)

  const selectedRateText = useMemo(() => {
    if (rate && !rate.code) {
      return translate('selectedRateWithoutCode')
    }
  }, [rate, translate])

  const rateFieldOpts = useMemo(() => {
    const opts = referenceInputOptions(resourceRelationships['tonnage_rate'])
    return {
      ...opts,
      filter: {
        ...opts.filter,
        code_present: true,
      },
      matchFrom: 'start',
      filterToQuery: v => ({ code_start: v }),
    }
  }, [resourceRelationships])

  const initialFocusedDate = useMemo(() => {
    const rolloverHour = 7
    const date = new Date()
    const currentHours = date.getHours()
    if (currentHours >= rolloverHour) {
      date.setDate(date.getDate() + 1)
    }
    date.setMinutes(0)
    date.setHours(rolloverHour)
    date.setSeconds(0)
    return date
  }, [])

  const getRateOptionSubtext = useCallback(option => {
    return [
      option.pickup_site.name,
      option.material.name,
    ].join(' / ')
  }, [])

  const onMaterialGradeChange = useCallback(e => {
    propsOnMaterialGradeChange(e.target.value)
  }, [propsOnMaterialGradeChange])

  useEffect(() => {
    onRateIdChange(rate?.id)
  }, [rate?.id, onRateIdChange])

  useEffect(() => {
    // Reset material grade when rate changes
    if (rateId !== prevRateId) {
      changeFormField('material_grade', null)
      resetFieldState('material_grade')
      propsOnMaterialGradeChange(null)
    }
  }, [rateId, prevRateId, changeFormField, resetFieldState, propsOnMaterialGradeChange])

  return (
    <Box className={formClasses.grid}>
      <Box>
        <ReferenceInput
          {...formInputOptions}
          {...rateFieldOpts}
          source='tonnage_rate.id'
          reference={resourceRelationships['tonnage_rate']}
          label={translate('lookupRate')}
          onChange={onSelectedRateIdChange}
          required
          autoFocus
          inputProps={{
            ...formInputOptions.inputProps,
          }}
        >
          <Autocomplete
            validate={getValidator()}
            optionText='code'
            getOptionSubtext={getRateOptionSubtext}
            helperText={selectedRateText}
            useServerToFilter
            preserveChoicesOnLoading
          />
        </ReferenceInput>

        <DateTimeInput
          {...formInputOptions}
          validate={getValidator()}
          source='load_at'
          label={label('load_at')}
          initialFocusedDate={initialFocusedDate}
          required
        />

        <SelectInput
          {...formInputOptions}
          source='material_grade'
          label={label('material_grade')}
          choices={materialGradeChoices}
          validate={getValidator()}
          onChange={onMaterialGradeChange}
          required
        />

        <TextInput
          {...formInputOptions}
          source='order_number'
          label={label('order_number')}
          validate={getValidator(false)}
        />

        <NumberInput
          {...formInputOptions}
          source='num_trucks'
          min={0}
          label={label('num_trucks')}
          validate={getValidator(false)}
        />

        {
          !isNewRecord &&
          <SelectInput
            {...formInputOptions}
            source='status'
            label={label('status')}
            choices={statusTypeChoices}
          />
        }

        <TextInput
          {...formInputOptions}
          source='notes'
          label={label('notes')}
          multiline
          validate={getValidator(false)}
        />
      </Box>
      <Box>
        <DispatchesFieldArray parentResource={resource} record={record}/>
      </Box>
    </Box>
  )
}

const formatAddress = (record) => {
  const { address_street_1, address_street_2, address_united_state_abbreviation, address_city, address_zip } = record
  return [
    address_street_1 ? `${address_street_1},` : null,
    address_street_2 ? `${address_street_2},` : null,
    address_city ? `${address_city},` : null,
    address_united_state_abbreviation,
    address_zip,
  ].filter(Boolean).join(' ')
}

const PinLink = ({ pinLink, resource }) => {
  const translateSite = useTranslateResource('sites', 'fieldNames')
  const translateForm = useTranslateResource(resource, 'form.general')
  const classes = useStyles()

  return pinLink ?
    <ExternalLink target='_blank' tabIndex='-1' href={pinLink}>
      {translateSite('google_maps_pin_link')}
    </ExternalLink> :
    <Typography className={classes.missingLink} variant={'body2'}>{translateForm('missingPinLink')}</Typography>
}

const RouteLink = ({ routeLink, resource }) => {
  const translateSite = useTranslateResource('sites', 'fieldNames')
  const translateForm = useTranslateResource(resource, 'form.general')
  const classes = useStyles()

  return routeLink ?
    <ExternalLink target='_blank' tabIndex='-1' href={routeLink}>
      {translateSite('route_link')}
    </ExternalLink> :
    <Typography className={classes.missingLink} variant={'body2'}>{translateForm('missingRouteLink')}</Typography>
}

const SelectedRate = ({ isLoading, rate, onRefreshRateClick, resource, materialGrade }) => {
  const labelResource = useLabel({ resource })
  const labelRate = useLabel({ resource: 'tonnage_rates' })
  const resourceRelationships = relationships['tonnage_rates']
  const translateForm = useTranslateResource(resource, 'form.general')
  const classes = useStyles()

  const items = useMemo(() => {
    if (!rate) return []
    return [
      {
        label: labelRate('code'),
        value:
          <>
            <Typography variant={'body1'}>{rate.code}</Typography>
          </>
      },
      {
        label: labelRate('base_rate_in_dollars'),
        value:
          <>
            <Typography variant={'body1'}>{Intl.NumberFormat('en-US', currencyFieldOptions).format(rate.base_rate_in_dollars)}</Typography>
          </>
      },
      {
        label: labelRate('fuel_surcharge_in_dollars'),
        value:
          <>
            <Typography variant={'body1'}>{Intl.NumberFormat('en-US', currencyFieldOptions).format(rate.fuel_surcharge_in_dollars)}</Typography>
          </>
      },
      {
        label: labelRate('material_surcharge_in_dollars'),
        value:
          <>
            <Typography variant={'body1'}>{Intl.NumberFormat('en-US', currencyFieldOptions).format(rate.material_surcharge_in_dollars)}</Typography>
          </>
      },
      {
        label: labelResource('customer'),
        link: `/${resourceRelationships.customer}/${rate.customer.id}`,
        value:
          <>
            <Typography variant={'body1'}>{rate.customer.name}</Typography>
          </>
      },
      {
        label: labelResource('recipient'),
        link: `/${resourceRelationships.recipient}/${rate.recipient.id}`,
        value:
          <>
            <Typography variant={'body1'}>{rate.recipient.name}</Typography>
          </>
      },
      {
        label: labelResource('material'),
        link: `/${resourceRelationships.material}/${rate.material.id}`,
        value:
          <>
            <Typography variant={'body1'}>
              {getMaterialAndGrade(rate.material.name, materialGrade)}
            </Typography>
          </>
      },
      {
        label: labelResource('dropoff_site'),
        link: `/${resourceRelationships.dropoff_site}/${rate.dropoff_site.id}`,
        value:
          <>
            <Typography variant={'body1'}>{rate.dropoff_site.name}</Typography>
            <Typography variant={'body2'}>{formatAddress(rate.dropoff_site)}</Typography>
            <PinLink pinLink={rate.dropoff_site.google_maps_pin_link} resource={resource} />
          </>
      },
      {
        label: labelResource('pickup_site'),
        link: `/${resourceRelationships.pickup_site}/${rate.pickup_site.id}`,
        value:
          <>
            <Typography variant={'body1'}>{rate.pickup_site.name}</Typography>
            <Typography variant={'body2'}>{formatAddress(rate.pickup_site)}</Typography>
            <PinLink pinLink={rate.pickup_site.google_maps_pin_link} resource={resource} />
          </>
      },
      {
        label: translateForm('route'),
        value:
          <>
            <Typography variant={'body1'} className={classes.routeText}>
              {rate.pickup_site.name}
              <ArrowForwardIcon className={classes.routeIcon} fontSize='small' />
              {rate.dropoff_site.name}
            </Typography>
            <RouteLink routeLink={rate.route_link} resource={resource} />
          </>
      },
    ]
  }, [rate, resourceRelationships, resource, labelResource, labelRate, translateForm, classes, materialGrade])

  return (
    <Box>
      <Grid container className={classes.rateTitle} spacing={1} alignItems='center'>
        <Grid item>
          <Typography>
            {labelResource('tonnage_rate')}
          </Typography>
        </Grid>
        <Grid item className={classes.rateReloader}>
          {
            isLoading ?
            <CircularProgress color='inherit' size={18} style={{margin: 4}} /> :
            <IconButton size='small' tabIndex='-1' onClick={onRefreshRateClick} style={{ visibility: rate ? 'visible' : 'hidden' }}>
              <RefreshIcon fontSize='small'/>
            </IconButton>
          }
        </Grid>
        {
          rate &&
          <Grid item>
            <Link target='_blank' tabIndex='-1' className={classes.editLink} to={`/tonnage_rates/${rate.id}`} >
              {translateForm('edit')}
            </Link>
          </Grid>
        }
      </Grid>
      <Box style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        {
          rate ?
          <Grid container spacing={2} direction='column'>
            {
              items.map((item, idx) => (
                <Grid item key={idx}>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                      <Typography className={classes.rateRelationshipLabel}>
                        {item.label}
                      </Typography>
                    </Grid>
                    {
                      item.link &&
                      <Grid item>
                        <Link target='_blank' tabIndex='-1' className={classes.editLink} to={item.link} >
                          {translateForm('edit')}
                        </Link>
                      </Grid>
                    }
                  </Grid>
                  {item.value}
                </Grid>
              ))
            }
          </Grid> :
          <Typography variant='body2'>
            {translateForm('noRate')}
          </Typography>
        }
      </Box>
    </Box>
  )
}

const RouteMap = ({ rate }) => {
  const translateSite = useTranslateResource('sites', 'fieldNames')
  const classes = useStyles()

  const routeMapSrc = useMemo(() => {
    const pickupLat = rate?.pickup_site?.lat
    const pickupLong = rate?.pickup_site?.long
    const dropoffLat = rate?.dropoff_site?.lat
    const dropoffLong = rate?.dropoff_site?.long
    if (![pickupLat, pickupLong, dropoffLat, dropoffLong].every(Boolean)) return null
    return `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&origin=${pickupLat},${pickupLong}&destination=${dropoffLat},${dropoffLong}`
  }, [rate])

  if (!routeMapSrc) return null

  return (
    <Box className={classes.iframeWrap}>
      <iframe
        title={translateSite('route_link')}
        className={classes.iframe}
        width='100%'
        height='600'
        loading='lazy'
        src={routeMapSrc}
      />
    </Box>
  )
}

// wrap component so we can use useForm hook
const TonnageOrdersForm = props => {
  const { resource, record } = props
  const [selectedRateId, setSelectedRateId] = useState(record.tonnage_rate?.id)
  const [selectedMaterialGrade, setSelectedMaterialGrade] = useState(record.material_grade)
  const translate = useTranslateResource(resource, 'form')
  const classes = useStyles()
  const dataProvider = useContext(DataProviderContext)
  const [rate, setRate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const notify = useNotify()

  const transform = useCallback(data => {
    return {
      ...data,
      ...transformDispatches(record, data)
    }
  }, [record])

  const getRate = useCallback(async () => {
    let data = null
    if (selectedRateId) {
      setIsLoading(true)
      try {
        const resp = await dataProvider.getOne('tonnage_rates', { id: selectedRateId })
        data = resp.data
      } catch(e) {
        notify(`resources.${resource}.form.general.rateFetchError`, 'error')
      }
    }
    setRate(data)
    setIsLoading(false)
  }, [notify, selectedRateId, resource, dataProvider])

  useEffect(() => {
    getRate()
  }, [getRate])

  return (
    <Box>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={8}>
          <Form
            {...props}
            transform={transform}
            className={classes.form}
            infoText={translate('info.formHelper')}
          >
            <FormBody
              {...props}
              onRateIdChange={setSelectedRateId}
              onMaterialGradeChange={setSelectedMaterialGrade}
            />
          </Form>
        </Grid>
        <Grid item xs={4}>
          <SelectedRate
            rate={rate}
            isLoading={isLoading}
            onRefreshRateClick={getRate}
            resource={resource}
            materialGrade={selectedMaterialGrade}
          />
        </Grid>
      </Grid>
      <RouteMap rate={rate} />
    </Box>
  )
}

export default TonnageOrdersForm
