import React, {
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, useRefresh } from 'react-admin'
import { useForm } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import IconClear from '@material-ui/icons/Clear'

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: '10px',
    position: 'relative',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 18,
  },
}), { name: 'ClearButton' })

const sanitizeRestProps = ({
  basePath,
  invalid,
  pristine,
  reset,
  saving,
  submitOnEnter,
  handleSubmit,
  handleSubmitWithRedirect,
  undoable,
  onSave,
  ...rest
}) => rest

const ClearButton = ({ className, icon, ...rest}) => {
  const classes = useStyles()
  const form = useForm()
  const refresh = useRefresh()
  const handleClick = useCallback(() => {
    form.setConfig('keepDirtyOnReinitialize', false)
    form.reset()
    form.setConfig('keepDirtyOnReinitialize', true)
    refresh()
  }, [form, refresh])

  return (
    <Button
      className={classnames(classes.button, className)}
      onClick={handleClick}
      tabIndex="-1"
      {...sanitizeRestProps(rest)}
    >
      { icon ? React.cloneElement(icon, { className: classnames(classes.leftIcon, classes.icon) }) : null }
    </Button>
  )
}

ClearButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  icon: PropTypes.element,
}

ClearButton.defaultProps = {
  icon: <IconClear />,
  label: 'Clear',
}

export default ClearButton
