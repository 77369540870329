import {
  useEffect,
  useMemo,
} from 'react'
import {
  useQueryWithStore,
} from 'react-admin'
import { dateOnlyStringToISODate, iSODateToDateOnlyString } from '../../util/date'
import { usePolling, usePrevious } from '../../hooks'

const useMapData = ({ selectedPositionMarkerKey, startDate: startDateDateOnlyStr }) => {
  const startDate = useMemo(() => {
    return dateOnlyStringToISODate(startDateDateOnlyStr)
  }, [startDateDateOnlyStr])

  const endDate = useMemo(() => {
    return dateOnlyStringToISODate(startDateDateOnlyStr, 1)
  }, [startDateDateOnlyStr])

  const today = iSODateToDateOnlyString(new Date())
  const isToday = startDateDateOnlyStr === today

  const { data: orders = [], total: ordersTotal, loading: isLoadingTonnageOrders } = useQueryWithStore({
    type: 'getList',
    resource: 'tonnage_orders',
    payload: {
      filter: {
        // status_in: ['pending', 'active'],
        load_at_gteq: startDate,
        load_at_lt: endDate,
      },
      // pagination: {
      //   perPage: 50,
      //   page: 1,
      // },
      sort: {
        field: 'load_at',
        order: 'DESC',
      },
    },
  })

  const { data: contactRoutes = [], refetch: refetchContactRoutes, loading: isLoadingContactRoutes } = useQueryWithStore({
    type: 'getList',
    resource: 'contact_routes',
    payload: {
      filter: {
        start_date: startDate,
      }
    },
  })

  const { data: contactRouteDetail, loading: isLoadingContactRouteDetail } = useQueryWithStore({
    type: 'getOne',
    resource: 'contact_routes',
    payload: {
      filter: {
        start_date: startDate,
      },
      id: selectedPositionMarkerKey,
    },
  }, {
    action: 'CUSTOM_QUERY',
    enabled: selectedPositionMarkerKey != null,
  })

  const latestContactRouteDetailLocationCapture = useMemo(() => {
    if (contactRouteDetail == null) return null
    return contactRouteDetail.latest_location_captures[0].id
  }, [contactRouteDetail])

  const prevLatestContactRouteDetailLocationCapture = usePrevious(latestContactRouteDetailLocationCapture)
  const contactRouteDetailChanged = Boolean(latestContactRouteDetailLocationCapture && latestContactRouteDetailLocationCapture !== prevLatestContactRouteDetailLocationCapture)

  useEffect(() => {
    if (contactRouteDetailChanged && isToday) {
      refetchContactRoutes()
    }
  }, [contactRouteDetailChanged, refetchContactRoutes, isToday])

  usePolling(refetchContactRoutes, 10000, !isToday)

  const isLoading = isLoadingTonnageOrders || isLoadingContactRoutes || isLoadingContactRouteDetail

  const routes = useMemo(() => {
    return orders.map(o => {
      return {
        id: o.id,
        pickup: {
          position: {
            lat: o.tonnage_rate.pickup_site.longlat[1],
            lng: o.tonnage_rate.pickup_site.longlat[0],
          },
          title: o.tonnage_rate.pickup_site.name,
          id: o.tonnage_rate.pickup_site.id,
          radius: o.tonnage_rate.pickup_site.proximity_radius_in_meters,
        },
        dropoff: {
          position: {
            lat: o.tonnage_rate.dropoff_site.longlat[1],
            lng: o.tonnage_rate.dropoff_site.longlat[0],
          },
          title: o.tonnage_rate.dropoff_site.name,
          id: o.tonnage_rate.dropoff_site.id,
          radius: o.tonnage_rate.dropoff_site.proximity_radius_in_meters,
        },
      }
    })
  }, [orders])

  const positions = useMemo(() => {
    const cloned = [...contactRoutes]

    if (contactRouteDetail != null) {
      const idx = contactRoutes.findIndex(r => r.id === contactRouteDetail?.id)
      if (idx !== -1) {
        cloned[idx] = contactRouteDetail
      }
    }

    return cloned.map(p => {
      return {
        ...p,
        latest_location_captures: p.latest_location_captures.map(c => {
          const { longlat, ...rest } = c
          return {
            ...rest,
            position: {
              lat: longlat[1],
              lng: longlat[0],
            },
          }
        }),
        site_intersections: p.site_intersections.map(c => {
          const { longlat: locationCaptureLonglat, ...restLocationCapture} = c.location_capture
          const { longlat: siteLonglat, ...restSite } = c.site
          return {
            ...c,
            location_capture: {
              ...restLocationCapture,
              position: {
                lat: locationCaptureLonglat[1],
                lng: locationCaptureLonglat[0],
              },
            },
            site: {
              ...restSite,
              position: {
                lat: siteLonglat[1],
                lng: siteLonglat[0],
              },
            },
          }
        }),
      }
    })
  }, [contactRoutes, contactRouteDetail])

  return {
    data: {
      routes,
      positions,
      orders,
      ordersTotal,
    },
    isLoading,
  }
}

export default useMapData
