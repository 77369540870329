import { useCallback, useMemo } from 'react'
import {
  useNotify,
  useTranslate,
} from 'react-admin'
import { capitalize } from '../../../util'

const useNotifyResponse = props => {
  const notify = useNotify(props)
  const translate = useTranslate()
  const { resource, record, getOptimisticFailureMessageContext } = props

  const defaultAction = useMemo(() => {
    return Boolean(record.id) ? 'updated' : 'created'
  }, [record.id])
  
  const resourceTranslation = useMemo(() => {
    return capitalize(translate(`resources.${resource}.name`, {
      smart_count: 1,
    }))
  }, [resource, translate])

  const notifySuccess = useCallback((action) => {
    notify(`ra.notification.${action ?? defaultAction}`, 'info', {
      resource: resourceTranslation,
    }, false)
  }, [notify, resourceTranslation, defaultAction])

  const notifyDeleteSuccess = useCallback(() => {
    notifySuccess('deleted')
  }, [notifySuccess])

  const alertOptimisticFailure = useCallback((data) => {
    const message = translate(`ra.notification.optimistic_save_error`)
    const context = getOptimisticFailureMessageContext?.(data)
    alert(
      [
        resourceTranslation,
        context,
        message,
      ].filter(Boolean).join(' ')
    )
  }, [translate, resourceTranslation, getOptimisticFailureMessageContext])
  
  const notifyFailure = useCallback((error) => {
    const { status, body } = error
    const { generalErrors } = body
    if (status === 422) {
      if (generalErrors?.length) {
        notify('ra.notification.http_validation_error_with_message', 'warning', {
          resource: resourceTranslation,
          message: generalErrors.join('\n'),
        }, false, 10000)
      } else {
        notify('ra.notification.http_validation_error', 'warning', {
          resource: resourceTranslation,
        }, false)
      }
    } else if (generalErrors?.length) {
      notify('ra.notification.message', 'warning', {
        message: generalErrors.join('\n'),
      })
    } else {
      notify('ra.notification.http_error', 'error')
    }
  }, [notify, resourceTranslation])
  
  return {
    notifySuccess,
    notifyDeleteSuccess,
    notifyFailure,
    alertOptimisticFailure,
  }
}

export default useNotifyResponse
