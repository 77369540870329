import { cloneElement, Children } from 'react'
import { Filter as RaFilter } from 'react-admin'

const Filter = props => {
  const { children, ...rest } = props

  return (
    <RaFilter
      {...rest}
      variant='standard'
      margin='dense'
    >
      {Children.map(children, child => cloneElement(child, {
        fullWidth: true,
        alwaysOn: true,
        ...child.props
      }))}
    </RaFilter>
  )
}

export default Filter
