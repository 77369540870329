// Order corresponds to order in side nav

import { dateOnlyStringToISODate } from '../util/date'

const filterTransformCreatedAt = (filters) => {
  const { created_at_gteq, created_at_lt } = filters
  const finalFilters = { ...filters }
  if (created_at_gteq) {
    finalFilters.created_at_gteq = dateOnlyStringToISODate(created_at_gteq)
  }
  if (created_at_lt) {
    finalFilters.created_at_lt = dateOnlyStringToISODate(created_at_lt, 1)
  }
  return finalFilters
}

// Not all resources appear in side nav
const tonnageTickets = {
  humanReadableIdentifier: 'ticket_number',
  sort: { field: 'worked_at_date', order: 'DESC' },
  relationships: {
    material: 'materials',
    driver: 'broker_drivers',
    truck: 'trucks',
    customer: 'companies',
    pickup_site: 'sites',
    dropoff_site: 'sites',
    tonnage_rate: 'tonnage_rates',
    recipient: 'companies',
  },
  postParams: [
    'ticket_number',
    'net_weight_in_tons',
    'worked_at_date',
    'fuel_surcharge_in_dollars',
    'material_surcharge_in_dollars',
    'base_rate_in_dollars',
    'customer_id',
    'material_id',
    'pickup_site_id',
    'dropoff_site_id',
    'tonnage_rate_id',
    'driver_id',
    'truck_id',
    'recipient_id',
  ],
}

const materials =  {
  humanReadableIdentifier: 'name',
  sort: { field: 'name', order: 'ASC' },
  optionText: 'name',
  postParams: [
    'name',
  ],
}

const sites = {
  humanReadableIdentifier: 'name',
  sort: { field: 'name', order: 'ASC' },
  optionText: 'name',
  relationships: {
    company: 'companies',
  },
  postParams: [
    'name',
    'company_id',
    'address_street_1',
    'address_street_2',
    'address_city',
    'address_united_state_id',
    'address_zip',
    'checkin_radius_in_miles',
    'lat',
    'long',
    'internal_notes',
  ],
}

const companies = {
  humanReadableIdentifier: 'name',
  sort: { field: 'name', order: 'ASC' },
  optionText: 'name',
  postParams: [
    'name',
    'ledger_code',
    'email',
    'phone_number',
    'uses_consolidated_invoices',
    'billing_address_street_1',
    'billing_address_street_2',
    'billing_address_city',
    'billing_address_united_state_id',
    'billing_address_zip',
    'ticket_vendor_id',
  ],
}

const trucks = {
  humanReadableIdentifier: 'number',
  sort: { field: 'number', order: 'ASC' },
  relationships: {
    default_driver: 'broker_drivers',
  },
  optionText: 'number',
  postParams: [
    'number',
    'default_driver_id',
  ],
}

const hourlyTickets = {
  humanReadableIdentifier: 'ticket_number',
  sort: { field: 'worked_at_date', order: 'DESC' },
  relationships: {
    material: 'materials',
    customer: 'companies',
    driver: 'broker_drivers',
    truck: 'trucks',
    hourly_rate: 'hourly_rates',
  },
  postParams: [
    'ticket_number',
    'regular_time_in_hours',
    'doubletime_in_hours',
    'overtime_in_hours',
    'regular_rate_in_dollars',
    'overtime_rate_in_dollars',
    'doubletime_rate_in_dollars',
    'hourly_rate_id',
    'worked_at_date',
    'truck_id',
    'customer_id',
    'driver_id',
  ],
}

const resources = {
  tonnage_orders: {
    humanReadableIdentifier: 'order_number',
    sort: { field: 'load_at', order: 'ASC' },
    relationships: {
      tonnage_rate: 'tonnage_rates',
    },
    postParams: [
      'tonnage_rate_id',
      'load_at',
      'order_number',
      'num_trucks',
      'dispatches_attributes',
      'status',
      'material_grade',
      'notes',
    ],
  },
  hourly_orders: {
    humanReadableIdentifier: 'order_number',
    sort: { field: 'arrive_at', order: 'ASC' },
    relationships: {
      hourly_rate: 'hourly_rates',
      hourly_work_type: 'hourly_work_types',
      location: 'sites',
    },
    postParams: [
      'hourly_rate_id',
      'hourly_work_type_id',
      'location_id',
      'arrive_at',
      'order_number',
      'num_trucks',
      'dispatches_attributes',
      'status',
    ],
  },
  dispatches: {
    relationships: {
      broker_driver: 'broker_drivers',
    },
  },
  order_uploads: {
    humanReadableIdentifier: 'image_filename',
    sort: { field: 'created_at', order: 'DESC' },
    filterTransform: filterTransformCreatedAt,
    relationships: {
      customer: 'companies',
    },
  },
  tonnage_ticket_uploads: {
    humanReadableIdentifier: 'ticket_image_filename',
    sort: { field: 'created_at', order: 'DESC' },
    filterTransform: filterTransformCreatedAt,
  },
  // tonnage_ticket_spreadsheet_uploads: {
  //   humanReadableIdentifier: 'spreadsheet_filename',
  //   sort: { field: 'created_at', order: 'DESC' },
  //   relationships: {
  //     ticket_vendor: 'ticket_vendors',
  //   },
  // },
  tonnage_ticket_audits: {
    humanReadableIdentifier: 'spreadsheet_filename',
    sort: { field: 'created_at', order: 'DESC' },
    relationships: {
      ticket_vendor: 'ticket_vendors',
    },
    // postParams: [
    //   'rerun',
    //   'ticket_vendor_id',
    // ]
  },
  tonnage_ticket_audit_issues: {
  },
  tonnage_ticket_pdf_uploads: {
    humanReadableIdentifier: 'pdf_filename',
    sort: { field: 'created_at', order: 'DESC' },
  },
  tonnage_ticket_extractions: {
    humanReadableIdentifier: 'ticket_number',
    sort: { field: 'created_at', order: 'DESC' },
  },
  pending_tonnage_tickets: tonnageTickets,
  pending_hourly_tickets: hourlyTickets,
  tonnage_tickets: tonnageTickets,
  hourly_tickets: hourlyTickets,
  tonnage_rates: {
    humanReadableIdentifier: 'description',
    sort: { field: 'code', order: 'ASC' },
    relationships: {
      material: 'materials',
      customer: 'companies',
      recipient: 'companies',
      pickup_site: 'sites',
      dropoff_site: 'sites',
    },
  },
  hourly_rates: {
    humanReadableIdentifier: 'description',
    sort: { field: 'customer_name', order: 'ASC' },
    relationships: {
      customer: 'companies',
    },
  },
  materials,
  broker_drivers: {
    humanReadableIdentifier: 'name',
    sort: { field: 'name', order: 'ASC' },
  },
  sites,
  trucks,
  companies,
  hourly_work_types: {
    humanReadableIdentifier: 'name',
    sort: { field: 'name', order: 'ASC' },
  },
  exports: {
    humanReadableIdentifier: 'name',
    sort: { field: 'name', order: 'ASC' },
  },
  tonnage_ticket_invoices: {
    sort: { field: 'customer_name', order: 'ASC' },
  },
  hourly_ticket_invoices: {
    sort: { field: 'customer_name', order: 'ASC' },
  },
  broker_driver_pay_rollups: {
    sort: { field: 'driver_name', order: 'ASC' },
  },
  tonnage_revenues: {
    sort: { field: 'name', order: 'ASC' },
  },
  hourly_revenues: {
    sort: { field: 'name', order: 'ASC' },
  },
  broker_driver_aggregate_profits: {
    sort: { field: 'name', order: 'ASC' },
  },
  truck_aggregate_profits: {
    sort: { field: 'number', order: 'ASC' },
  },
  broker_driver_profits: {
    sort: { field: 'driver_name', order: 'ASC' },
  },
  periods: {
  },
  united_states: {
  },
  tenants: {
  },
  users: {
  },
  ticket_vendors: {
  },
  pending_material_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    postParams: [
      'resource_id',
      ...materials.postParams,
    ],
  },
  pending_customer_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    postParams: [
      'resource_id',
      ...companies.postParams,
    ],
  },
  pending_recipient_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    postParams: [
      'resource_id',
      ...companies.postParams,
    ],
  },
  pending_pickup_site_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    relationships: {
      company: 'companies',
    },
    postParams: [
      'resource_id',
      ...sites.postParams,
    ],
  },
  pending_dropoff_site_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    relationships: {
      company: 'companies',
    },
    postParams: [
      'resource_id',
      ...sites.postParams,
    ],
  },
  pending_truck_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    relationships: {
      default_driver: 'broker_drivers',
    },
    postParams: [
      'resource_id',
      ...trucks.postParams,
    ],
  },
  material_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    humanReadableIdentifier: 'text',
  },
  site_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    humanReadableIdentifier: 'text',
  },
  company_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    humanReadableIdentifier: 'text',
  },
  truck_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    humanReadableIdentifier: 'text',
  },
  pending_tonnage_rates: {
    sort: { field: 'customer_name', order: 'ASC' },
    relationships: {
      material: 'materials',
      customer: 'companies',
      recipient: 'companies',
      pickup_site: 'sites',
      dropoff_site: 'sites',
    },
  },
  broker_drivers_users: {
    sort: { field: 'created_at', order: 'DESC' },
    relationships: {
      broker_driver: 'broker_drivers',
      user: 'users',
    },
  },
  customers_users: {
    sort: { field: 'created_at', order: 'DESC' },
    relationships: {
      customer: 'companies',
      user: 'users',
    },
  },
  contacts: {
    sort: { field: 'created_at', order: 'DESC' },
    humanReadableIdentifier: 'phone',
    relationships: {
      truck: 'trucks',
    },
    postParams: [
      'truck_id',
      'phone',
    ],
  },
  suggest_locations: {},
  position_locations: {},
  contact_routes: {},
}

export default resources
