import React from 'react'
import { Edit } from 'react-admin'
import Form from './form'
import { editOptions } from '../../util/component-options'
import { EmbeddedExtractionMappingsList } from '../../shared'

const CompaniesEdit = props => {
  return (
    <>
      <Edit {...editOptions} {...props} >
        <Form />
      </Edit>
      <EmbeddedExtractionMappingsList
        resource='company_extraction_mappings'
        filter={{
          company_id_eq: props.id,
        }}
      />
    </>
  )
}

export default CompaniesEdit
