import {
  TextInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { AddressInputFields } from '../../shared'

const TenantsForm = props => {
  const { resource } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  
  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='name'
            label={label('name')}
            validate={getValidator()}
            required
          />

        </Box>
        <Box>
          <AddressInputFields resource={resource} />
        </Box>
      </Box>
    </Form>
  )
}

export default TenantsForm
