import { useCallback } from 'react'
import {
  useNotify,
  useTranslate,
} from 'react-admin'
import { Box, Button, makeStyles } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'

const useStyles = makeStyles(({ spacing }) => {
  return {
    root: {
      padding: spacing(3)
    },
    iframeWrap: {
      marginTop: spacing(1)
    },
    iframe: {
      border: 0,
    },
  }
})

const EmbeddedSiteMap = ({ record }) => {
  const { google_maps_pin_link, lat, long } = record
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()

  const copy = useCallback(() => {
    navigator.clipboard.writeText(google_maps_pin_link)
    notify('resources.sites.actions.copiedGoogleMapsPinLink')
  }, [google_maps_pin_link, notify])

  if (!(google_maps_pin_link && lat && long)) return null

  return (
    <Box className={classes.root}>
      <Button
        tabIndex='-1'
        size='small'
        color='secondary'
        onClick={copy}
        startIcon={<DescriptionIcon />}
      >
        {translate('resources.sites.actions.copyGoogleMapsPinLink')}
      </Button>
      <Box className={classes.iframeWrap}>
        <iframe
          title={translate('resources.sites.fieldNames.google_maps_pin_link')}
          className={classes.iframe}
          width='100%'
          height='450'
          loading='lazy'
          src={`https://maps.google.com/maps?q=${lat},${long}&hl=en&z=10&output=embed`}
        />
      </Box>
    </Box>
  )
}


export default EmbeddedSiteMap
