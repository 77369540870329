import {
  TextField,
  NumberField,
  useListContext,
} from 'react-admin'
import { Datagrid } from '../../custom'
import {
  currencyFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'

const BrokerDriverProfitsDatagrid = ({ isPrintView }) => {
  const context = useListContext()
  const label = useLabel(context)

  return (
    <Datagrid
      hasEdit={false}
    >
      <TextField source='driver.name' label={label('driver')} sortBy='driver_name' />
      <NumberField source='regular_total_in_dollars' label={label('regular_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='overtime_total_in_dollars' label={label('overtime_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='doubletime_total_in_dollars' label={label('doubletime_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_in_dollars' label={label('total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='tonnage_income_total_in_dollars' label={label('tonnage_income_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='hourly_income_total_in_dollars' label={label('hourly_income_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='income_total_in_dollars' label={label('income_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='profit_total_in_dollars' label={label('profit_total_in_dollars')} options={currencyFieldOptions} />
    </Datagrid>
  )
}

export default BrokerDriverProfitsDatagrid
