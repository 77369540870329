import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useListContext } from 'ra-core'
import { key } from '../reducer/collection-data/resources'
import get from 'lodash/get'

const useListCollectionDataSelector = () => {
  const { resource, filterValues, filter } = useListContext()
  
  const keyName = useMemo(() => {
    return key({
      ...filterValues,
      ...filter,
    })
  }, [filterValues, filter])

  return useSelector(state => get(state.collectionData.resources, [resource, keyName])) || {}
}

export default useListCollectionDataSelector
