import tonnageTicketsComponents from './tonnage-tickets'
import tonnageRatesComponents from './tonnage-rates'
import materialsComponents from './materials'
import sitesComponents from './sites'
import companiesComponents from './companies'
import brokerDriversComponents from './broker-drivers'
import trucksComponents from './trucks'
import hourlyRatesComponents from './hourly-rates'
import hourlyTicketsComponents from './hourly-tickets'
import hourlyTicketInvoicesComponents from './hourly-ticket-invoices'
import tonnageTicketInvoicesComponents from './tonnage-ticket-invoices'
import brokerDriverPayRollupsComponents from './broker-driver-pay-rollups'
import brokerDriverAggregateProfitsComponents from './broker-driver-aggregate-profits'
import exportsComponents from './exports'
import tonnageRevenuesComponents from './tonnage-revenues'
import hourlyRevenuesComponents from './hourly-revenues'
import tonnageTicketUploadsComponents from './tonnage-ticket-uploads'
// import tonnageTicketSpreadsheetUploadsComponents from './tonnage-ticket-spreadsheet-uploads'
// import tonnageTicketPdfUploadsComponents from './tonnage-ticket-pdf-uploads'
import tonnageTicketExtractionsComponents from './tonnage-ticket-extractions'
import pendingTonnageTicketsComponents from './pending-tonnage-tickets'
import contactsComponents from './contacts'
import brokerDriversUsersComponents from './broker-drivers-users'
import tonnageOrdersComponents from './tonnage-orders'
import customersUsersComponents from './customers-users'
// import orderUploadsComponents from './order-uploads'
import tonnageTicketAuditsComponents from './tonnage-ticket-audits'
import truckAggregateProfitsComponents from './truck-aggregate-profits'
import hourlyOrdersComponents from './hourly-orders'
import hourlyWorkTypesComponents from './hourly-work-types'

import { capitalize } from '../../util'
import camelCase from 'camelcase'
import { resourceTypes } from '../../data-model'

const componentsMap = {
  tonnageTicketsComponents,
  tonnageRatesComponents,
  materialsComponents,
  sitesComponents,
  companiesComponents,
  brokerDriversComponents,
  trucksComponents,
  hourlyRatesComponents,
  contactsComponents,
  hourlyTicketsComponents,
  hourlyTicketInvoicesComponents,
  tonnageTicketInvoicesComponents,
  brokerDriverPayRollupsComponents,
  brokerDriverAggregateProfitsComponents,
  truckAggregateProfitsComponents,
  exportsComponents,
  tonnageRevenuesComponents,
  hourlyRevenuesComponents,
  tonnageTicketUploadsComponents,
  // tonnageTicketSpreadsheetUploadsComponents,
  // tonnageTicketPdfUploadsComponents,
  tonnageTicketExtractionsComponents,
  pendingTonnageTicketsComponents,
  brokerDriversUsersComponents,
  tonnageOrdersComponents,
  customersUsersComponents,
  // orderUploadsComponents,
  tonnageTicketAuditsComponents,
  hourlyOrdersComponents,
  hourlyWorkTypesComponents,
}

const views = [
  'list',
  'create',
  'edit',
]

const mapComponents = module => {
  return views.reduce((accum, view) => {
    return {
      ...accum,
      [view]: module[capitalize(view)],
      options: {
        isMenuItem: true,
      },
    }
  }, {})
}

const adminResources = resourceTypes.map(name => {
  const module = componentsMap[`${camelCase(name)}Components`]

  return {
    name,
    ...(module ? mapComponents(module) : null),
  }
})

export default adminResources
