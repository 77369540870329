import { cloneElement, useEffect } from 'react'
import {
  useMemo,
  useState,
  useCallback,
} from 'react'
import {
  useListContext,
  useDataProvider,
  useNotify,
} from 'react-admin'
import usePrint from './use-print'
import useTranslateResource from './use-translate-resource'

const usePrintDetail = (detail, options = {}) => {
  const notify = useNotify()
  const { getMany } = useDataProvider()
  const { ids, resource, loading: isLoadingResource, currentSort: sort } = useListContext()
  const translateResource = useTranslateResource(resource)
  const title = translateResource('name', { smart_count: 2 }).toLocaleUpperCase()
  const [items, setItems] = useState([])
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isAwaitingPrint, setIsAwaitingPrint] = useState(false)

  const [
    print,
    Printable,
    willPrint,
  ] = usePrint({
    title,
    ...options,
  })

  const isDisabled = isLoadingData || isLoadingResource || willPrint || !ids.length

  const printDetail = useCallback(async () => {
    setIsLoadingData(true)

    try {
      const { data } = await getMany(resource, { ids, sort, filter: { view: 'detail' } })
      setItems(data)
      setIsAwaitingPrint(true)
    } catch(e) {
      notify('ra.notification.http_error', 'error')
    } finally {
      setIsLoadingData(false)
    }
  }, [resource, ids, setItems, notify, getMany, sort])

  const printable = useMemo(() => {
    return (
      <Printable>
        {items.map(record => cloneElement(detail, {record, key: record.id}))}
      </Printable>
    )
  }, [items, Printable, detail]) // eslint-disable-line

  useEffect(() => {
    if (isAwaitingPrint) { 
      print()
      setIsAwaitingPrint(false)
    }
  }, [print, isAwaitingPrint])

  return [
    printDetail,
    printable,
    isDisabled,
  ]
}

export default usePrintDetail
