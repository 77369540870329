import {
  ReferenceInput,
  TextInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete, NumberInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { relationships } from '../../../data-model'
import { Form, getValidator } from '../../form'
import { AddressInputFields } from '../../shared'
import Location from '../../location'
import { useAddressFill } from '../../form/functions'

const LookupLocationAction = () => {
  const onLocationChange = useAddressFill()
  return <Location onChange={onLocationChange} />
}

const FormBody = props => {
  const { resource } = props
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(props)
  const label = useLabel(props)

  return (
    <Box className={formClasses.grid}>
      <Box>
        <TextInput
          {...formInputOptions}
          source='name'
          label={label('name')}
          validate={getValidator()}
          required
        />

        <ReferenceInput
          {...referenceInputOptions(resourceRelationships['company'])}
          {...formInputOptions}
          source='company.id'
          reference={resourceRelationships['company']}
          label={label('company')}
        >
          <Autocomplete validate={getValidator(false)} />
        </ReferenceInput>

        <TextInput
          {...formInputOptions}
          source='lat'
          label={label('lat')}
        />

        <TextInput
          {...formInputOptions}
          source='long'
          label={label('long')}
        />

        <NumberInput
          {...formInputOptions}
          source='checkin_radius_in_miles'
          label={label('checkin_radius_in_miles')}
          step={0.01}
          min={0}
        />

        <TextInput
          {...formInputOptions}
          source='internal_notes'
          label={label('internal_notes')}
          multiline
        />
      </Box>

      <Box>
        <AddressInputFields
          resource={resource}
          addressRequired={false}
        />
      </Box>
    </Box>
  )
}

const SitesForm = props => {
  return (
    <Form
      {...props}
      formActions={[<LookupLocationAction />]}
    >
      <FormBody {...props} />
    </Form>
  )
}

export default SitesForm
