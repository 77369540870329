 const cancelablePromise = promise => {
  const status = { isCanceled: false }
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(d => {
      return status.isCanceled ? reject(status) : resolve(d)
    })
    .catch(e => {
      reject(status.isCanceled ? status : e)
    })
  })

  return {
    promise: wrappedPromise,
    cancel: () => {
      status.isCanceled = true
    },
  }
}

export default cancelablePromise
