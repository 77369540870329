import { useState, useCallback, useRef } from 'react'
import { getApiUrl, jsonHttpClient } from '../http-client'
import cancelablePromise from '../components/util/cancelable-promise'

const useFetch = ({ url, query, method='post', onFailure, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const cancelRef = useRef(() => {})

  const _fetch = useCallback(async ({ url: urlOverride, query: queryOverride, data }) => {
    setIsLoading(true)

    try {
      const apiUrl = getApiUrl(urlOverride || url, { query: queryOverride || query })
      const p = jsonHttpClient[method.toLocaleLowerCase()](apiUrl, { body: JSON.stringify(data) })
      const { promise, cancel } = cancelablePromise(p)
      cancelRef.current = cancel
      const respData = await promise
      setIsLoading(false)
      onSuccess(respData)
    } catch (error) {
      if (!error.isCanceled) {
        setIsLoading(false)
        onFailure(error)
      }
    }
  }, [onFailure, onSuccess, url, method, query])

  return [
    _fetch,
    isLoading,
    cancelRef,
  ]
}

export default useFetch
