import {
  TextField,
  DateField,
  TextInput,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import {
  dateTimeFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'

const TonnageTicketAuditsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('spreadsheet_filename', 'find')} source='spreadsheet_file_blob_filename_i_cont' />
    </Filter>
  )
}

const TonnageTicketAuditsList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
      filters={<TonnageTicketAuditsFilter />}
    >
      <Datagrid
        hasEdit
      >
        <TextField source='spreadsheet_filename' label={label('spreadsheet_filename')} sortBy='spreadsheet_file_blob_filename' />
        <TextField source='ticket_vendor.name' label={label('ticket_vendor')} sortBy='ticket_vendor_name' />
        <DateField source='created_at' label={label('created_at')} options={dateTimeFieldOptions}/>
      </Datagrid>
    </List>
  )
}

export default TonnageTicketAuditsList
