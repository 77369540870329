import { useState, useCallback } from 'react'
import AddLocationIcon from '@material-ui/icons/AddLocation'
import { Dialog, DialogTitle, DialogContent, Button, withStyles } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import LookupLocation from './lookup-location'

const StyledDialog = withStyles(() => {
  return {
    root: {
      overflow: 'auto',
    },
    container: {
      height: 'auto',
    },
    paper: {
      marginTop: '10vh',
      marginBottom: '10vh',
    },
  }
})(Dialog)

const Search = ({ onChange, ...rest }) => {
  const translate = useTranslate()
  const [showModal, setShowModal] = useState(false)

  const onClick = useCallback(() => {
    setShowModal(true)
  }, [])

  const onModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  const onSubmit = useCallback((data) => {
    setShowModal(false)
    onChange(data)
  }, [onChange])

  return (
    <>
      <Button
        onClick={onClick}
        color='primary'
        tabIndex='-1'
        {...rest}
      >
        <AddLocationIcon fontSize='default' />
        {translate('searchTitles.locations')}
      </Button>
      {
        <StyledDialog
          maxWidth='sm'
          fullWidth
          open={showModal}
          onClose={onModalClose}
        >
          <DialogTitle>
            {translate('searchTitles.locations')}
          </DialogTitle>
          <DialogContent>
            <LookupLocation onSubmit={onSubmit} />
          </DialogContent>
        </StyledDialog>
      }
    </>
  )
}

export default Search
