import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Title,
  useTranslate,
} from 'react-admin'
import {
  Card,
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core'
import {
  APIProvider,
  Map as GoogleMap,
  useMap,
  useApiIsLoaded,
  useMapsLibrary,
} from '@vis.gl/react-google-maps'
import PositionMarker from './position-marker'
import EndpointMarker from './endpoint-marker'
import useMapData from './use-map-data'
import useMarkers from './use-markers'
import DateFilter from './date-filter'
import Legend from './legend'
import Orders from './orders'
import { iSODateToDateOnlyString } from '../../util/date'
import usePrevious from '../../hooks/use-previous'

const mapRenderingTypeIsVector = (function() {
  const canvas = document.createElement('canvas')
  return canvas.getContext('webgl2') ? true : false
})();

const mapId = mapRenderingTypeIsVector ? process.env.REACT_APP_GOOGLE_MAPS_VECTOR_MAP_ID : process.env.REACT_APP_GOOGLE_MAPS_RASTER_MAP_ID
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const translationPrefix = 'views.dispatchMap'
const ordersSidebarWidth = 280

const mapProps = {
  defaultCenter: {
    lat: 40,
    lng: -86,
  },
  defaultZoom: 10,
  options: {
    mapId,
    id: 'dispatch-map',
    gestureHandling: 'greedy',
    isFractionalZoomEnabled: mapRenderingTypeIsVector,
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    minZoom: 2,
  },
}

const useStyles = makeStyles((theme) => {
  const overlaySpacing = theme.spacing(1)
  return {
    root: {
      padding: 0,
      position: 'sticky',
      top: 48,
      height: 'calc(100vh - 48px)',
      margin: -theme.spacing(3),
      marginLeft: -5,
      [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
        margin: 0,
      },
      '& .gm-style iframe + div': {
        border: 'none! important',
      },
      '& .GMAMP-maps-pin-view': {
        opacity: 0.9,
      },
    },
    card: {
      padding: 0,
      height: '100%',
      borderRadius: 0,
    },
    controls: {
      position: 'absolute',
      top: overlaySpacing,
      left: overlaySpacing,
      padding: theme.spacing(1),
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    legend: {
      position: 'absolute',
      top: overlaySpacing + 56,
      left: overlaySpacing,
      padding: theme.spacing(1),
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    orders: {
      position: 'absolute',
      top: overlaySpacing,
      right: overlaySpacing,
      maxHeight: `calc(100% - ${overlaySpacing * 3}px)`,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      width: ordersSidebarWidth,
      overflowY: 'auto',
    },
  }
})

const DispatchMapInner = () => {
  const google = window.google
  const classes = useStyles()
  const map = useMap()
  const defaultStartDate = iSODateToDateOnlyString(new Date())

  const [
    {
      selectedPositionMarkerKey,
      startDate,
    },
    setFilterState,
  ] = useState({
    selectedPositionMarkerKey: null,
    startDate: defaultStartDate,
  })

  const [
    {
      selectedEndpointMarker,
      selectedOrder,
    },
    setSelectedOrderState,
  ] = useState({
    selectedEndpointMarker: null,
    selectedOrder: null,
  })

  const mapData = useMapData({ selectedPositionMarkerKey, startDate })
  const { orders, routes, positions } = mapData.data

  const {
    endpointMarkers,
    positionMarkers,
    setEndpointMarkerRef,
    setPositionMarkerRef,
  } = useMarkers({ routes, positions, selectedEndpointMarker, selectedOrder})

  const endpointMarkerPositions = useMemo(() => {
    const positions = endpointMarkers.map((marker) => marker.position)
    return JSON.stringify(positions)
  }, [endpointMarkers])

  const prevEndpointMarkerPositions = usePrevious(endpointMarkerPositions)
  const endpointMarkerPositionsChanged = endpointMarkerPositions !== prevEndpointMarkerPositions

  const fitBounds = useCallback(() => {
    if (!map || !(endpointMarkers?.length)) return
    if (!endpointMarkerPositionsChanged) return
    const bounds = new google.maps.LatLngBounds()
    for (const marker of endpointMarkers) {
      bounds.extend(marker.position)
    }
    google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
      map.setZoom(Math.min(map.getZoom(), 12))
    })
    map.fitBounds(bounds, { top: 100, right: Math.max(40, ordersSidebarWidth), bottom: 30, left: 40 })
  }, [google, map, endpointMarkers, endpointMarkerPositionsChanged])

  const onDateChange = useCallback((date) => {
    setFilterState(prev => ({
      ...prev,
      startDate: date,
      selectedPositionMarkerKey: prev.startDate === date ? prev.selectedPositionMarkerKey : null,
    }))
  }, [])

  const onPositionMarkerClick = useCallback(({ key }) => {
    setFilterState(prev => ({
      ...prev,
      selectedPositionMarkerKey: prev.selectedPositionMarkerKey === key ? null : key,
    }))
  }, [])

  const onEndpointMarkerClick = useCallback((marker) => {
    setSelectedOrderState({ selectedEndpointMarker: marker, selectedOrder: null })
  }, [])

  const resetSelectedOrderState = useCallback(() => {
    setSelectedOrderState({ selectedEndpointMarker: null, selectedOrder: null })
  }, [])

  const onSelectOrder = useCallback((order) => {
    if (selectedOrder === order) {
      fitBounds()
    } else {
      setSelectedOrderState({ selectedEndpointMarker: null, selectedOrder: order })
    }
  }, [selectedOrder, fitBounds])

  useEffect(() => {
    fitBounds()
  }, [fitBounds])

  useEffect(() => {
    resetSelectedOrderState()
  }, [resetSelectedOrderState, orders])

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <GoogleMap {...mapProps} onClick={resetSelectedOrderState}>
          <>
            {
              endpointMarkers.map((marker) => {
                return (
                  <EndpointMarker
                    key={marker.key}
                    marker={marker}
                    position={marker.position}
                    type={marker.type}
                    title={marker.title}
                    label={marker.points.length}
                    setMarkerRef={setEndpointMarkerRef}
                    onClick={onEndpointMarkerClick}
                  />
                )
              })
            }
          </>
          <>
            {
              positionMarkers.map((marker) => {
                return (
                  <PositionMarker
                    key={marker.key}
                    marker={marker}
                    position={marker.position}
                    type={marker.type}
                    title={marker.title}
                    heading={marker.heading}
                    subTitle={marker.subTitle}
                    setMarkerRef={setPositionMarkerRef}
                    onClick={onPositionMarkerClick}
                  />
                )
              })
            }
          </>
        </GoogleMap>
        <Card className={classes.controls}>
          <Grid container>
            <Grid item>
              <DateFilter
                date={startDate}
                onChange={onDateChange}
                isDisabled={mapData.isLoading}
              />
            </Grid>
          </Grid>
        </Card>
        <Card className={classes.legend}>
          <Legend />
        </Card>
        <Card className={classes.orders}>
          <Orders
            selectedEndpoint={selectedEndpointMarker}
            onShowAllClick={resetSelectedOrderState}
            orders={orders}
            onSelectOrder={onSelectOrder}
            selectedOrder={selectedOrder}
          />
        </Card>
      </Card>
    </Box>
  )
}

const Loaded = ({ children }) => {
  // FIXME: loading maker libarary is a workaround for slow initial rendering for AdvancedMarker
  // https://github.com/visgl/react-google-maps/issues/171
  const markerLib = useMapsLibrary('marker')
  const geometryLib = useMapsLibrary('geometry')
  const isLoaded = useApiIsLoaded()
  return isLoaded && markerLib && geometryLib && children
}

const DispatchMap = () => {
  const translate = useTranslate()

  return (
    <APIProvider apiKey={apiKey}>
      <Title title={translate(`${translationPrefix}.name`)} />
      <Loaded>
        <DispatchMapInner />
      </Loaded>
    </APIProvider>
  )
}

export default DispatchMap
