import {
  Loading,
  NullableBooleanInput,
  TextInput,
} from 'react-admin'
import Datagrid from './datagrid'
import CollectionData from './collection-data'
import DriverPayroll from './driver-payroll'
import { List, Filter, NumberInput } from '../../custom'
import { PrintListButtons } from '../../shared'
import { withCollectionData } from '../../hoc'
import { useEarliestIncompletePeriod, useLabel } from '../../../hooks'
import PeriodDatePicker from '../../shared/period-date-picker'

const DatagridWithTotals = withCollectionData(Datagrid, CollectionData)

const BrokerDriverPayRollupsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <PeriodDatePicker label={label('period_end_date')} source='period_end_date_eq' />
      <NullableBooleanInput label={label('is_archived')} source='is_archived_true' />
      <NumberInput label={label('total_in_dollars', 'filter', 'greaterThan')} source='total_in_dollars_gteq' />
      <TextInput label={label('driver', 'filter')} source='driver_name_i_cont' />
    </Filter>
  )
}

const Actions = () => {
  return (
    <PrintListButtons
      summary={<DatagridWithTotals/>}
      detail={<DriverPayroll/>}
    />
  )
}

const BrokerDriverPayRollupsList = props => {
  const period = useEarliestIncompletePeriod()

  if (!period) return <Loading />

  return (
    <List
      {...props}
      filters={<BrokerDriverPayRollupsFilter />}
      filter={{ include_collection_data: true }}
      actions={<Actions />}
      filterDefaultValues={{ period_end_date_eq: period?.end_date }}
    >
      <DatagridWithTotals />
    </List>
  )
}

export default BrokerDriverPayRollupsList
