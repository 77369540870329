import { Route, Redirect } from 'react-router-dom'
import { Admin, Resource} from 'react-admin'
import CssBaseline from '@material-ui/core/CssBaseline'
import jsonProvider from './json-provider'
import cacheDataProviderProxy from './json-provider/cache-proxy'
import authProvider from './auth-provider'
import i18nProvider from './i18n-provider'
import { createBrowserHistory } from 'history'
import theme from './theme'
import adminResources from './components/resources'
import Layout from './components/layout'
import LoginPage from './components/login'
import Help from './components/help'
import CompanyEdit from './components/resources/tenants/edit'
import AccountEdit from './components/resources/users/edit'
import { Preview as TransferPreview } from './components/transfer'
import { Preview as PendingResourcesPreview } from './components/pending-resources'
import DispatchMap from './components/dispatch-map'
import reducer from './reducer'
import sagas from './sagas'

const history = createBrowserHistory()
const dataProviderWithCache = cacheDataProviderProxy(jsonProvider())

const customRoutes = [
  <Route exact path='/'>
    <Redirect to='/tonnage_orders' />
  </Route>,
  <Route exact path='/help' component={Help} />,
  <Route exact path='/business_settings' component={CompanyEdit} />,
  <Route exact path='/account_settings' component={AccountEdit} />,
  <Route exact path='/transfer' >
    <Redirect to='/transfer/tonnage_ticket_invoices'/>
  </Route>,
  <Route path='/transfer' component={TransferPreview} />,
  <Route exact path='/pending_resources' >
    <Redirect to='/pending_resources/customers'/>
  </Route>,
  <Route exact path='/pending_tonnage_tickets/:id' render={props => (
    <Redirect to={`/pending_tonnage_tickets/${props.match.params.id}/tonnage`}/>
  )} />,
  <Route path='/pending_resources' component={PendingResourcesPreview} />,
  <Route path='/dispatch_map' component={DispatchMap} />,
]

function App() {
  return (
    <>
      <Admin
        disableTelemetry
        customSagas={sagas}
        customReducers={reducer}
        history={history}
        theme={theme}
        layout={Layout}
        loginPage={LoginPage}
        dataProvider={dataProviderWithCache}
        authProvider={authProvider()}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
      >
        { adminResources.map((resourceProps, i) => <Resource key={i} {...resourceProps} />) }
      </Admin>
      <CssBaseline/>
    </>
  )
}

export default App
