import { useState, useMemo } from 'react'
import { useFindFromStore } from '.'

const useReferenceLookup = (resource, initial) => {
  const [selectedId, setSelectedId] = useState(initial?.id)
  const findRecordFromStore = useFindFromStore(resource)

  const selectedRecord = useMemo(() => {
    return findRecordFromStore(selectedId) || (
      selectedId === initial?.id ? initial : undefined
    )
  }, [selectedId, findRecordFromStore, initial])

  return {
    selectedRecord,
    onSelectedIdChange: setSelectedId,
  }
}

export default useReferenceLookup
