import {
  useState,
  useCallback,
} from 'react'
import {
  useRefresh,
} from 'react-admin'
import {
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    paddingBottom: spacing(3),
  },
  title: {
    textTransform: 'capitalize',
  },
}))

const defaultButtonProps = {
  size: 'small',
  color: 'primary',
}

const CompleteButton = ({
  record,
  reference,
  resource,
  buttonClassName,
  dialogTitle,
  DialogContentComponent,
  ResourceFormComponent
}) => {
  const classes = useStyles()
  const [showModal, setShowModal] = useState(false)
  const refresh = useRefresh()

  const onClick = useCallback(() => {
    setShowModal(true)
  }, [])

  const onModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  const onSuccess = useCallback(() => {
    setShowModal(false)
    refresh()
  }, [refresh])

  return (
    <>
      <Button
        {...defaultButtonProps}
        className={buttonClassName}
        onClick={onClick}
      >
        <EditIcon fontSize='small'/>
      </Button>
      {
        <Dialog
          maxWidth='md'
          fullWidth
          open={showModal}
          onClose={onModalClose}
        >
          <DialogTitle className={classes.title}>
            {dialogTitle}
          </DialogTitle>
          <DialogContent
            className={classes.content}
          >
            <DialogContentComponent
              record={record}
              onSuccess={onSuccess}
              resource={resource}
              reference={reference}
              ResourceFormComponent={ResourceFormComponent}
            />
          </DialogContent>
        </Dialog>
      }
    </>
  )
}

export default CompleteButton
