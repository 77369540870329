import { Card, CardContent } from '@material-ui/core'
import { Title } from 'react-admin'

const Help = props => {
  return (
    <Card>
      <Title title='Help' />
      <CardContent>
        TODO: Help page
      </CardContent>
    </Card>
  )
}

export default Help
