import { Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'

const InlineLoading = () => {
  const translate = useTranslate()

  return (
    <Typography variant='body1'>
      {`${translate('ra.page.loading')}...`}
    </Typography>
  )
}

export default InlineLoading
