import {
  SelectArrayInput as RaSelectArrayInput,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const StyledRaSelectArrayInput = withStyles(() => {
  return {
    chip: {
      height: 'auto',
    },
  }
})(RaSelectArrayInput)

const SelectArrayInput = props => {
  return (
    <StyledRaSelectArrayInput {...props} />
  )
}

export default SelectArrayInput
