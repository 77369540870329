import {
  cloneElement,
  useMemo,
} from 'react'
import {
  useListContext,
} from 'react-admin'
import usePrint from './use-print'
import useTranslateResource from './use-translate-resource'

const usePrintSummary = (summary, options = {}) => {
  const context = useListContext()
  const { loading, ids, resource } = context
  const translateResource = useTranslateResource(resource)
  const title = translateResource('name', { smart_count: 2 }).toLocaleUpperCase()

  const [
    print,
    Printable,
    willPrint,
  ] = usePrint({
    title,
    ...options,
  })

  const isDisabled = loading || willPrint || !ids.length

  const printable = useMemo(() => {
    return (
      <Printable>
         {cloneElement(summary, { ...context, isPrintView: true })}
      </Printable>
    )
  }, [Printable, summary]) // eslint-disable-line

  return [
    print,
    printable,
    isDisabled,
  ]
}

export default usePrintSummary
