import {
  ReferenceInput,
  TextInput,
  FormDataConsumer,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete, NumberInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import {
  useLabel,
} from '../../../hooks'
import {
  useTonnageRateFields,
  useTonnageRateReferenceFields,
} from '../../form/functions'
import { relationships } from '../../../data-model'
import { Form, getValidator } from '../../form'

const TonnageRatesForm = (props) => {
  const { resource } = props
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(props)
  const rateFields = useTonnageRateFields()
  const label = useLabel(props)

  const referenceFields = useTonnageRateReferenceFields()

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          {
            rateFields.map(({ source, required: _required, label: _label }) => (
              <FormDataConsumer key={source}>
                {() => {
                  return (
                    <NumberInput
                      {...formInputOptions}
                      source={source}
                      label={label(_label)}
                      step={0.01}
                      min={0}
                      required={_required}
                    />
                  )
                }}
              </FormDataConsumer>
            ))
          }
          <TextInput
            {...formInputOptions}
            source='code'
            label={label('code')}
            validate={getValidator(false)}
            required={false}
          />
          <TextInput
            {...formInputOptions}
            source='description'
            label={label('description')}
            validate={getValidator(false)}
            required={false}
          />
        </Box>

        <Box>
          {
            referenceFields.map(({ source, required, label: _label}) => (
              <ReferenceInput
                {...referenceInputOptions(resourceRelationships[source])}
                {...formInputOptions}
                key={source}
                source={`${source}.id`}
                reference={resourceRelationships[source]}
                label={label(_label)}
                required={required}
              >
                <Autocomplete validate={getValidator(required)} />
              </ReferenceInput>
            ))
          }
        </Box>
      </Box>
    </Form>
  )
}

export default TonnageRatesForm
