import {
  Datagrid as RaDatagrid,
  EditButton,
  ShowButton,
  FunctionField,
} from 'react-admin'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import isFunction from 'lodash/isFunction'

const StyledRaDatagrid = withStyles(({ spacing }) => ({
  headerCell: {
    fontWeight: 'bold',
    padding: spacing(1),
  },
  rowCell: {
    padding: spacing(1),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 260,
    '@media print': {
      whiteSpace: 'initial',
      textOverflow: 'initial',
      overflow: 'initial',
    }
  },
}))(RaDatagrid)

const StyledBox = withStyles(() => ({
  root: {
    overflow: 'auto',
    minWidth: '100%',
  },
}))(Box)

const StyledEditButton = withStyles(() => ({
  button: {
    minWidth: 0,
  }
}))(EditButton)

const StyledShowButton = withStyles(() => ({
  button: {
    minWidth: 0,
  }
}))(ShowButton)

const useEditCellStyles = makeStyles(({ spacing }) => ({
  root: {
    width: 1,
    padding: `0 ${spacing(0.5)}px`,
  },
}))

const Datagrid = props => {
  const { children, hasEdit = true, hasShow = false, ...rest } = props
  const { resource } = rest
  const editCellStyles = useEditCellStyles()

  return (
    <StyledBox>
      <StyledRaDatagrid optimized {...rest}>
        {
          (hasEdit || hasShow) &&
          <FunctionField
            cellClassName={editCellStyles.root}
            render={record => {
              const showEdit = isFunction(hasEdit) ? hasEdit(record) : hasEdit
              const showShow = isFunction(hasShow) ? hasShow(record) : hasShow
              return (
                <>
                  { showEdit && <StyledEditButton label={''} record={record} basePath={resource}/> }
                  { showShow && <StyledShowButton label={''} record={record} basePath={resource}/> }
                </>
              )
            }}
          />
        }
        {children}
      </StyledRaDatagrid>
    </StyledBox>
  )
}

export default Datagrid
