import {
  TextField,
  NumberField,
  useListContext,
  DateField,
  FunctionField,
  BooleanField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Datagrid } from '../../custom'
import { useLabel } from '../../../hooks'
import {
  dateWithTimeFieldOptions,
  booleanFieldOptions,
} from '../../util/component-options'
import StatusSelect from '../../shared/orders/status-select'
import { GridExpand } from '../../shared'
import TicketsSummary from '../../shared/orders/tickets-summary'
import rowStyle from '../../shared/orders/acceptance-status-row-style'
import { getMaterialAndGrade } from '../../shared/orders/util'

const Expand = (props) => (
  <GridExpand {...props} resource='tonnage_order_tickets_summaries' enablePrint={false}>
    <TicketsSummary />
  </GridExpand>
)

const useStyles = makeStyles(({ spacing }) => ({
  booleanFieldHeader: {
    textAlign: 'center',
  },
  statusSelectCell: {
    padding: spacing(0.5),
  }
}))


const TonnageOrdersDatagrid = () => {
  const context = useListContext()
  const { resource } = context
  const label = useLabel(context)
  const classes = useStyles()

  return (
    <Datagrid
      expand={<Expand />}
      rowStyle={rowStyle}
    >
      <FunctionField label={label('status')} cellClassName={classes.statusSelectCell} sortBy='status' render={record => (
        <StatusSelect record={record} resource={resource} />
      )} />
      <BooleanField source='is_created_by_customer' label={label('is_created_by_customer')} headerClassName={classes.booleanFieldHeader} {...booleanFieldOptions} />
      <DateField source='load_at' label={label('load_at')} options={dateWithTimeFieldOptions} />
      <NumberField source='num_trucks' label={label('num_trucks')} />
      <NumberField source='num_assigned_trucks' label={label('num_assigned_trucks')} />
      <NumberField source='num_remaining_trucks' label={label('num_remaining_trucks')} />
      <TextField source='order_number' label={label('order_number')} />
      <TextField source='tonnage_rate.code' sortBy='tonnage_rate_code' label={label('tonnage_rate_code')} />
      <TextField source='tonnage_rate.customer.name' sortBy='tonnage_rate_customer_name' label={label('customer')} />
      <TextField source='tonnage_rate.recipient.name' sortBy='tonnage_rate_recipient_name' label={label('recipient')} />
      <FunctionField label={label('material')} sortBy='tonnage_rate_material_name' render={record => (
        getMaterialAndGrade(record.tonnage_rate.material.name, record.material_grade)
      )} />
      <TextField source='tonnage_rate.pickup_site.name' sortBy='tonnage_rate_pickup_site_name' label={label('pickup_site')} />
      <TextField source='tonnage_rate.dropoff_site.name' sortBy='tonnage_rate_dropoff_site_name' label={label('dropoff_site')} />
    </Datagrid>
  )
}

export default TonnageOrdersDatagrid
