import { useGetList } from 'react-admin'
import { CACHE_DURATION_FILTER_KEY } from '../json-provider/cache-proxy'

const useEarliestIncompletePeriod = () => {
  const resource = 'periods'

  const { data } = useGetList(resource, {
    page: 1,
    perPage: 1
  }, {
    field: 'end_date',
    order: 'ASC',
  }, {
    is_completed_true: false,
    [CACHE_DURATION_FILTER_KEY]: 4 * 60 * 60 * 1000, // 4 hours
  })

  return Object.values(data).find(p => p.is_completed === false)
}

export default useEarliestIncompletePeriod
