import {
  useTranslate,
} from 'react-admin'
import {
  Grid,
  Button,
} from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import {
  usePrintDetail,
  usePrintSummary,
} from '../../hooks'

const PrintListButtons = ({ summary, detail, detailPrintOptions = {}, summaryPrintOptions = {} }) => {
  const translate = useTranslate()

  const [
    printDetail,
    printableDetail,
    isDetailDisabled,
  ] = usePrintDetail(detail, detailPrintOptions)

  const [
    printSummary,
    printableSummary,
    isSummaryDisabled,
  ] = usePrintSummary(summary, summaryPrintOptions)

  return (
    <>
      {
        summary &&
        <>
          <Grid item>
            <Button
              size='small'
              color='secondary'
              onClick={printSummary}
              startIcon={<PrintIcon />}
              disabled={isSummaryDisabled}
            >
              {translate('printButtons.summary')}
            </Button>
          </Grid>
          {printableSummary}
        </>
      }
      {
        detail &&
        <>
          <Grid item>
            <Button
              size='small'
              color='secondary'
              onClick={printDetail}
              startIcon={<PrintIcon />}
              disabled={isDetailDisabled}
            >
              {translate('printButtons.detail')}
            </Button>
          </Grid>
          {printableDetail}
        </>
      }
    </>
  )
}

export default PrintListButtons
