import {
  ReferenceInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete, PhoneInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { relationships } from '../../../data-model'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { useDriverLookup } from '../../form/functions'

const FormBody = props => {
  const { resource, record } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const resourceRelationships = relationships[resource]
  const isNewRecord = !record.id

  const { defaultDriver, onSelectedTruckChange } = useDriverLookup(record)

  return (
    <Box className={formClasses.grid}>
      <Box>
        <PhoneInput
          {...formInputOptions}
          source='phone'
          label={label('phone')}
          disabled={!isNewRecord}
          showHelperText={isNewRecord}
          required
        />

        <ReferenceInput
          {...referenceInputOptions(resourceRelationships['truck'])}
          {...formInputOptions}
          source='truck.id'
          reference={resourceRelationships['truck']}
          validate={getValidator(false)}
          label={label('truck')}
          onChange={onSelectedTruckChange}
        >
          <Autocomplete
            optionText='number'
            optionSubtext='default_driver.name'
            helperText={defaultDriver?.name}
          />
        </ReferenceInput>
      </Box>
    </Box>
  )
}

const ContactsForm = props => {
  return (
    <Form {...props}>
      <FormBody {...props} />
    </Form>
  )
}

export default ContactsForm
