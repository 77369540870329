import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

const useFindFromStore = (resource) => {
  const resourcesStore = useSelector(state => state.admin.resources)
  const recordStore = resourcesStore[resource].data

  const records = useMemo(() => {
    return Object.values(recordStore)
  }, [recordStore])

  return useCallback((id) => {
    if (!id) return null
    return records.find(r => r.id === id)
  }, [records])
}

export default useFindFromStore
