import {
  TextField,
  DateField,
  NumberField,
  TextInput,
  FunctionField,
  NullableBooleanInput,
} from 'react-admin'
import {
  makeStyles,
} from '@material-ui/core'
import { DateInput, Datagrid, List, Filter } from '../../custom'
import {
  numberFieldOptions,
  currencyFieldOptions,
  dateFieldOptions,
} from '../../util/component-options'
import { useLabel } from '../../../hooks'
import CompleteButton from './complete-button'
import CompleteAllButton from './complete-all-button'

const useStyles = makeStyles(({ spacing }) => ({
  cell: {
    width: 1,
    whiteSpace: 'nowrap',
    paddingLeft: spacing(0.2),
    paddingRight: spacing(0.2),
    textAlign: 'center',
  },
  button: {
    minWidth: 0,
  },
}))

const TonnageTicketsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <NullableBooleanInput label={label('is_completable', 'filter')} source={'is_completable_true'} />
      <TextInput label={label('ticket_number', 'find')} source='ticket_number_start' />
      <DateInput label={label('worked_at_date', 'start')} source='worked_at_date_gteq' />
      <DateInput label={label('worked_at_date', 'end')} source='worked_at_date_lteq' />
      <TextInput label={label('customer', 'filter')} source='customer_name_i_cont' />
      <TextInput label={label('recipient', 'filter')} source='recipient_name_i_cont' />
      <TextInput label={label('driver', 'filter')} source='driver_name_i_cont' />
      <TextInput label={label('truck', 'filter')} source='truck_number_i_cont' />
      <TextInput label={label('material', 'filter')} source='material_name_i_cont' />
      <TextInput label={label('pickup_site', 'filter')} source='pickup_site_name_i_cont' />
      <TextInput label={label('dropoff_site', 'filter')} source='dropoff_site_name_i_cont' />
    </Filter>
  )
}

const TonnageTicketsList = props => {
  const label = useLabel(props)
  const { resource } = props
  const classes = useStyles()

  return (
    <List
      {...props}
      filters={<TonnageTicketsFilter />}
      actions={<CompleteAllButton />}
    >
      <Datagrid
      >
        <FunctionField cellClassName={classes.cell} label={label('is_completable')} sortBy='is_completable' render={record => {
          return (
            record.is_completable && 
              <CompleteButton
                buttonClassName={classes.button}
                record={record}
                resource={resource}
              />
          )
        }} />
        <TextField source='ticket_number' label={label('ticket_number')} />
        <TextField source='driver.name' sortBy='driver_name' label={label('driver')} />
        <TextField source='truck.number' sortBy='truck_number' label={label('truck')} />
        <TextField source='customer.name' sortBy='customer_name' label={label('customer')} />
        <TextField source='recipient.name' sortBy='recipient_name' label={label('recipient')} />
        <NumberField source='net_weight_in_tons' label={label('net_weight_in_tons')} options={numberFieldOptions}/>
        <NumberField source='base_rate_in_dollars' label={label('base_rate_in_dollars')} options={currencyFieldOptions}/>
        <NumberField source='fuel_surcharge_in_dollars' label={label('fuel_surcharge_in_dollars')} options={currencyFieldOptions}/>
        <NumberField source='material_surcharge_in_dollars' label={label('material_surcharge_in_dollars')} options={currencyFieldOptions}/>
        <TextField source='material.name' sortBy='material_name' label={label('material')} />
        <TextField source='pickup_site.name' sortBy='pickup_site_name' label={label('pickup_site')} />
        <TextField source='dropoff_site.name' sortBy='dropoff_site_name' label={label('dropoff_site')} />
        <DateField source='worked_at_date' label={label('worked_at_date')} options={dateFieldOptions}/>
      </Datagrid>
    </List>
  )
}

export default TonnageTicketsList
