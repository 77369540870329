import React from 'react'
import { Create } from 'react-admin'
import Form from './form'

const BrokerDriversCreate = props => {
  return (
    <Create {...props} >
      <Form />
    </Create>
  )
}

export default BrokerDriversCreate
