import { statusTypeColors } from './util'
import { Grid, Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => {
  return {
    color: {
      padding: '0.36em',
      marginRight: '0.2em',
      borderRadius: '2em',
    },
  }
})

const StatusColor = ({ status, children, justify }) => {
  const classes = useStyles()

  return <Grid container wrap='nowrap' justify={justify} alignItems='center'>
    <Grid item>
      <Box className={classes.color} style={{ background: statusTypeColors[status] }} />
    </Grid>
    <Grid item>
      {children}
    </Grid>
  </Grid>
}

export default StatusColor
