import React from 'react'
import { Layout as RaLayout } from 'react-admin'
import AppBar from './app-bar'
import Menu from './menu'
import Sidebar from './sidebar'
import Notification from './notification'

const Layout = props => {
  return (
    <RaLayout
      {...props}
      appBar={AppBar}
      menu={Menu}
      sidebar={Sidebar}
      notification={Notification}
    />
  )
}

export default Layout
