import {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react'
import {
  ReferenceInput,
  TextInput,
  Link,
  useNotify,
  SelectInput,
  DataProviderContext,
} from 'react-admin'
import { Grid, Box, Typography, IconButton, CircularProgress, Link as ExternalLink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import { statusTypeChoices } from '../../shared/orders/util'
import { Autocomplete, DateTimeInput, NumberInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
  currencyFieldOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import {
  useLabel,
  useTranslateResource,
} from '../../../hooks'
import { relationships } from '../../../data-model'
import { Form, getValidator } from '../../form'
import {
  useReferenceLookup,
} from '../../form/functions'
import DispatchesFieldArray, { transform as transformDispatches } from '../../shared/orders/dispatches-field-array'

const useStyles = makeStyles(theme => {
  return {
    container: {
      padding: theme.spacing(4),
    },
    form: {
      padding: 0,
    },
    jobInfoTitle: {
      marginBottom: theme.spacing(1),
    },
    editLink: {
      fontSize: '0.9em',
      display: 'flex',
    },
    jobInfoReloader: {
      display: 'flex',
      alignItems: 'center',
    },
    jobInfoLabel: {
      ...theme.typography.body2,
      color: theme.palette.grey['600'],
      paddingBottom: theme.spacing(0.25),
      boxSizing: 'content-box',
    },
    missingLink: {
      color: theme.palette.error.main,
    },
    routeText: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    iframeWrap: {
      padding: theme.spacing(2)
    },
    iframe: {
      border: 0,
    },
  }
})

const FormBody = props => {
  const { onRateIdChange, onLocationIdChange, onHourlyWorkTypeIdChange, ...restProps } = props
  const { resource, record } = restProps
  const isNewRecord = !record?.id
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(restProps)
  const label = useLabel(restProps)
  const translate = useTranslateResource(resource, 'form.general')
  const labelBrokerDriver = useLabel({ resource: 'broker_drivers' })
  const { selectedRecord: rate, onSelectedIdChange: onSelectedRateIdChange } = useReferenceLookup(resourceRelationships.hourly_rate, record.hourly_rate)
  const { selectedRecord: location, onSelectedIdChange: onSelectedLocationIdChange } = useReferenceLookup(resourceRelationships.location, record.location)
  const { selectedRecord: hourly_work_type, onSelectedIdChange: onSelectedHourlyWorkTypeIdChange } = useReferenceLookup(resourceRelationships.hourly_work_type, record.hourly_work_type)

  const selectedRateText = useMemo(() => {
    if (rate && !rate.description) {
      return translate('selectedRateWithoutCode')
    }
  }, [rate, translate])

  const rateFieldOpts = useMemo(() => {
    const opts = referenceInputOptions(resourceRelationships.hourly_rate)
    return {
      ...opts,
      filterToQuery: v => ({ description_i_cont: v }),
    }
  }, [resourceRelationships])

  const locationFieldOpts = useMemo(() => {
    const opts = referenceInputOptions(resourceRelationships.location)
    return {
      ...opts,
      filterToQuery: v => ({ name_i_cont: v }),
    }
  }, [resourceRelationships])

  const hourlyWorkTypeFieldOpts = useMemo(() => {
    const opts = referenceInputOptions(resourceRelationships.hourly_work_type)
    return {
      ...opts,
      filterToQuery: v => ({ name_i_cont: v }),
    }
  }, [resourceRelationships])

  const initialFocusedDate = useMemo(() => {
    const rolloverHour = 7
    const date = new Date()
    const currentHours = date.getHours()
    if (currentHours >= rolloverHour) {
      date.setDate(date.getDate() + 1)
    }
    date.setMinutes(0)
    date.setHours(rolloverHour)
    date.setSeconds(0)
    return date
  }, [])

  const getRateOptionSubtext = useCallback(option => {
    return [
      option.customer.name,
      Intl.NumberFormat('en-US', currencyFieldOptions).format(option.regular_rate_in_dollars),
    ].join(' / ')
  }, [])

  const getBrokerMeta = useCallback(broker => {
    if (broker) {
      const formatter = new Intl.NumberFormat('en-US', currencyFieldOptions)
      if (broker.hourly_work_pay_type === 'hourly_rate') {
        return [
          `${labelBrokerDriver('hourly_regular_rate_in_dollars')}:`,
          formatter.format(broker.hourly_regular_rate_in_dollars),
        ].join(' ')
      } else {
        return [
          `${labelBrokerDriver('hourly_work_deduction_in_dollars')}:`,
          formatter.format(broker.hourly_work_deduction_in_dollars),
        ].join(' ')
      }
    }
  }, [labelBrokerDriver])

  useEffect(() => {
    onRateIdChange(rate?.id)
  }, [rate?.id, onRateIdChange])

  useEffect(() => {
    onLocationIdChange(location?.id)
  }, [location?.id, onLocationIdChange])

  useEffect(() => {
    onHourlyWorkTypeIdChange(hourly_work_type?.id)
  }, [hourly_work_type?.id, onHourlyWorkTypeIdChange])

  return (
    <Box className={formClasses.grid}>
      <Box>
        <ReferenceInput
          {...formInputOptions}
          {...rateFieldOpts}
          source='hourly_rate.id'
          reference={resourceRelationships.hourly_rate}
          label={translate('lookupRate')}
          onChange={onSelectedRateIdChange}
          required
          autoFocus
          inputProps={{
            ...formInputOptions.inputProps,
          }}
        >
          <Autocomplete
            validate={getValidator()}
            optionText='description'
            getOptionSubtext={getRateOptionSubtext}
            helperText={selectedRateText}
            useServerToFilter
            preserveChoicesOnLoading
          />
        </ReferenceInput>

        <ReferenceInput
          {...formInputOptions}
          {...locationFieldOpts}
          source='location.id'
          reference={resourceRelationships.location}
          label={label('location')}
          onChange={onSelectedLocationIdChange}
          required
          inputProps={{
            ...formInputOptions.inputProps,
          }}
        >
          <Autocomplete
            validate={getValidator()}
            useServerToFilter
            preserveChoicesOnLoading
          />
        </ReferenceInput>

        <ReferenceInput
          {...formInputOptions}
          {...hourlyWorkTypeFieldOpts}
          source='hourly_work_type.id'
          reference={resourceRelationships.hourly_work_type}
          label={label('hourly_work_type')}
          onChange={onSelectedHourlyWorkTypeIdChange}
          inputProps={{
            ...formInputOptions.inputProps,
          }}
        >
          <Autocomplete
            validate={getValidator(false)}
            useServerToFilter
            preserveChoicesOnLoading
          />
        </ReferenceInput>

        <DateTimeInput
          {...formInputOptions}
          validate={getValidator()}
          source='arrive_at'
          label={label('arrive_at')}
          initialFocusedDate={initialFocusedDate}
          required
        />

        <TextInput
          {...formInputOptions}
          source='order_number'
          label={label('order_number')}
          validate={getValidator(false)}
        />

        <NumberInput
          {...formInputOptions}
          source='num_trucks'
          min={0}
          label={label('num_trucks')}
          validate={getValidator(false)}
        />

        {
          !isNewRecord &&
          <SelectInput
            {...formInputOptions}
            source='status'
            label={label('status')}
            choices={statusTypeChoices}
          />
        }
      </Box>
      <Box>
        <DispatchesFieldArray parentResource={resource} getBrokerMeta={getBrokerMeta} record={record}/>
      </Box>
    </Box>
  )
}

const formatAddress = (record) => {
  const { address_street_1, address_street_2, address_united_state_abbreviation, address_city, address_zip } = record
  return [
    address_street_1 ? `${address_street_1},` : null,
    address_street_2 ? `${address_street_2},` : null,
    address_city ? `${address_city},` : null,
    address_united_state_abbreviation,
    address_zip,
  ].filter(Boolean).join(' ')
}

const PinLink = ({ pinLink, resource }) => {
  const translateSite = useTranslateResource('sites', 'fieldNames')
  const translateForm = useTranslateResource(resource, 'form.general')
  const classes = useStyles()

  return pinLink ?
    <ExternalLink target='_blank' tabIndex='-1' href={pinLink}>
      {translateSite('google_maps_pin_link')}
    </ExternalLink> :
    <Typography className={classes.missingLink} variant={'body2'}>{translateForm('missingPinLink')}</Typography>
}

const JobInfo = ({ isLoading, rate, location, hourly_work_type, onRefreshClick, resource }) => {
  const labelResource = useLabel({ resource })
  const labelRate = useLabel({ resource: 'hourly_rates' })
  const resourceRelationships = relationships[resource]
  const rateResourceRelationships = relationships['hourly_rates']
  const translateForm = useTranslateResource(resource, 'form.general')
  const classes = useStyles()

  const items = useMemo(() => {
    let result = []

    if (rate) {
      const rateLink = `/${resourceRelationships.hourly_rate}/${rate.id}`

      result = [
        ...result,
        {
          label: labelResource('hourly_rate_code'),
          link: rateLink,
          value:
            <>
              <Typography variant={'body1'}>{rate.description}</Typography>
            </>
        },
        {
          label: labelRate('regular_rate_in_dollars'),
          link: rateLink,
          value:
            <>
              <Typography variant={'body1'}>{Intl.NumberFormat('en-US', currencyFieldOptions).format(rate.regular_rate_in_dollars)}</Typography>
            </>
        },
        {
          label: labelRate('overtime_rate_in_dollars'),
          link: rateLink,
          value:
            <>
              <Typography variant={'body1'}>{Intl.NumberFormat('en-US', currencyFieldOptions).format(rate.overtime_rate_in_dollars)}</Typography>
            </>
        },
        {
          label: labelRate('doubletime_rate_in_dollars'),
          link: rateLink,
          value:
            <>
              <Typography variant={'body1'}>{Intl.NumberFormat('en-US', currencyFieldOptions).format(rate.doubletime_rate_in_dollars)}</Typography>
            </>
        },
        {
          label: labelRate('customer'),
          link: `/${rateResourceRelationships.customer}/${rate.customer.id}`,
          value:
            <>
              <Typography variant={'body1'}>{rate.customer.name}</Typography>
            </>
        },
      ]
    }

    if (hourly_work_type) result = [
      ...result,
      {
        label: labelResource('hourly_work_type'),
        link: `/${resourceRelationships.hourly_work_type}/${hourly_work_type.id}`,
        value:
          <>
            <Typography variant={'body1'}>{hourly_work_type.name}</Typography>
          </>
      },
    ]

    if (location) result = [
      ...result,
      {
        label: labelResource('location'),
        link: `/${resourceRelationships.location}/${location.id}`,
        value:
          <>
            <Typography variant={'body1'}>{location.name}</Typography>
            <Typography variant={'body2'}>{formatAddress(location)}</Typography>
            <PinLink pinLink={location.google_maps_pin_link} resource={resource} />
          </>
      },
    ]

    return result
  }, [rate, location, hourly_work_type, resourceRelationships, rateResourceRelationships, resource, labelResource, labelRate])

  return (
    <Box>
      <Grid container className={classes.jobInfoTitle} spacing={1} alignItems='center'>
        <Grid item>
          <Typography>
            {translateForm('jobInfo')}
          </Typography>
        </Grid>
        <Grid item className={classes.jobInfoReloader}>
          {
            isLoading ?
            <CircularProgress color='inherit' size={18} style={{margin: 4}} /> :
            <IconButton size='small' tabIndex='-1' onClick={onRefreshClick} style={{ visibility: rate ? 'visible' : 'hidden' }}>
              <RefreshIcon fontSize='small'/>
            </IconButton>
          }
        </Grid>
      </Grid>
      <Box style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        {
          items.length ?
          <Grid container spacing={2} direction='column'>
            {
              items.map((item, idx) => (
                <Grid item key={idx}>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                      <Typography className={classes.jobInfoLabel}>
                        {item.label}
                      </Typography>
                    </Grid>
                    {
                      item.link &&
                      <Grid item>
                        <Link target='_blank' tabIndex='-1' className={classes.editLink} to={item.link} >
                          {translateForm('edit')}
                        </Link>
                      </Grid>
                    }
                  </Grid>
                  {item.value}
                </Grid>
              ))
            }
          </Grid> :
          <Typography variant='body2'>
            {translateForm('noJobInfo')}
          </Typography>
        }
      </Box>
    </Box>
  )
}

const LocationMap = ({ location, resource }) => {
  const resourceRelationships = relationships[resource]
  const translate = useTranslateResource(resourceRelationships.location, 'fieldNames')
  const classes = useStyles()

  const mapSrc = useMemo(() => {
    const lat = location?.lat
    const long = location?.long
    if (![lat, long].every(Boolean)) return null
    return `https://maps.google.com/maps?q=${lat},${long}&hl=en&z=10&output=embed`
  }, [location])

  if (!mapSrc) return null

  return (
    <Box className={classes.iframeWrap}>
      <iframe
        title={translate('google_maps_pin_link')}
        className={classes.iframe}
        width='100%'
        height='600'
        loading='lazy'
        src={mapSrc}
      />
    </Box>
  )
}

// wrap component so we can use useForm hook
const HourlyOrdersForm = props => {
  const { resource, record } = props
  const resourceRelationships = relationships[resource]
  const [selectedRateId, setSelectedRateId] = useState(record.hourly_rate?.id)
  const [selectedLocationId, setSelectedLocationId] = useState(record.location?.id)
  const [selectedHourlyWorkTypeId, setSelectedHourlyWorkTypeId] = useState(record.hourly_work_type?.id)
  const translate = useTranslateResource(resource, 'form')
  const classes = useStyles()
  const dataProvider = useContext(DataProviderContext)
  const [rate, setRate] = useState(null)
  const [location, setLocation] = useState(null)
  const [hourly_work_type, setHourlyWorkType] = useState(null)
  const [isLoadingRate, setIsLoadingRate] = useState(false)
  const [isLoadingLocation, setIsLoadingLocation] = useState(false)
  const [isLoadingHourlyWorkType, setIsLoadingHourlyWorkType] = useState(false)
  const notify = useNotify()

  const isLoading = isLoadingRate || isLoadingLocation || isLoadingHourlyWorkType

  const transform = useCallback(data => {
    return {
      ...data,
      ...transformDispatches(record, data)
    }
  }, [record])

  const fetchResource = useCallback(async (_resource, id) => {
    let data = null
    try {
      const resp = await dataProvider.getOne(resourceRelationships[_resource], { id })
      data = resp.data
    } catch(e) {
      notify(`resources.${resource}.form.general.jobInfoFetchError`, 'error')
    }
    return data
  }, [dataProvider, resourceRelationships, resource, notify])

  const getRate = useCallback(async () => {
    let data = null
    if (selectedRateId) {
      setIsLoadingRate(true)
      data = await fetchResource('hourly_rate', selectedRateId)
    }
    setRate(data)
    setIsLoadingRate(false)
  }, [selectedRateId, fetchResource])

  const getLocation = useCallback(async () => {
    let data = null
    if (selectedLocationId) {
      setIsLoadingLocation(true)
      data = await fetchResource('location', selectedLocationId)
    }
    setLocation(data)
    setIsLoadingLocation(false)
  }, [selectedLocationId, fetchResource])

  const getHourlyWorkType = useCallback(async () => {
    let data = null
    if (selectedHourlyWorkTypeId) {
      setIsLoadingHourlyWorkType(true)
      data = await fetchResource('hourly_work_type', selectedHourlyWorkTypeId)
    }
    setHourlyWorkType(data)
    setIsLoadingHourlyWorkType(false)
  }, [selectedHourlyWorkTypeId, fetchResource])

  useEffect(() => {
    getRate()
  }, [getRate])

  useEffect(() => {
    getLocation()
  }, [getLocation])

  useEffect(() => {
    getHourlyWorkType()
  }, [getHourlyWorkType])

  const onRefreshJobClick = useCallback(() => {
    getRate()
    getLocation()
    getHourlyWorkType()
  }, [getRate, getLocation, getHourlyWorkType])

  return (
    <Box>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={8}>
          <Form
            {...props}
            transform={transform}
            className={classes.form}
            infoText={translate('info.formHelper')}
          >
            <FormBody
              {...props}
              onRateIdChange={setSelectedRateId}
              onLocationIdChange={setSelectedLocationId}
              onHourlyWorkTypeIdChange={setSelectedHourlyWorkTypeId}
            />
          </Form>
        </Grid>
        <Grid item xs={4}>
          <JobInfo
            rate={rate}
            location={location}
            hourly_work_type={hourly_work_type}
            isLoading={isLoading}
            onRefreshClick={onRefreshJobClick}
            resource={resource}
          />
        </Grid>
      </Grid>
      <LocationMap location={location} resource={resource} />
    </Box>
  )
}

export default HourlyOrdersForm
