import { Create } from 'react-admin'
import Form from './form'

const BrokerDriversUsersCreate = props => {
  return (
    <Create {...props} >
      <Form />
    </Create>
  )
}

export default BrokerDriversUsersCreate
