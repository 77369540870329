import React from 'react'
import {
  TextInput,
} from 'react-admin'
import CollectionData from './collection-data'
import Datagrid from './datagrid'
import { withCollectionData } from '../../hoc'
import { List, Filter } from '../../custom'
import { useLabel } from '../../../hooks'

const DatagridWithTotals = withCollectionData(Datagrid, CollectionData)

const BrokerDriversFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('name', 'filter')} source='name_i_cont' />
    </Filter>
  )
}

const BrokerDriversList = props => {
  return (
    <List
      {...props}
      filters={<BrokerDriversFilter />}
      filter={{ include_collection_data: true }}
    >
      <DatagridWithTotals />
    </List>
  )
}

export default BrokerDriversList
