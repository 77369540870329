import { useCallback } from 'react'
import {
  TextInput,
  ReferenceInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { relationships } from '../../../data-model'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'

const CustomersUsersForm = props => {
  const { resource, record } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const resourceRelationships = relationships[resource]
  const recordId = record.id
  const isNewRecord = !recordId

  const transform = useCallback(data => {
    const result = {
      id: data.id,
      customer_id: data.customer.id,
    }

    if (isNewRecord) {
      result.user_attributes = {
        email: data.user.email,
      }
    }

    return result
  }, [isNewRecord])


  return (
    <Form
      {...props}
      transform={transform}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='user.email'
            label={label('user.email')}
            validate={getValidator()}
            disabled={!isNewRecord}
            required
          />

          {
            !isNewRecord && record.user.temp_password &&
            <TextInput
              {...formInputOptions}
              source='user.temp_password'
              label={label('user.temp_password')}
              disabled
            />
          }

          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['customer'])}
            {...formInputOptions}
            source='customer.id'
            reference={resourceRelationships['customer']}
            label={label('customer.name')}
          >
            <Autocomplete
              validate={getValidator()}
              required
            />
          </ReferenceInput>
        </Box>
      </Box>
    </Form>
  )
}

export default CustomersUsersForm
