import {
  TextField,
  FunctionField,
  useCreateController,
  CreateContextProvider,
  useTranslate,
} from 'react-admin'
import {
  makeStyles,
} from '@material-ui/core'
import { Datagrid } from '../custom'
import { useLabel, useTranslateResource } from '../../hooks'
import { capitalize } from '../../util'
import CompleteButton from './complete-button'
import TonnageRatesForm from '../../components/resources/tonnage-rates/form'

const useStyles = makeStyles(({ spacing }) => ({
  cell: {
    width: 1,
    whiteSpace: 'nowrap',
    paddingLeft: spacing(0.2),
    paddingRight: spacing(0.2),
  },
  button: {
    minWidth: 0,
  },
}))

const CompleteResource = (props) => {
  const { record, onSuccess, resource, reference, ResourceFormComponent, ...rest } = props
  const translate = useTranslate()

  const createReferenceFormContext = useCreateController({
    basePath: `/${resource}`,
    resource,
    record,
  })

  return (
    <CreateContextProvider value={createReferenceFormContext}>
      <ResourceFormComponent
        {...rest}
        {...createReferenceFormContext}
        saveButtonLabel={translate('resources.pending_resources.form.actions.createNew')}
        onUpdateSuccess={onSuccess}
        isDeleteDisabled
      />
    </CreateContextProvider>
  )
}

const TonnageRatesList = () => {
  const reference = 'tonnage_rates'
  const resource = 'pending_tonnage_rates'
  const label = useLabel({ resource })
  const classes = useStyles()
  const translateResource = useTranslateResource(reference)
  const translate = useTranslate()

  return (
    <Datagrid hasEdit={false}>
      <FunctionField cellClassName={classes.cell} render={record => {
        return <CompleteButton
          buttonClassName={classes.button}
          record={record}
          resource={resource}
          reference={reference}
          dialogTitle={`${translate('ra.action.create')} ${capitalize(translateResource('name', { smart_count: 1 }))}`}
          DialogContentComponent={CompleteResource}
          ResourceFormComponent={TonnageRatesForm}
        />
      }} />
      <TextField source='customer.name' label={label('customer')} sortBy='customer_name' />
      <TextField source='recipient.name' label={label('recipient')} sortBy='recipient_name' />
      <TextField source='pickup_site.name' label={label('pickup_site')} sortBy='pickup_site_name' />
      <TextField source='dropoff_site.name' label={label('dropoff_site')} sortBy='dropoff_site_name' />
      <TextField source='material.name' label={label('material')} sortBy='material_name' />
    </Datagrid>
  )
}

export default TonnageRatesList
