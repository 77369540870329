import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useAutofillFields from './use-autofill-fields'

const useDriverLookup = (record, shouldAutofill) => {
  const [selectedTruckId, setSelectedTruckId] = useState(record.truck?.id)
  const resourcesStore = useSelector(state => state.admin.resources)
  const trucksStore = resourcesStore.trucks.data

  const trucks = useMemo(() => {
    return Object.values(trucksStore)
  }, [trucksStore])

  const defaultDriver = useMemo(() => {
    return trucks.find(t => t.id === selectedTruckId)?.default_driver
  }, [trucks, selectedTruckId])

  const autofillFields = useMemo(() => {
    return ['driver.id']
  }, [])

  const autofillSource = useMemo(() => {
    return { driver: defaultDriver }
  }, [defaultDriver])

  const [getIsAutofilled, onAutofillFieldChange] = useAutofillFields(autofillFields, autofillSource, shouldAutofill)

  return {
    defaultDriver,
    onSelectedTruckChange: setSelectedTruckId,
    getIsDriverFieldIsAutofilled: getIsAutofilled,
    onDriverFieldAutofillChange: onAutofillFieldChange,
  }
}

export default useDriverLookup
