import { NumberField, useListContext } from 'react-admin'
import { CollectionDataTable } from '..'
import { useLabel } from '../../../hooks'

const CollectionData = props => {
  const context = useListContext()
  const label = useLabel(context)

  return (
    <CollectionDataTable {...props}>
      <NumberField source='num_trucks' label={label('num_trucks')} />
      <NumberField source='num_remaining_trucks' label={label('num_remaining_trucks')} />
      <NumberField source='num_pending_trucks' label={label('num_pending_trucks')} />
      <NumberField source='num_active_trucks' label={label('num_active_trucks')} />
      <NumberField source='num_available_trucks' label={label('num_available_trucks')} />
      <NumberField source='num_unassigned_trucks' label={label('num_unassigned_trucks')} />
    </CollectionDataTable>
  )
}

export default CollectionData
