export default {
  views: {
    dispatchMap: {
      name: 'dispatch map',
      legend: {
        pickup: 'Pickup',
        dropoff: 'Dropoff',
        pickupAndDropoff: 'Pickup & Dropoff',
        position: 'Driver',
      },
      orders: {
        title: '%{smart_count} order |||| %{smart_count} orders',
        showing: '(showing %{showing_count})',
        selected: '(map filtered)',
        assignedTrucks: '%{smart_count} truck |||| %{smart_count} trucks',
        view: 'view order',
      },
    },
  },
}
