import {
  Children,
  cloneElement
} from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { DatagridHeaderCell, DatagridCell } from 'react-admin'
import classnames from 'classnames'
import set from 'lodash/set'
import get from 'lodash/get'

const useStyles = makeStyles(({ spacing }) => {
  return {
    table: {
      tableLayout: 'fixed',
    },
    headerCell: {
      fontWeight: 'bold',
      borderBottomWidth: 0,
      padding: `${spacing(1)}px ${spacing(3)}px`,

    },
    rowCell: {
      padding: `${spacing(1)}px ${spacing(3)}px`,
      borderWidth: 1,
      borderBottomWidth: 0,
      borderTopStyle: 'solid',
      borderTopColor: 'lightgray',
    },
    rowCellContent: {
      fontWeight: 'bold',
    },
  }
})

const datagridCellDefaults = {
  currentSort: {},
  isSorting: false,
  updateSort: () => null,
}

const cellPlaceholderValue = '--'

const DataTable = ({
  children,
  data: propsData,
  className,
  tableClassName,
  cellContentClassName,
}) => {
  const data = Array.isArray(propsData) ? propsData : [propsData]
  const classes = useStyles()

  const dataElements = Children.map(children, c => {
    const nextProps = Object.assign({},
    c.props, {
      className: classnames(classes.rowCellContent, c.props.className, cellContentClassName),
    }, c.props.forceZero ? { forceZero: false } : null)
    return cloneElement(c, nextProps)
  })

  const headersElements = Children.map(children, c => cloneElement(c, {
    ...c.props,
    sortable: false,
    className: classnames(classes.headerCell, c.props.className)
  }))

  const getCellRecord = (item, field) => {
    const record = {...item}
    const { props: { source } } = field
    if (get(record, source) == null) {
      set(record, field.props.source, cellPlaceholderValue)
    }
    return record
  }

  return (
    <div className={className}>
      <Table className={classnames(classes.table, tableClassName)}>
        <TableHead>
          <TableRow>
            {
              headersElements.map((field, index) => (
                <DatagridHeaderCell
                  {...datagridCellDefaults}
                  className={classes.headerCell}
                  field={field}
                  key={index}
                />
              ))
            }
          </TableRow>
        </TableHead>
          {
            <TableBody>
              {
                data.map((item, itemIndex) => {
                  return (
                    <TableRow key={itemIndex}>
                      {
                        dataElements.map((field, index) => {
                          return (
                            <DatagridCell
                              className={classes.rowCell}
                              key={index}
                              field={field}
                              record={getCellRecord(item, field)}
                            />
                          )
                        })
                      }
                    </TableRow>
                  )
                })
              }
            </TableBody>
          }
      </Table>
    </div>
  )
}

export default DataTable
