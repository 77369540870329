import React, { Children, useCallback, useEffect } from 'react'
import {
  FormWithRedirect,
  SaveButton,
  DeleteButton,
  useRedirect,
  useMutation,
  useEditContext,
  useRefresh,
  Loading as RaLoading,
} from 'react-admin'
import classnames from 'classnames'
import ClearButton from './clear-button'
import ToggleActiveButton from './toggle-active-button'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip, IconButton, Grid } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { useNotifyResponse, useEnterToTab } from './functions/'
import { formOptions } from '../util/component-options'
import { useFormStyles } from '../../styles'
import { useNonce, useSoundEffect, useDeleteConfirmText } from '../../hooks'
import errorSound from '../../audio/error.mp3'
import { relationships } from '../../data-model'
import { CUSTOM_MUTATION } from '../../actions'

const InfoTooltip = withStyles(theme => {
  return {
    tooltip: {
      ...theme.typography.caption
    }
  }
})(Tooltip)

const ToolbarGrid = withStyles(theme => {
  return {
    root: {
      marginTop: theme.spacing(4)
    }
  }
})(Grid)

const Loading = withStyles(() => {
  return {
    container: {
      marginTop: 0,
      height: '28vh',
    },
  }
})(RaLoading)

const transformErrors = (resource, error) => {
  // Transform errors to format form can map to fields.
  // Handle nested resource errors (i.e. accepts_nested_attributes)
  const { body } = error
  const { attributeErrors = {} } = body

  const getErrorValue = (_resource, attr, errors) => {
    const joinedErrors = errors.join(', ').trim()
    return relationships[_resource]?.hasOwnProperty(attr) ? { id: joinedErrors } : joinedErrors
  }

  const attrRgx = /(?:(?<nestedResource>.+?)(?:\[(?<index>\d+)\])?\.)?(?<attr>.+)/
  return Object.entries(attributeErrors).reduce((accum, [_attr, errors]) => {
    const { nestedResource, index, attr } = _attr.match(attrRgx).groups
    const next = { ...accum }

    if (nestedResource) {
      if (index != null) {
        next[nestedResource] = [...(next[nestedResource] || [])]
        next[nestedResource][index] = {
          ...next[nestedResource][index],
          [attr]: getErrorValue(nestedResource, attr, errors)
        }
      } else {
        next[nestedResource] = { [attr]: getErrorValue(nestedResource, attr, errors) }
      }
    } else {
      next[attr] = getErrorValue(resource, attr, errors)
    }

    return next
  }, {})
}

const formHOC = (Component) => {
  return props => {
    const {
      basePath,
      resource,
      record,
    } = props

    const {
      transform,
      onUpdateSuccess,
      useOptimisticSave = false,
      ...restProps
    } = props

    const recordId = record.id
    const isNewRecord = !recordId
    const isOptimisticSave = useOptimisticSave && isNewRecord
    const redirect = useRedirect()
    const [mutate] = useMutation()
    const playErrorSound = useSoundEffect(errorSound)

    // Use as nonce key for component to force a reset
    // without refreshing data store (i.e. via 'useRefresh' RA hook)
    const [formKey, resetFormKey] = useNonce()

    const {
      notifySuccess,
      notifyDeleteSuccess,
      notifyFailure,
      alertOptimisticFailure,
    } = useNotifyResponse(restProps)

    const onSuccess = useCallback(() => {
      if (!isNewRecord) {
        onUpdateSuccess ? onUpdateSuccess() : redirect('list', basePath)
      } else if (!isOptimisticSave) {
        resetFormKey()
      }
      notifySuccess()
    }, [basePath, isOptimisticSave, resetFormKey, notifySuccess, onUpdateSuccess, redirect, isNewRecord])

    const save = useCallback(async (rawData) => {
      if (isOptimisticSave) resetFormKey()

      const data = transform ? transform(rawData) : rawData

      try {
        await mutate({
          resource,
          type: isNewRecord ? 'create' : 'update',
          payload: isNewRecord ? {
            data
          } : {
            data,
            id: data.id
          },
        }, {
          returnPromise: true,
          action: CUSTOM_MUTATION,
        })
        onSuccess()
      } catch (error) {
        playErrorSound()
        if (isOptimisticSave) {
          alertOptimisticFailure(data)
        } else {
          notifyFailure(error)

          // Return value from async save sets form submitErrors
          // to be shown beneath appropriate field.
          return transformErrors(resource, error)
        }
      }
    }, [
      mutate,
      resource,
      onSuccess,
      notifyFailure,
      resetFormKey,
      isNewRecord,
      isOptimisticSave,
      playErrorSound,
      alertOptimisticFailure,
      transform,
    ])

    const refresh = useRefresh()

    const onDeleteSuccess = useCallback(() => {
      notifyDeleteSuccess()
      redirect('list', basePath)
    }, [basePath, redirect, notifyDeleteSuccess])

    const onActivateToggleSuccess = useCallback(() => {
      notifySuccess()
      // Prev state
      record.is_active ? redirect('list', basePath) : refresh()
    }, [refresh, record, redirect, basePath, notifySuccess])

    const onSubmit = useCallback(formProps => {
      const { valid, handleSubmitWithRedirect, hasSubmitErrors } = formProps
      handleSubmitWithRedirect()
      if (!valid && !hasSubmitErrors) {
        playErrorSound()
      }
    }, [playErrorSound])

    useEffect(() => () => {
      // Reset form on cleanup effect if recordId change i.e. to handle route change
      resetFormKey()
    }, [recordId, resetFormKey])

    const { loading } = useEditContext(props)

    if (loading) return <Loading loadingSecondary={''} loadingPrimary={''} />

    return (
      <Component
        {...restProps}
        save={save}
        formKey={formKey}
        onDeleteSuccess={onDeleteSuccess}
        onDeleteFailure={notifyFailure}
        onActivateToggleFailure={notifyFailure}
        onActivateToggleSuccess={onActivateToggleSuccess}
        onSubmit={onSubmit}
      />
    )
  }
}

const formSubscription = {
  hasSubmitErrors: true,
  pristine: true,
  valid: true,
  submitting: true,
}

const Form = props => {
  const {
    infoText,
    isUpdateDisabled = false,
    isDeleteDisabled = false,
    save,
    formKey,
    children,
    onDeleteFailure,
    onDeleteSuccess,
    onActivateToggleFailure,
    onActivateToggleSuccess,
    onSubmit,
    saveButtonLabel,
    className,
    formActions,
    ...restProps
  } = props

  const { resource, record } = props
  const formClasses = useFormStyles(restProps)
  const { onKeyDown, saveButtonRef, formRef } = useEnterToTab()

  const deleteConfirmText = useDeleteConfirmText({ record, resource })

  return (
    <FormWithRedirect
      {...formOptions}
      {...restProps}
      key={formKey}
      save={save}
      subscription={formSubscription}
      render={formProps => {
        return (
          <form
            className={classnames(formClasses.form, className)}
            ref={formRef}
            autoComplete='off'
            onKeyDown={onKeyDown}
          >
            { children }
            {
              !(isUpdateDisabled && isDeleteDisabled) &&
              <ToolbarGrid container justify='space-between' alignItems='center'>
                <Grid item>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                      {
                        !isUpdateDisabled &&
                        <SaveButton
                          innerRef={saveButtonRef}
                          saving={formProps.submitting}
                          disabled={!formProps.valid || formProps.record.is_active === false}
                          handleSubmitWithRedirect={onSubmit.bind(null, formProps)}
                          label={saveButtonLabel != null ? saveButtonLabel : (formProps.record.id ? 'ra.action.update' : 'ra.action.create')}
                        />
                      }
                    </Grid>
                    {
                      infoText &&
                      <Grid item>
                        <InfoTooltip title={infoText}>
                          <IconButton tabIndex='-1'>
                            <InfoIcon />
                          </IconButton>
                        </InfoTooltip>
                      </Grid>
                    }
                    {
                      formActions &&
                      Children.map(formActions, (child, idx) => (
                        <Grid item key={idx}>
                          {child}
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container spacing={2} alignItems='center'>
                    {
                      formProps.record.is_active != null &&
                      <Grid item>
                        <ToggleActiveButton
                          resource={resource}
                          record={formProps.record}
                          onSuccess={onActivateToggleSuccess}
                          onFailure={onActivateToggleFailure}
                        />
                      </Grid>
                    }
                    {
                      <Grid item>
                        {
                          !isDeleteDisabled &&
                          <DeleteButton
                            tabIndex='-1'
                            confirmTitle={deleteConfirmText}
                            mutationMode='pessimistic'
                            record={formProps.record}
                            onSuccess={onDeleteSuccess}
                            onFailure={onDeleteFailure}
                          />
                        }
                        {!formProps.record.id && <ClearButton/>}
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </ToolbarGrid>
            }
          </form>
        )
      }}
    />
  )
}

export default formHOC(Form)
