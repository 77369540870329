const oneToMany = (previousState = {}, data) => {
  const { type } = data

  if (type === 'RA/CRUD_GET_MANY_REFERENCE_SUCCESS') {
    const {
      payload: { collectionData },
      meta: { resource },
      requestPayload: { id, target, filter },
    } = data

    const name = key(resource, id, target, filter)

    return {
      ...previousState,
      [name]: collectionData
    }
  }
  
  return previousState
}

export default oneToMany

export const key = (reference, id, target, filter = {}) => {
  return `${reference}-${target}-${id}-${JSON.stringify(filter)}`
}
