import { useCallback } from 'react'
import { useAddressInputFields } from '../../../hooks'
import { useForm } from 'react-final-form'

const useAddressFill = (addressPrefix) => {
  const addressInputFields = useAddressInputFields(addressPrefix)
  const { change: changeFormField, resetFieldState } = useForm()

  return useCallback((data) => {
    addressInputFields.forEach(field => {
      if (field.required) {
        changeFormField(field.source, data[field.addressSource])
        resetFieldState(field.source)
      }
    });
    ['lat', 'long'].forEach(field => {
      changeFormField(field, data[field])
      resetFieldState(field)
    })
  }, [changeFormField, addressInputFields, resetFieldState])
}

export default useAddressFill
