import { useCallback } from 'react'
import {
  useTranslate,
  TextField,
  DeleteButton as RaDeleteButton,
  useRefresh,
} from 'react-admin'
import { ListBase, ListHeader, Datagrid } from '../custom'
import { useLabel, useDeleteConfirmText } from '../../hooks'
import {
  Card,
  CardContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { sorts } from '../../data-model'
import { useNotifyResponse } from '../form/functions'

const useStyles = makeStyles(({ spacing }) => {
  return {
    root: {
      marginTop: spacing(3),
    },
    cell: {
      width: 1,
      whiteSpace: 'nowrap',
      paddingLeft: spacing(0.2),
      paddingRight: spacing(0.2),
    },
    button: {
      minWidth: 0,
    },
  }
})

const DeleteButton = (props) => {
  const { resource, record, cellClassName, ...rest } = props
  const {
    notifyDeleteSuccess,
    notifyFailure,
  } = useNotifyResponse({ record, resource })

  const refresh = useRefresh()
  const deleteConfirmTitleText = useDeleteConfirmText({ record, resource })

  const onDeleteSuccess = useCallback(() => {
    notifyDeleteSuccess()
    refresh()
  }, [refresh, notifyDeleteSuccess])

  return (
    <RaDeleteButton
      {...rest}
      tabIndex='-1'
      mutationMode='pessimistic'
      label={''}
      redirect={false}
      confirmTitle={deleteConfirmTitleText}
      record={record}
      resource={resource}
      onSuccess={onDeleteSuccess}
      onFailure={notifyFailure}
    />
  )
}

const EmbeddedExtractionMappingsList = ({ children, ...rest }) => {
  const label = useLabel({ resource: 'extraction_mappings'})
  const translate = useTranslate()
  const title = translate('resources.extraction_mappings.name', { smart_count: 2 })
  const classes = useStyles()
  const { resource } = rest

  return (
    <Card className={classes.root}>
      <CardContent>
        <ListBase
          {...rest}
          sort={sorts[resource]}
          includeHeader={false}
        >
          <ListHeader title={title} />
          <Datagrid hasEdit={false}>
            <DeleteButton cellClassName={classes.cell} className={classes.button} />
            <TextField source='text' label={label('text')} />
            <TextField source='ticket_vendor.name' label={label('ticket_vendor')} sortBy='ticket_vendor_name' />
          </Datagrid>
        </ListBase>
      </CardContent>
    </Card>
  )
}

export default EmbeddedExtractionMappingsList
