import { useMemo} from 'react'
import {
  ReferenceInput,
} from 'react-admin'
import {
  referenceInputOptions,
  formInputOptions,
} from '../util/component-options'
import { useDriverLookup, getValidator } from './functions'
import { Autocomplete } from '../custom'
import { relationships } from '../../data-model'
import {
  useLabel,
  useTranslateResource,
} from '../../hooks'

const truckReferenceInputOptions = {
  filterToQuery: term => ({ number_i_cont: term }),
}

const validate = getValidator()

const TruckDriverFields = props => {
  const { record, resource, truckHelperText, shouldAutofill } = props
  const { defaultDriver, onSelectedTruckChange, onDriverFieldAutofillChange } = useDriverLookup(record, shouldAutofill)
  const resourceRelationships = relationships[resource]
  const translate = useTranslateResource(resource, 'form')
  const label = useLabel(props)

  const driverFieldHelperText = useMemo(() => {
    if (defaultDriver) {
      const { name } = defaultDriver
      return translate('general.defaultDriver', { name })
    } else {
      return translate('general.noDefaultDriver')
    }
  }, [defaultDriver, translate])

  return (
    <>
      <ReferenceInput
        {...formInputOptions}
        {...referenceInputOptions(resourceRelationships['truck'])}
        {...truckReferenceInputOptions}
        source='truck.id'
        reference={resourceRelationships['truck']}
        label={label('truck')}
        onChange={onSelectedTruckChange}
        required
      >
        <Autocomplete
          optionText='number'
          optionSubtext='default_driver.name'
          validate={validate}
          helperText={truckHelperText}
        />
      </ReferenceInput>

      <ReferenceInput
        {...formInputOptions}
        {...referenceInputOptions(resourceRelationships['driver'])}
        source='driver.id'
        reference={resourceRelationships['driver']}
        label={label('driver')}
        onChange={onDriverFieldAutofillChange.bind(null, 'driver.id')}
        required
      >
        <Autocomplete
          validate={validate}
          helperText={driverFieldHelperText}
        />
      </ReferenceInput>
    </>
  )
}

export default TruckDriverFields
