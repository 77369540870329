import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CHANGE_UI } from '../../actions'
import {
  useTranslate,
} from 'react-admin'
import {
  Grid,
  Button,
} from '@material-ui/core'

const ToggleUiButton = ({ uiAttribute }) => {
  const translate = useTranslate()

  const toggledState = useSelector(state => state.ui[uiAttribute])
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch({ type: CHANGE_UI, [uiAttribute]: !toggledState })
  }, [dispatch, toggledState, uiAttribute])

  return (
    <>
      <Grid item>
        <Button
          size='small'
          color='secondary'
          onClick={onClick}
        >
          {translate(`ui.${uiAttribute}.${toggledState}`)}
        </Button>
      </Grid>
    </>
  )
}

export default ToggleUiButton
