import { Edit } from 'react-admin'
import Form from './form'
import { editOptions } from '../../util/component-options'
import { withTicketImage } from '../../hoc'

const FormWithTicketImage = withTicketImage(Form)

const EditView = (props) => {
  const { record } = props
  const imageUrl = record.ticket_image_url
  const senderPhone = record?.sender?.phone

  return (
    <FormWithTicketImage
      {...props}
      imageUrl={imageUrl}
      senderPhone={senderPhone}
    />
  )
}

const TonnageTicketsEdit = props => {
  return (
    <Edit {...editOptions} {...props} >
      <EditView />
    </Edit>
  )
}

export default TonnageTicketsEdit
