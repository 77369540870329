import { useState, useCallback } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { Dialog, DialogTitle, DialogContent, IconButton, withStyles } from '@material-ui/core'
import { useRedirect, useTranslate } from 'ra-core'
import LookupTickets from './lookup-tickets'

const StyledDialog = withStyles(() => {
  return {
    root: {
      overflow: 'auto',
    },
    container: {
      height: 'auto',
    },
    paper: {
      marginTop: '20vh',
      marginBottom: '20vh',
    },
  }
})(Dialog)

const Search = props => {
  const redirect = useRedirect()
  const translate = useTranslate()
  const [showModal, setShowModal] = useState(false)

  const onClick = useCallback(() => {
    setShowModal(true)
  }, [])

  const onModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  const onChange = useCallback((resource, id) => {
    setShowModal(false)
    redirect('edit', `/${resource}`, id)
  }, [redirect])

  return (
    <>
      <IconButton
        {...props}
        onClick={onClick}
      >
        <SearchIcon />
      </IconButton>
      {
        <StyledDialog
          maxWidth='sm'
          fullWidth
          open={showModal}
          onClose={onModalClose}
        >
          <DialogTitle>
            {translate('searchTitles.tickets')}
          </DialogTitle>
          <DialogContent>
            <LookupTickets onChange={onChange} />
          </DialogContent>
        </StyledDialog>
      }
    </>
  )
}

export default Search
