import {
  ResourceContextProvider,
  ListBase as RaListBase,
} from 'react-admin'
import { ListHeader } from '.'

const ListBase = ({ children, resource, includeHeader = true, ...rest }) => {
  return (
    <ResourceContextProvider value={resource}>
      <RaListBase
        perPage={25}
        basePath={undefined}
        {...rest}
      >
        <>
          { includeHeader && <ListHeader /> }
          {children}
        </>
      </RaListBase>
    </ResourceContextProvider>
  )
}

export default ListBase
