import {
  useState,
  useCallback,
} from 'react'
import {
  useListContext,
  useRefresh,
  Confirm,
  useNotify,
} from 'react-admin'
import {
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { useFetch, useTranslateResource } from '../../../hooks'
import StatusColor from './status-color'

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    paddingBottom: spacing(3),
  },
  button: {
    minWidth: '8em',
  },
}))

const BatchButton = ({ url, action, status }) => {
  const refresh = useRefresh()
  const { resource } = useListContext()
  const translate = useTranslateResource('orders', `list.${action}`)
  const classes = useStyles()
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const notify = useNotify()

  const onUpdateButtonClick = useCallback(() => {
    setConfirmIsOpen(true)
  }, [])

  const onConfirmClose = useCallback(() => {
    setConfirmIsOpen(false)
  }, [])

  const onFailure = useCallback(() => {
    notify('ra.notification.http_error', 'error')
    notify(`resources.orders.list.${action}.notifyFailure`, 'info')
  }, [notify, action])

  const onSuccess = useCallback(() => {
    setConfirmIsOpen(false)
    notify(`resources.orders.list.${action}.notifySuccess`, 'info')
    refresh()
  }, [refresh, notify, action])

  const [update, isUpdating] = useFetch({ url: `/${resource}${url}`, onFailure, onSuccess })

  return (
    <>
      <Grid item>
        <Button
          className={classes.button}
          variant='contained'
          size='small'
          onClick={onUpdateButtonClick}
        >
          <StatusColor status={status} justify='center'>
            {translate('buttonLabel')}
          </StatusColor>
        </Button>
      </Grid>
      <Confirm
        isOpen={confirmIsOpen}
        loading={isUpdating}
        onConfirm={update}
        title={translate('confirmTitle', { smart_count: 0 })}
        content={isUpdating ? translate('isUpdating') : translate('confirmContent')}
        onClose={onConfirmClose}
      />
    </>
  )
}

const ActivateAll = props => {
  return <BatchButton url={'/activate_all'} status='active' action='activateAll' {...props} />
}

const DeactivateAll = props => {
  return <BatchButton url={'/deactivate_all'}  status='pending' action='deactivateAll' {...props} />
}

const ArchiveAll = props => {
  return <BatchButton url={'/archive_all'} status='archived' action='archiveAll' {...props} />
}

const CancelAll = props => {
  return <BatchButton url={'/cancel_all'} status='canceled' action='cancelAll' {...props} />
}

export {
  ActivateAll,
  DeactivateAll,
  ArchiveAll,
  CancelAll,
}
