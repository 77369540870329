import {
  cloneElement,
} from 'react'
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  IconButton,
} from '@material-ui/core'
import { useQueryWithStore } from 'react-admin'
import classnames from 'classnames'
import PrintIcon from '@material-ui/icons/Print'
import usePrint from '../../hooks/use-print'
import { InlineLoading } from '../shared'

const useStyles = makeStyles(theme => {
  return {
    container: {
      marginRight: theme.spacing(-1.75),
      marginLeft: theme.spacing(-1.75),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    detailCol: {
      flexGrow: 1,
    },
  }
})

const GridExpand = ({ children, className, resource, record, payload = {}, enablePrint = true }) => {
  const { data: detail, loaded, loading } = useQueryWithStore({
    type: 'getOne',
    resource,
    payload: {
      ...payload,
      id: record.id,
    },
  })

  const [
    print,
    Printable,
    willPrint,
  ] = usePrint()

  const classes = useStyles()

  return (
    <Grid container spacing={1} wrap='nowrap' className={classes.container}>
      {
        enablePrint &&
        <Grid item>
          <IconButton
            size='small'
            color='secondary'
            onClick={print}
            disabled={willPrint}
          >
            <PrintIcon />
          </IconButton>
        </Grid>
      }
      <Grid item className={classnames(classes.detailCol, className)}>
        <Card>
          <CardContent>
            {
              !loaded && loading ?
                <InlineLoading /> :
                (
                  enablePrint ?
                  <Printable renderChildren>
                    {cloneElement(children, { record: detail })}
                  </Printable> :
                  cloneElement(children, { record: detail })
                )
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default GridExpand
