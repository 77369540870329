export default {
  ra: {
    page: {
      edit: 'Edit %{name}',
    },
    action: {
      update: 'Update',
      deactivate: 'Deactivate',
      reactivate: 'Reactivate',
    },
    notification: {
      updated: '%{resource} updated',
      created: '%{resource} created',
      deleted: '%{resource} deleted',
      http_validation_error: '%{resource} invalid',
      http_validation_error_with_message: '%{resource} invalid \n\n %{message}',
      message: '%{message}',
      optimistic_save_error: 'failed to save',
    },
    auth: {
      username: 'Email',
    },
    boolean: {
      null: 'All',
    },
  },
}
