import { NumberField, useListContext } from 'react-admin'
import { CollectionDataTable } from '../../shared'
import { currencyFieldOptions } from '../../util/component-options'
import { useLabel } from '../../../hooks'

const CollectionData = props => {
  const context = useListContext()
  const label = useLabel(context)

  return (
    <CollectionDataTable {...props}>
      <NumberField source='total_sum_total_in_dollars' label={label('sum_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_sum_income_total_in_dollars' label={label('sum_income_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_sum_profit_total_in_dollars' label={label('sum_profit_total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_sum_total_line_item_quantity' label={label('sum_total_line_item_quantity')} />
    </CollectionDataTable>
  )
}

export default CollectionData
