import { useMemo } from 'react'
import {
  TextInput,
  useTranslate,
} from 'react-admin'
import { getValidator } from '../form'

const PhoneInput = ({ showHelperText = true, ...rest }) => {
  const { required = false } = rest
  const translate = useTranslate()

  const validate = useMemo(() => {
    return getValidator(required, [
      value => !value || /^\+[1-9]\d{10}$/.test(value) ? null : translate('phone.formatInvalid'),
    ])
  }, [translate, required])

  return (
    <TextInput
      validate={validate}
      helperText={showHelperText ? translate('phone.helper') : undefined}
      {...rest}
    />
  )
}

export default PhoneInput
