import { Fragment } from 'react'
import { AppBar as RaAppBar } from 'react-admin'
import UserMenu from './user-menu'
import {
  Typography,
  makeStyles,
  // IconButton,
} from '@material-ui/core'
// import HelpIcon from '@material-ui/icons/HelpOutline'
import Search from '../search'
// import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => {
  return {
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textTransform: 'capitalize',
    },
    spacer: {
      flex: 1,
    },
  }
})

const AppBar = props => {
  const classes = useStyles()

  return (
    <RaAppBar
      {...props}
      userMenu={<UserMenu />}
      container={Fragment} // override Slide effect - keep fixed
    >
      <Typography
        variant='h6'
        color='inherit'
        id='react-admin-title'
        className={classes.title}
      />
      <span className={classes.spacer} />
      <Search color='inherit' />
      {/* <IconButton
        color='inherit'
        component={Link}
        to={{ pathname: '/help' }}
      >
        <HelpIcon />
      </IconButton> */}
    </RaAppBar>
  )
}

export default AppBar

