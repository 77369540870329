import {
  ReferenceInput,
  TextInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete, NumberInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { relationships } from '../../../data-model'
import { Form, getValidator } from '../../form'
import { useHourlyRateFields } from '../../form/functions'

const HourlyRatesForm = (props) => {
  const { resource } = props
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(props)
  const rateFields = useHourlyRateFields()
  const label = useLabel(props)

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='description'
            label={label('description')}
            validate={getValidator()}
            required
          />

          {
            rateFields.map(({ source, required, label: _label }) => (
              <NumberInput
                {...formInputOptions}
                key={source}
                validate={getValidator(required)}
                source={source}
                label={label(_label)}
                step={0.01}
                min={0}
                required={required}
              />
            ))
          }
        </Box>

        <Box>
          <ReferenceInput
            {...referenceInputOptions}
            {...formInputOptions}
            source={'customer.id'}
            reference={resourceRelationships['customer']}
            label={label('customer')}
            required
          >
            <Autocomplete validate={getValidator()} />
          </ReferenceInput>
        </Box>
      </Box>
    </Form>
  )
}

export default HourlyRatesForm
