import { useCallback, useMemo } from 'react'
import {
  TextInput,
  RadioButtonGroupInput,
  required,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import {
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import { useLabel, useTranslateResource } from '../../../hooks'
import { Form, getValidator } from '../../form'
import { AddressInputFields } from '../../shared'
import { PhoneInput, NumberInput } from '../../custom'

const hourlyWorkPayTypes = {
  hourly_rate: [
    'hourly_regular_rate_in_dollars',
    'hourly_overtime_rate_in_dollars',
    'hourly_doubletime_rate_in_dollars',
  ],
  hourly_work_deduction: [
    'hourly_work_deduction_in_dollars',
  ],
}

const BrokerDriversForm = props => {
  const { resource } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const translateForm = useTranslateResource(resource, 'form')

  const transform = useCallback((data) => {
    const {
      hourly_work_pay_type,
      hourly_work_deduction_in_dollars,
      hourly_regular_rate_in_dollars,
      hourly_overtime_rate_in_dollars,
      hourly_doubletime_rate_in_dollars,
      ...rest
    } = data

    return {
      ...rest,
      hourly_work_deduction_in_dollars: hourly_work_pay_type === 'hourly_work_deduction' ? hourly_work_deduction_in_dollars : null,
      hourly_regular_rate_in_dollars: hourly_work_pay_type === 'hourly_rate' ? hourly_regular_rate_in_dollars : null,
      hourly_overtime_rate_in_dollars: hourly_work_pay_type === 'hourly_rate' ? hourly_overtime_rate_in_dollars : null,
      hourly_doubletime_rate_in_dollars: hourly_work_pay_type === 'hourly_rate' ? hourly_doubletime_rate_in_dollars : null,
    }
  }, [])

  const hourlyWorkPayTypeChoices = useMemo(() => {
    return Object.keys(hourlyWorkPayTypes).map(f => ({
      id: f,
      name: translateForm(f),
    }))
  }, [translateForm])

  const getHourlyWorkPayTypeValidator = useCallback(_required => {
    return (value, data, field) => {
      if (hourlyWorkPayTypes[data['hourly_work_pay_type']].includes(field.name)) {
        return [
          _required && required(),
        ].filter(Boolean).reduce((error, validator) => error || validator(value, data, field), undefined)
      } else {
        return undefined
      }
    }
  }, [])

  return (
    <Form
      {...props}
      transform={transform}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='name'
            label={label('name')}
            validate={getValidator()}
            required
          />

          <PhoneInput
            {...formInputOptions}
            source='phone'
            label={label('phone')}
          />

          <TextInput
            {...formInputOptions}
            source='email'
            label={label('email')}
            validate={getValidator(false)}
          />

          <TextInput
            {...formInputOptions}
            source='ledger_code'
            label={label('ledger_code')}
            validate={getValidator(false)}
          />
        </Box>

        <Box>
          <NumberInput
            {...formInputOptions}
            source='percent_of_base_rate'
            label={label('percent_of_base_rate')}
            validate={getValidator()}
            step={1}
            min={0}
            required
          />

          <BooleanInput
            source='is_surcharge_passthrough'
            label={label('is_surcharge_passthrough')}
            defaultValue={false}
          />

          <RadioButtonGroupInput
            source='hourly_work_pay_type'
            defaultValue='hourly_rate'
            helperText={false}
            choices={hourlyWorkPayTypeChoices}
          />

          <FormDataConsumer>
            {({ formData }) => {
              const payType = formData['hourly_work_pay_type']
              return (
                <>
                  {
                    Object.keys(hourlyWorkPayTypes).map(type => {
                      return hourlyWorkPayTypes[type].map(f => {
                        return (
                          <NumberInput
                            {...formInputOptions}
                            key={f}
                            style={payType === type ? null : { display: 'none' }}
                            source={f}
                            label={label(f)}
                            validate={getHourlyWorkPayTypeValidator(true)}
                            step={0.01}
                            min={0}
                            disabled={payType !== type}
                            required={payType === type}
                          />

                        )
                      })
                    })
                  }
                </>
              )
            }}
          </FormDataConsumer>
        </Box>

        <Box>
          <AddressInputFields resource={resource} />
        </Box>
      </Box>
    </Form>
  )
}

export default BrokerDriversForm
