import {
  TextField,
  SelectField,
} from 'react-admin'
import { Datagrid, List } from '../../custom'
import { useLabel } from '../../../hooks'
import { exportTypeChoices } from './util'

const ExportsList = props => {
  const label = useLabel(props)

  return (
    <List
      {...props}
    >
      <Datagrid>
        <TextField source='name' label={label('name')} />
        <SelectField source='export_type' choices={exportTypeChoices} label={label('export_type')} />
      </Datagrid>
    </List>
  )
}

export default ExportsList
