const exportTypes = [
  'invoice',
  'broker_driver_pay_rollup',
]

const exportTypeChoices = exportTypes.map(id => ({
  id,
  name: `resources.exports.export_type.${id}`,
}))

export {
  exportTypeChoices,
}
