import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing, palette }) => {
  const pad = spacing(4)

  return {
    form: {
      padding: pad,
    },
    noteText: {
      marginBottom: spacing(2),
      fontSize: '1em',
      color: palette.grey[600]
    },
    grid: {
      display: 'grid',
      gridGap: pad,
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    },
  }
})

export default useStyles
