import { useMemo } from 'react'

const useAddressFields = (prefix, only) => (
  useMemo(() => {
    const _only = only ? only.map(f => `${prefix}_${f}`) : null

    let fields = [
      {
        source: `${prefix}_street_1`,
        label: 'address.street_1',
      },
      {
        source: `${prefix}_street_2`,
        label: 'address.street_2',
      },
      {
        source: `${prefix}_city`,
        label: 'address.city',
      },
      {
        source: `${prefix}_united_state_abbreviation`,
        label: 'address.state',
      },
      {
        source: `${prefix}_zip`,
        label: 'address.zip',
      },
    ]

    if (_only) {
      fields = fields.filter(f => _only.includes(f.source))
    }

    return fields

  },[prefix, only])
)

export default useAddressFields
