import { combineReducers } from 'redux'
import oneToMany from './oneToMany'
import resources from './resources'

const reducer = combineReducers({
  oneToMany,
  resources,
})

export default reducer
