import { Login } from 'react-admin'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import logo from '../../images/logo-light.png'

const LoginPage = props => {
  const { theme: { palette, spacing } } = props

  const useLayoutStyles = makeStyles(() => ({
    outer: {
      minHeight: '100vh',
      background: `linear-gradient(135deg, ${palette.secondary.main} 0%, ${palette.custom.gray.main} 300%)`,
    },
    inner: {
      marginTop: '10vh',
      marginBottom: spacing(4),
    },
  }))

  const LoginWithStyles = withStyles(() => ({
    main: {
      background: 'none',
      minHeight: 0,
      height: 'auto',
    },
    card: {
      marginTop: spacing(1.5),
      background: palette.custom.gray.main,
    },
  }))(Login)

  const layoutClasses = useLayoutStyles()

  return (
    <Grid container direction='column' alignItems='center' className={layoutClasses.outer}>
      <Grid item container direction='column' alignItems='center' className={layoutClasses.inner}>
        <Grid item>
          <img className={layoutClasses.image} height={180} src={logo} alt='logo' />
        </Grid>
        <Grid item>
          <LoginWithStyles {...props} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LoginPage
