import React, { useCallback } from 'react';
import {
  TextInput,
  FileInput,
  FileField,
  ReferenceInput,
  ReferenceArrayField,
  Datagrid,
  TextField,
  FunctionField,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '../../custom';
import {
  formInputOptions,
  referenceInputOptions,
} from '../../util/component-options';
import { relationships } from '../../../data-model';
import { useFormStyles } from '../../../styles';
import { useLabel } from '../../../hooks';
import { Form, getValidator } from '../../form';

const StyledFileInput = withStyles((theme) => ({
  root: {
    margin: 0,
  },
}))(FileInput);

const IssueLink = ({ record }) => {
  const tonnageTicketId = record.tonnage_ticket_id;

  return (
    <span style={{ cursor: tonnageTicketId ? 'pointer' : 'not-allowed' }}>
      {tonnageTicketId ? (
        <a href={`/tonnage_tickets/${tonnageTicketId}`}>
          {record.ticket_number}
        </a>
      ) : (
        `${record.ticket_number}`
      )}
    </span>
  );
};

const TonnageTicketAuditsForm = props => {
  const formClasses = useFormStyles(props);
  const label = useLabel(props);
  const { resource, record } = props;
  const resourceRelationships = relationships[resource];

  const transform = useCallback(data => {
    return {
      id: data.id,
      spreadsheet_file: data.fileUpload?.rawFile,
      ticket_vendor_id: data.ticket_vendor.id,
    };
  }, []);

  return (
    <Form {...props} transform={transform}>
      <Box className={formClasses.grid}>
        <Box>
          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['ticket_vendor'])}
            {...formInputOptions}
            source='ticket_vendor.id'
            reference={resourceRelationships['ticket_vendor']}
            label={label('ticket_vendor')}
          >
            <Autocomplete required validate={getValidator()} />
          </ReferenceInput>
          {record.spreadsheet_filename && (
            <TextInput
              {...formInputOptions}
              source='spreadsheet_filename'
              label={label('spreadsheet_filename')}
              disabled
            />
          )}
        </Box>
        <Box>
          <StyledFileInput
            source='fileUpload'
            accept='.csv,.xlsx'
            label={''}
          >
            <FileField source='src' title='title' />
          </StyledFileInput>
        </Box>
      </Box>

      <h3>Tonnage Ticket Audit Issues</h3>
      <sub>* Note: There may be multiple separate issues on the same ticket number</sub>
      {record.spreadsheet_filename && (
        <ReferenceArrayField label="Tonnage Ticket Audit Issues" reference="tonnage_ticket_audit_issues" source="tonnage_ticket_audit_issue_ids">
          <Datagrid>
            <FunctionField
              label="Ticket Number"
              render={(record) => <IssueLink record={record} />}
            />
            <TextField source="error_message"/>
          </Datagrid>
        </ReferenceArrayField>
      )}
    </Form>
  );
};

export default TonnageTicketAuditsForm;
