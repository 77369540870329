import React, { useCallback, useState } from 'react'
import { InputHelperText } from 'react-admin'
import { useInput, FieldTitle } from 'ra-core'
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { iSODateToDateOnlyString, dateOnlyStringToISODate } from '../../util/date'
import DateFnsUtils from '@date-io/date-fns'

const providerOptions = {
  utils: DateFnsUtils,
  locale: undefined,
}

const Picker = ({
  PickerComponent,
  defaultValue,
  formatInput,
  formatValue,
  format,
  mask,
  disableToolbar,
  pickerVariant: variant,
  ...fieldProps
}) => {
  const {
    label,
    source,
    resource,
    className,
    variant: inputVariant,
    required,
    helperText,
    fullWidth,
    margin,
    validate,
    disabled,
    shouldDisableDate,
    initialFocusedDate,
  } = fieldProps

  const { input, meta, isRequired } = useInput({ source, validate })
  const { touched, error: validationError, submitError } = meta
  const error = submitError || validationError

  const [value, setValue] = useState(() => {
    if (Date.parse(input.value)) {
      return formatValue(input.value)
    } else if (defaultValue) {
      return formatValue(defaultValue)
    } else {
      return null
    }
  })

  const handleChange = useCallback(date => {
    setValue(date)
    input.onChange(formatInput(date))
  }, [input, setValue, formatInput])

  const onBlur = useCallback(() => {
    input.onBlur(formatInput(value))
  }, [value, input, formatInput])

  return (
    <MuiPickersUtilsProvider {...providerOptions}>
      <PickerComponent
        disableToolbar={disableToolbar}
        shouldDisableDate={shouldDisableDate}
        disabled={disabled}
        className={className}
        value={value}
        initialFocusedDate={initialFocusedDate ? formatValue(initialFocusedDate) : undefined}
        fullWidth={fullWidth}
        margin={margin}
        required={isRequired || required}
        inputVariant={inputVariant}
        error={Boolean(touched && error)}
        onChange={handleChange}
        onBlur={onBlur}
        format={format}
        mask={mask}
        autoOk
        variant={variant}
        KeyboardButtonProps={{ tabIndex: '-1' }}
        helperText={<InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />}
        label={<FieldTitle
          label={label}
          source={source}
          resource={resource}
        />}
      />
    </MuiPickersUtilsProvider>
  )
}

const DateInput = props => {
  const formatValue = useCallback(value => {
    return dateOnlyStringToISODate(value)
  }, [])

  const formatInput = useCallback(value => {
    return Date.parse(value) ? iSODateToDateOnlyString(value) : null
  }, [])

  return (
    <Picker
      PickerComponent={KeyboardDatePicker}
      formatValue={formatValue}
      formatInput={formatInput}
      format='MM/dd/yyyy'
      pickerVariant='inline'
      disableToolbar
      {...props}
    />
  )
}

const DateTimeInput = props => {
  const formatValue = useCallback(value => value, [])
  const formatInput = useCallback(value => value, [])

  return (
    <Picker
      PickerComponent={KeyboardDateTimePicker}
      formatValue={formatValue}
      formatInput={formatInput}
      format='MM/dd/yyyy hh:mm a'
      mask='__/__/____ __:__ _M'
      pickerVariant='dialog'
      {...props}
    />
  )
}

export {
  DateInput,
  DateTimeInput,
}
