import {
  TabbedShowLayout,
  Tab as RaTab,
  Title,
  useTranslate,
} from 'react-admin'
import {
  Card,
  CardContent,
  Box,
} from '@material-ui/core'
import ExtractionsList from './extractions-list'
import TonnageRatesList from './tonnage-rates-list'
import { sorts } from '../../data-model'
import MaterialsForm from '../../components/resources/materials/form'
import CompaniesForm from '../resources/companies/form'
import SitesForm from '../../components/resources/sites/form'
import TrucksForm from '../../components/resources/trucks/form'
import { ListBase } from '../custom'
import { useGetPendingResources } from '../../hooks'

import { withStyles, makeStyles } from '@material-ui/core/styles'

const Tab = withStyles(({ spacing }) => ({
  root: {
    minHeight: 48,
    minWidth: 140,
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& > *:first-child': {
      marginBottom: '0 !important',
    }
  },
}))(RaTab)

const useCountStyles = makeStyles(theme => {
  return {
    root: {
      marginRight: theme.spacing(1),
      borderRadius: '50px',
      padding: theme.spacing(1),
      fontSize: '0.9em',
      background: theme.palette.primary.main,
      lineHeight: 0,
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
    }
  }
})

const TabContent = (props) => {
  const { reference, resource, ...rest } = props
  return (
    <ListBase
      resource={reference}
      {...rest}
    />
  )
}

const Count = ({ value }) => {
  const classes = useCountStyles()
  return value ? <span className={classes.root}>{value}</span> : null
}

const PendingResourcesPreview = () => {
  const translate = useTranslate()
  const resourceQueryStates = useGetPendingResources()

  return (
    <Box>
      <Card>
        <Title title={translate('resources.pending_resources.preview.name')} />
        <CardContent>
          <TabbedShowLayout>
            <Tab
              label={translate('resources.customers.name', { smart_count: 2 })}
              path='customers'
              icon={<Count value={resourceQueryStates.pending_customer_extraction_mappings.total}/>}
            >
              <TabContent
                reference='pending_customer_extraction_mappings'
                sort={sorts['pending_customer_extraction_mappings']}
              >
                <ExtractionsList
                  reference='companies'
                  extractionAttribute='customer'
                  ResourceFormComponent={CompaniesForm}
                />
              </TabContent>
            </Tab>
            <Tab
              label={translate('resources.recipients.name', { smart_count: 2 })}
              path='recipients'
              icon={<Count value={resourceQueryStates.pending_recipient_extraction_mappings.total}/>}
            >
              <TabContent
                reference='pending_recipient_extraction_mappings'
                sort={sorts['pending_recipient_extraction_mappings']}
              >
                <ExtractionsList
                  reference='companies'
                  extractionAttribute='recipient'
                  ResourceFormComponent={CompaniesForm}
                />
              </TabContent>
            </Tab>
            <Tab
              label={translate('resources.materials.name', { smart_count: 2 })}
              path='materials'
              icon={<Count value={resourceQueryStates.pending_material_extraction_mappings.total}/>}
            >
              <TabContent
                reference='pending_material_extraction_mappings'
                sort={sorts['pending_material_extraction_mappings']}
              >
                <ExtractionsList
                  reference='materials'
                  extractionAttribute='material'
                  ResourceFormComponent={MaterialsForm}
                />
              </TabContent>
            </Tab>
            <Tab
              label={translate('resources.pickup_sites.name', { smart_count: 2 })}
              path='pickup_sites'
              icon={<Count value={resourceQueryStates.pending_pickup_site_extraction_mappings.total}/>}
            >
              <TabContent
                reference='pending_pickup_site_extraction_mappings'
                sort={sorts['pending_pickup_site_extraction_mappings']}
              >
                <ExtractionsList
                  reference='sites'
                  extractionAttribute='pickup_site'
                  ResourceFormComponent={SitesForm}
                />
              </TabContent>
            </Tab>
            <Tab
              label={translate('resources.dropoff_sites.name', { smart_count: 2 })}
              path='dropoff_sites'
              icon={<Count value={resourceQueryStates.pending_dropoff_site_extraction_mappings.total}/>}
            >
              <TabContent
                reference='pending_dropoff_site_extraction_mappings'
                sort={sorts['pending_dropoff_site_extraction_mappings']}
              >
                <ExtractionsList
                  reference='sites'
                  extractionAttribute='dropoff_site'
                  ResourceFormComponent={SitesForm}
                />
              </TabContent>
            </Tab>
            <Tab
              label={translate('resources.trucks.name', { smart_count: 2 })}
              path='trucks'
              icon={<Count value={resourceQueryStates.pending_truck_extraction_mappings.total}/>}
            >
              <TabContent
                reference='pending_truck_extraction_mappings'
                sort={sorts['pending_truck_extraction_mappings']}
              >
                <ExtractionsList
                  reference='trucks'
                  extractionAttribute='truck_number'
                  ResourceFormComponent={TrucksForm}
                />
              </TabContent>
            </Tab>
            <Tab
              label={translate('resources.tonnage_rates.name', { smart_count: 2 })}
              path='tonnage_rates'
              icon={<Count value={resourceQueryStates.pending_tonnage_rates.total}/>}
            >
              <TabContent
                reference='pending_tonnage_rates'
                sort={sorts['pending_tonnage_rates']}
              >
                <TonnageRatesList />
              </TabContent>
            </Tab>
          </TabbedShowLayout>
        </CardContent>
      </Card>
    </Box>
  )
}

export default PendingResourcesPreview
