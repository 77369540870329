import { useCallback, useMemo } from 'react'
import { useTranslate, Link } from 'react-admin'
import {
  Grid,
  Button,
  Card,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core'
import { colors, formatTime } from './props'
import StatusColor from '../shared/orders/status-color'

const translationPrefix = 'views.dispatchMap.orders'

const useStyles = makeStyles(theme => {
  const header = {
    padding: theme.spacing(1),
    position: 'sticky',
    top: 0,
  }

  const headerFiltered = {
    ...header,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }

  const item = {
    padding: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.25),
    cursor: 'pointer',
  }

  const selectedItem = {
    ...item,
    background: theme.palette.action.selected,
  }

  return {
    root: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
    },
    header,
    headerFiltered,
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    item,
    selectedItem,
    infoRow: {
      display: 'flex',
      gap: theme.spacing(0.6),
      flexWrap: 'nowrap',
      alignItems: 'baseline',
    },
    color: {
      padding: '0.36em',
      borderRadius: '2em',
    },
  }
})

const Orders = ({ orders, selectedEndpoint, selectedOrder, onShowAllClick, onSelectOrder }) => {
  const translate = useTranslate()
  const classes = useStyles()

  const filteredOrders = useMemo(() => {
    if (!selectedEndpoint) return orders
    const selectedRoutes = selectedEndpoint?.points.map(point => point.routeId)
    return orders.filter(order => selectedRoutes.includes(order.id))
  }, [orders, selectedEndpoint])

  const isFiltered = filteredOrders.length !== orders.length
  const isOrderSelected = Boolean(selectedOrder && orders.length > 1)

  const statusText = useMemo(() => {
    const title = translate(`${translationPrefix}.title`, { smart_count: orders.length })
    let filteredText
    if (isFiltered) {
      filteredText = translate(`${translationPrefix}.showing`, { showing_count: filteredOrders.length })
    } else if (isOrderSelected) {
      filteredText = translate(`${translationPrefix}.selected`)
    }
    return [title, filteredText].filter(Boolean).join(' ')
  }, [filteredOrders, isFiltered, translate, orders, isOrderSelected])

  const onClick = useCallback((order, e) => {
    if (e.target.tagName === 'A') return
    onSelectOrder(order)
  }, [onSelectOrder])

  return (
    <Box className={classes.root}>
      <Card className={isFiltered || isOrderSelected ? classes.headerFiltered : classes.header}>
        <Grid container justify='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='body1'>
              {statusText}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              style={{ visibility: isFiltered || isOrderSelected ? 'visible' : 'hidden' }}
              onClick={onShowAllClick}
              variant='text'
              size='small'
              color='inherit'
            >
              Show All
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Box className={classes.list}>
        {
          filteredOrders?.map(order => {
            return (
              <Box
                key={order.id}
                className={selectedOrder && selectedOrder.id === order.id ? classes.selectedItem : classes.item}
                onClick={onClick.bind(null, order)}
              >
                <Grid container className={classes.infoRow}>
                  <Grid item>
                    <Box className={classes.color} style={{ background: colors.pickup.primary }} />
                  </Grid>
                  <Grid item>
                    <Typography variant='body2'>
                      {order.tonnage_rate.pickup_site.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.infoRow}>
                  <Grid item>
                    <Box className={classes.color} style={{ background: colors.dropoff.primary }} />
                  </Grid>
                  <Grid item>
                    <Typography variant='body2'>
                      {order.tonnage_rate.dropoff_site.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justify='space-between' className={classes.infoRow}>
                  <Grid item>
                    <Typography variant='body2'>
                      {formatTime(order.load_at)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2'>
                      {translate(`${translationPrefix}.assignedTrucks`, { smart_count: order.num_assigned_trucks })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justify='space-between' className={classes.infoRow}>
                  <Grid item>
                    <Typography variant='body2'>
                      {order.order_number ? `#${order.order_number}` : '--'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <StatusColor status={order.status}>
                      {order.status}
                    </StatusColor>
                  </Grid>
                </Grid>
                <Grid container className={classes.infoRow}>
                  <Grid item>
                    <Link target='_blank' tabIndex='-1' className={classes.editLink} to={`/tonnage_orders/${order.id}`} >
                      {translate(`${translationPrefix}.view`)}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            )
          })
        }
      </Box>
    </Box>
  )
}

export default Orders
